<template>
  <div class="position-relative copy-popover-main">
    <div class="text-truncate">
      {{ text ?? "--" }}
    </div>
    <CopyPopover :data="text" :toggle="toggle" :copy-icon="showCopy" />
  </div>
</template>

<script>
import CopyPopover from "@/components/CopyPopover.vue";
export default {
  components: { CopyPopover },
  props: {
    text: {
      type: String,
      default: "",
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    showCopy: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
