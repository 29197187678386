<template>
  <div v-if="(breadCrumbList ?? []).length !== 0" class="mb-1">
    <nav>
      <ol class="d-flex justify-content-start align-items-center gap-2">
        <li
          v-for="(item, index) in breadCrumbList"
          :key="item?.name"
          class="p-0 m-0"
          :class="{ 'last-item': index + 1 === breadCrumbList.length }"
          @click="routeTo(index + 1 === breadCrumbList.length ? null : item)"
        >
          <span class="li-label-ba">
            {{
              item?.label === "Loading..."
                ? customersDetailsLoading
                  ? "Loading..."
                  : customersDetails?.CustomerName
                : item?.label
            }}</span
          >
          <span v-if="index + 1 < breadCrumbList.length" class="ps-2"> > </span>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
import { computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { categories } from "@/data/options";
import { useStore } from "vuex";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const customersDetails = computed(
      () => store.state.usersViewStore.specificCustomersNBFDetails
    );
    const customersDetailsLoading = computed(
      () => store.state.usersViewStore.specificCustomersNBFDetailsLoading
    );

    const breadCrumbList = computed(() => {
      let list = route.meta?.breadCrumbList ?? [];

      list = list.map((item, index) => {
        let queryLabel = item?.label;

        //[1] user_name load from NBF endpoint
        if (item?.queryParam === "user_name") {
          queryLabel = "Loading...";
        } else if (item?.queryParam) {
          queryLabel = route.query?.[item?.queryParam] ?? item?.label;
        }
        if (item?.param) {
          queryLabel = route.params?.[item?.param] ?? item?.label;
        }
        //custom routes
        if (route.name === "UserCategoryDetails" && list.length === index + 1) {
          queryLabel =
            categories[route.params?.catId ?? 1]?.label ?? item?.label;
        }
        return { ...item, label: queryLabel };
      });

      return list;
    });

    const routeTo = (selectedRoute) => {
      if (selectedRoute?.name)
        router.push({
          name: selectedRoute?.name,
          query: {
            [selectedRoute?.queryParam]: route.query[selectedRoute?.queryParam], //for dynamic routes
          },
        });
    };

    const getCustomerDetails = () => {
      const cloudId = route.params?.cloudId;
      store.dispatch("getCustomerDetails", cloudId);
    };

    watch(
      () => route.name,
      (currentRoute) => {
        if (
          [
            "UserDetails",
            "UserCategoryDetails",
            "MainUserTransactions",
            "UserDetailsFromTransactions",
          ].includes(currentRoute)
        ) {
          //[1] reset
          store.commit("updateSpecificCustomersNBFDetails", {});
          store.commit("updateSpecificCustomersNBFDetailsLoading", false);

          //[2] fetch customer name
          getCustomerDetails();
        }
      },
      {
        immediate: true,
      }
    );

    return {
      breadCrumbList,
      routeTo,
      customersDetails,
      customersDetailsLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    color: var(--sea);
    cursor: pointer;
    font-size: 0.94rem;

    &.last-item .li-label-ba {
      color: var(--dark-green-60, #66827f);
      text-decoration: none;
      cursor: auto;
    }

    .li-label-ba {
      transition: var(--d-ease);
      text-decoration: underline;
      &:hover {
        color: var(--green);
      }
    }
  }
}
</style>
