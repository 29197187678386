<template>
  <div id="period-dropdown" class="postion-relative">
    <BaseFilterDropDown
      id="time-period-dd"
      v-model="selectedPeriod"
      :options="periodOptions"
      :range-label="selectedRange?.dropDownLabel ?? selectedPeriod?.value"
    >
      <template #option="{ value }">
        <span :id="`dd-time-filter-${value?.key}`"
          >{{ value?.value }}
        </span></template
      >
    </BaseFilterDropDown>
    <TimeDatePicker
      :selected-option="periodsOptions[selectedPeriod?.key]"
      @time-changed="handleTimeChange"
    />
  </div>
</template>
<script>
import { computed, ref, onMounted, watch } from "vue";
import BaseFilterDropDown from "./BaseFilterDropDown.vue";
import { periods } from "@/data/options";
import TimeDatePicker from "../TimeDatePicker.vue";
import store from "../../store";

export default {
  components: { BaseFilterDropDown, TimeDatePicker },
  emits: ["period-change"],
  setup(props, { emit }) {
    const selectedPeriod = ref({ key: "month", value: "Last 30 Days" });
    const periodsOptions = ref(periods);
    const selectedRange = ref();
    const timeFilters = computed(() => store.state.overViewStore.timeFilters);
    const appliedFilters = computed(
      () => store.state.overViewStore.appliedFilters
    );

    const periodOptions = computed(() => {
      let periodsList = [];
      for (let key in periodsOptions.value) {
        periodsList.push({
          key,
          value: periodsOptions.value[key]?.label,
        });
      }
      return periodsList;
    });

    onMounted(() => {
      const defaultSelectedTime = { ...periodsOptions.value?.month?.days };
      const cachedSelectedPeriod = { ...timeFilters.value };

      //[1] Set cached Period Dropdown label and value
      if (Object.keys(cachedSelectedPeriod)?.length !== 0) {
        selectedPeriod.value = {
          key: cachedSelectedPeriod?.key ?? "month",
          value: cachedSelectedPeriod?.dropDownLabel ?? "Last 30 Days",
        };
      }

      //[2] Set Default time to Last 30 Days or previous cached selected date
      if (Object.keys(defaultSelectedTime).length !== 0) {
        store.commit("updateTimeFilters", {
          rangePeriodFilter: cachedSelectedPeriod?.rangePeriodFilter ?? {
            ...periodsOptions.value?.month?.days,
          },
          dropDownLabel: cachedSelectedPeriod?.dropDownLabel ?? "Last 30 Days",
          periodFilter: cachedSelectedPeriod?.periodFilter ?? 30,
          key: selectedPeriod.value?.key ?? "month",
        });

        store.commit("updateAppliedFilters", {
          ...appliedFilters.value,
          from_date:
            cachedSelectedPeriod?.rangePeriodFilter?.from ??
            defaultSelectedTime?.from,
          to_date:
            cachedSelectedPeriod?.rangePeriodFilter?.to ??
            defaultSelectedTime?.to,
        });
      }
      // selectedPeriod.value
    });
    // computed(() => {
    //   return periods[selectedPeriod.value?.key];
    // });

    // watch(
    //   selectedRange,
    //   (selectedFilters) => {
    //     emit("period-change", selectedFilters);
    //   },
    //   {
    //     deep: true,
    //   }
    // );

    function handleTimeChange(selectedOpt) {
      const time = {
        key: selectedPeriod.value?.key,
        ...selectedOpt,
      };
      selectedRange.value = { ...time };
      emit("period-change", time);
    }

    // == Watchers ==
    watch(timeFilters, (newTimeFilter) => {
      // console.log("New Time Filter: ", newTimeFilter);

      //[1] Set new time to the dropdown component
      if (Object.keys(newTimeFilter)?.length !== 0) {
        selectedPeriod.value = {
          key: newTimeFilter?.key ?? "month",
          value: newTimeFilter?.dropDownLabel ?? "Last 30 Days",
        };
        selectedRange.value = newTimeFilter;
      }

      //[2] update appliedFilter
      store.commit("updateAppliedFilters", {
        ...appliedFilters.value,
        from_date: newTimeFilter?.rangePeriodFilter?.from,
        to_date: newTimeFilter?.rangePeriodFilter?.to,
      });
    });

    return {
      selectedPeriod,
      periodOptions,
      selectedRange,
      periodsOptions,
      handleTimeChange,
    };
  },
};
</script>
<style></style>
