<template>
  <div
    class="
      m-popover-outer
      cp
      d-flex
      text-start
      justify-content-center
      align-items-center
    "
    :class="{ shown: toggle }"
    @click.stop="copy(data)"
  >
    <div v-if="copyIcon" class="img-copy-box me-3">
      <img
        v-if="!isCopied"
        src="@/assets/images/svg/components/copy-clipboard.svg"
        alt="copy clipboard"
      />
      <p v-else class="copy-label cp m-0 p-0 text-nowrap">Copied!</p>
    </div>
    <p :style="`width: ${charCounter + 7}ch;`" class="m-0 p-0">
      {{ data }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: [Number, String],
      default: "",
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    copyIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isCopied: false,
    };
  },
  computed: {
    charCounter() {
      return this.data?.length;
    },
  },
  methods: {
    copy(id) {
      navigator.clipboard.writeText(id).then(
        () => {
          this.isCopied = true;
          setTimeout(() => {
            this.isCopied = false;
          }, 600);
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.m-popover-outer {
  visibility: hidden;
  position: absolute;
  left: 10%;
  top: 100%;
  z-index: 1;
  background: white;
  padding: 0.6rem 1.05rem;
  border-radius: 5px;
  box-shadow: 0px 4px 40px 0px #3a3a3a1f;
  max-width: 350px;
  overflow-wrap: anywhere;

  .img-copy-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.shown {
    visibility: visible;
  }

  p {
    font-size: 0.84rem;
    color: var(--green);
  }
  img {
    cursor: pointer;
    width: 18px;
  }
  .copy-label {
    margin-right: 0.5rem;
    height: fit-content;
    border-radius: 5px;
    font-size: 0.6rem;
    color: var(--sea);
  }
}
</style>
