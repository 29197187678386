<template>
  <main id="app">
    <div>
      <router-view />
      <vue-basic-alert ref="alert" :duration="300" :close-in="4500" />

      <!-- @click="toggleModal(false)" -->
      <div
        v-if="showingModal"
        class="overlay fixed-top w-100 h-100 d-flex justify-content-center"
      >
        <!-- v-if="formIsVisible" -->
        <div
          class="mx-auto my-auto w-30 card p-3 d-flex flex-column"
          @click="stopPropagation"
        >
          <div
            class="
              mb-4
              d-flex
              flex-row
              justify-content-between
              align-items-center
            "
          >
            <!-- <span class="text-muted">Save audience</span> -->
            <span class="text-muted">{{ modalConfig.rider }}</span>
            <button
              id="close-modal-icon"
              class="btn btn-sm"
              @click="toggleModal(false)"
            >
              <i class="bi bi-x-lg"></i>
            </button>
          </div>
          <!-- <h5 class="mb-3">Would you like to save this audience?</h5> -->
          <h5 class="mb-3">{{ modalConfig.title }}</h5>
          <div class="d-flex flex-column justify-content-between h-100">
            <div
              v-if="modalConfig.description"
              class="flex-grow-1 d-flex flex-column justify-content-around"
            >
              <div class="w-100 h-100 py-3">
                <!-- {{ profile?.def_data?.profile_name ?? "Audience Name" }} ({{
                  Boolean(profile.active_users)
                    ? numberWithCommas(profile.active_users ?? 0)
                    : "No"
                }}
                active user{{ profile.active_users > 1 ? "s" : "" }}) -->
                {{ modalConfig.description }}
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <button
                id="modal-cancel-button"
                class="ms-auto btn btn-light"
                @click="toggleModal(false)"
              >
                Cancel
              </button>
              <button
                id="modal-proceed-button"
                class="ms-3 btn btn-primary"
                @click="onActionClicked"
              >
                <!-- Save Audience -->
                {{ modalConfig.actionTitle }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="loading"
        id="loader"
        class="
          bg-transcluscent
          overlay
          fixed-top
          w-100
          h-100
          d-flex
          justify-content-center
        "
      >
        <!-- bg-danger -->
        <div class="mx-auto my-auto d-flex justify-content-center">
          <img
            class="w-25"
            src="@/assets/images/gif/loader.gif"
            alt="Loading..."
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import "v-calendar/dist/style.css";
import "firebase/messaging";
import VueBasicAlert from "vue-basic-alert";
import { countries, categories } from "./data/options";

export default {
  components: {
    VueBasicAlert,
  },
  provide() {
    return {
      isStr: this.isStr,
      notify: this.notify,
      load: this.load,
      showModal: this.showModal,
      toggleModal: this.toggleModal,
      toMap: this.toMap,
      isNumber: this.isNumber,
      numberWithCommas: this.numberWithCommas,
      capitalizeFirstLetter: this.capitalizeFirstLetter,
      capitalizeFirstLetterForEachWord: this.capitalizeFirstLetterForEachWord,
      compactNumber: this.compactNumber,
      setContactFormData: this.setContactFormData,
      checkIfImageExists: this.checkIfImageExists,
      getCountry: this.getCountry,
      getCategoryLabel: this.getCategoryLabel,
      useDiffCalculator: this.useDiffCalculator,
    };
  },
  data() {
    return {
      loading: false,
      showingModal: false,
      modalConfig: {
        rider: null,
        title: null,
        description: null,
        actionTitle: "Proceed",
        actionCallback: null,
      },
    };
  },
  watch: {
    $route() {
      document.title = `Lune | ${this.$route.meta.title ?? "Overview"}`;
    },
  },
  mounted() {
    this.trackChatWidget();
    // this.injectMSClarityScript();
    this.setContactFormData();
  },
  methods: {
    checkIfImageExists(url, callback) {
      const img = new Image();
      img.src = url;

      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };

        img.onerror = () => {
          callback(false);
        };
      }
    },
    trackChatWidget() {
      const ctx = this;
      zE("webWidget:on", "open", function () {
        ctx.$gtag.event("MAY23_click_open_chat_widget", {
          event_category: "Engagement",
          event_label: "MAY23_click_open_chat_widget",
          value: 1,
        });
      });
    },
    // injectMSClarityScript() {
    //   //This script is used to dynamicly inject MS Clarity to the <head> tag
    //   let script = document.createElement("script");
    //   script.type = "text/javascript";
    //   script.text = `
    //     (function (c, l, a, r, i, t, y) {
    //       c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
    //       t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
    //       y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    //     })(window, document, "clarity", "script", '${process.env.VUE_APP_MICROSOFT_CLARITY}');
    //     `;
    //   document.head.appendChild(script);
    // },
    setContactFormData() {
      zE("webWidget", "reset");
      const userObject = JSON.parse(localStorage.getItem("user") ?? "{}");
      if (Object.keys(userObject).length > 0) {
        zE("webWidget", "prefill", {
          name: {
            value: `${userObject?.name}`,
            readOnly: false,
          },
          email: {
            value: userObject?.email,
            readOnly: false,
          },
        });
      }
    },
    notify(msg, { success: s, header: h } = { success: true, header: null }) {
      //console.log(this.$refs);
      this.$refs.alert.showAlert(
        s ? "success" : "error", // There are 4 types of alert: success, info, warning, error
        `${msg}`, // Message of the alert
        h ?? (s ? "Success" : "Oops!"), // Header of the alert
        {
          iconSize: 35, // Size of the icon (px)
          iconType: "solid", // Icon styles: now only 2 styles 'solid' and 'regular'
          position: "top right",
        } // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
      );
    },
    isStr(subject) {
      typeof subject === "string" || subject instanceof String;
    },
    toMap(arr) {
      return new Map((arr ?? []).map((e) => [e, true]));
    },
    showModal(config) {
      this.modalConfig = config;
      this.toggleModal(true);
    },
    toggleModal(state) {
      this.showingModal = state == null ? !this.showingModal : state;
    },
    load(state) {
      this.loading = state == null ? !this.loading : state;
    },
    onActionClicked() {
      if (this.modalConfig.actionCallback) {
        this.modalConfig.actionCallback();
        this.toggleModal(false);
      }
    },
    isNumber(value) {
      return typeof value === "number" && isFinite(value);
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    capitalizeFirstLetter(string) {
      if (string) return string.charAt(0).toUpperCase() + string.slice(1);
    },
    capitalizeFirstLetterForEachWord(string) {
      let words = string.trim().toLowerCase().split(" ");
      let sentence = "";

      words.forEach((word) => {
        sentence += this.capitalizeFirstLetter(word) + " ";
      });

      return sentence;
    },
    compactNumber(number, digits = 0) {
      const formatter = Intl.NumberFormat("en", {
        notation: "compact",
        ...(digits && { minimumFractionDigits: digits }),
        ...(digits && { maximumFractionDigits: digits }),
      });
      let n = formatter.format(number);
      return n;
    },

    getCountry(countryKey) {
      return countries[countryKey] ?? null;
    },
    getCategoryLabel(catId) {
      return categories[catId]?.label ?? null;
    },
    useDiffCalculator(newValArg, oldValArg) {
      let diffValue = 0;
      let diffSign = true;

      let newVal = parseFloat(newValArg);
      let oldVal = parseFloat(oldValArg);

      if (!isNaN(newVal) && !isNaN(oldVal)) {
        //calculate the diff %
        diffValue = (Math.abs(newVal) - Math.abs(oldVal)) / Math.abs(oldVal);
        diffValue = isFinite(diffValue) ? diffValue * 100 : 0;
        diffValue = Math.round(diffValue);

        //check if the diff is postive or negative
        diffSign = Math.sign(diffValue) < 0 ? false : true;
      }

      return { diffValue, diffSign };
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.4s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}
.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}
</style>
