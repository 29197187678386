<template>
  <div class="card p-3 mx-2 my-4">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title no-select me-auto text-start mb-3">
          Top {{ title }} Transactions
        </h4>
        <ActionButton
          v-if="id"
          type="button"
          label="View All Transactions"
          class="btn-route-link"
          @click="changeRoute"
        />
      </div>

      <div class="pt-3 w-100">
        <div class="text-start w-100 overflow-auto top-view-all-table">
          <table v-if="sortedList.length !== 0" class="w-100 h-100">
            <thead>
              <tr>
                <th class="pb-3 text-nowrap">Raw Description</th>
                <th class="pb-3 text-nowrap">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(tx, index) in sortedList"
                :key="tx.id"
                :class="[index == 0 ? '' : 'border-top border-light']"
              >
                <td class="w-100">
                  <div class="tx-label py-3">
                    {{ tx.raw_description }}
                  </div>
                </td>

                <td>
                  <div class="tx-label py-3 text-start text-nowrap">
                    <small
                      ><small>{{ tx?.currency_code }}</small></small
                    >
                    <span class="ps-1">{{
                      numberWithCommas(tx.amount.toFixed(0) ?? 0)
                    }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div
            v-else
            class="
              no-data-state-container
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <h5>No Data to show.</h5>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="loader px-auto py-auto">
      <span class="position-absolute top-50 start-50 translate-middle">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import ActionButton from "./helpers/ActionButton.vue";

export default {
  components: { ActionButton },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    txList: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    view: {
      type: String,

      default: "",
    },
    id: {
      type: String,
      default: null,
    },
  },
  computed: {
    currency() {
      return this.$store.state.currency;
    },
    sortedList() {
      let list = this.txList;

      list = list.sort((a, b) => {
        return (a?.amount ?? 0) - (b?.amount ?? 0);
      });

      return list;
    },
  },
  methods: {
    redirectToTxsByCategory(item) {
      this.$store.commit("updateSelectedCategoriesFilter", [item]);
      this.$router.push({
        name: "MainTransactions",
        params: {
          filters: JSON.stringify({
            multi_currency: [
              { key: this.currency.trim(), value: this.currency.trim() },
            ],
          }),
        },
      });
    },
    redirectToTxsByBrand(item) {
      this.$store.commit("updateSelectedBrandsFilter", [item]);
      this.$router.push({
        name: "MainTransactions",
        params: {
          filters: JSON.stringify({
            multi_currency: [
              { key: this.currency.trim(), value: this.currency.trim() },
            ],
          }),
        },
      });
    },

    changeRoute() {
      if (this.$route.meta.title === "Categories") {
        this.redirectToTxsByCategory(this.data);
        // this.$router.push({
        //   name: "TransactionsHistoryCategories",
        //   params: { id: this.id },
        //   query: {
        //     category_label:
        //       (categories[this.id]?.label ?? "Specific") +
        //       " Category Transactions",
        //   },
        // });
        this.$gtag.event("MAY23_click_viewall_categories_transactions", {
          event_category: "Engagement",
          event_label: "MAY23_click_viewall_categories_transactions",
          value: 1,
        });
      } else {
        this.redirectToTxsByBrand(this.data);
        // this.$router.push({
        //   name: "TransactionsHistoryBrands",
        //   params: { id: this.id, brandLabel: this.data?.name },
        // });
        this.$gtag.event("MAY23_click_viewall_brands_transactions", {
          event_category: "Engagement",
          event_label: "MAY23_click_viewall_brands_transactions",
          value: 1,
        });
      }
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style scoped>
td,
li,
ul,
span,
h2 {
  color: var(--eggplant) !important;
}
.tx-label {
  color: var(--eggplant) !important;
}

.no-data-state-container,
.top-view-all-table {
  min-height: 675px;
}
.no-data-state-container h5 {
  opacity: 0.4;
}
.top-view-all-table table thead th {
  color: var(--green);
  font-weight: 600;
  font-size: 0.93rem;
}
.top-view-all-table table tbody tr {
  border-top: 1px solid var(--span-grey);
}
.top-view-all-table table tbody tr td:first-child {
  padding-top: 1rem;
}
.top-view-all-table table tbody tr td {
  padding: 0.35rem 0;
}
</style>
