<template>
  <div ref="mainTxViewTable" class="h-100 pb-4 px-4 hide-scrollbar">
    <div class="mt-2 d-flex justify-content-between align-items-center">
      <BaseSearchAction
        :id="`search-tx-description`"
        class="w-50"
        placeholder="Search by Raw description"
        @updated-search="setSearchQry"
      />
      <TransactionsFeedbackFilter v-model="feedBackFilterValue" />
    </div>

    <div class="mt-4 pt-2 h-100">
      <UsersTransactions
        v-model:firstRow="firstRow"
        :table-rows="txData?.transactions ?? []"
        :rows="10"
        :total-records="txData?.total_records ?? 0"
        :show-paginator="true"
        :loading="txLoading"
        @page-change="onPageChange"
        @sort-change="onSortChange"
        @updateTable="updateTable"
      />
    </div>
  </div>
</template>

<script>
import TransactionsFeedbackFilter from "../components/TransactionsFeedbackFilter.vue";
import UsersTransactions from "../components/UsersTransactions.vue";
import { useStore } from "vuex";
import { ref, computed, onUnmounted, watch, onMounted } from "vue";
import BaseSearchAction from "@/components/helpers/BaseSearchAction.vue";
import OverviewAPI from "@/services/api/OverviewAPI";
import { useRoute } from "vue-router";
export default {
  components: {
    BaseSearchAction,
    UsersTransactions,
    TransactionsFeedbackFilter,
  },
  props: {
    userId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const searchQry = ref("");
    const mainTxViewTable = ref(null);

    const filters = computed(() => {
      return store.getters.filters;
    });
    const multiCurrencyFilters = computed(() => {
      return store.getters.multiCurrencyFilters;
    });
    const transactionTypesFilters = computed(() => {
      return store.getters.transactionTypesFilters;
    });
    const brandsFilter = computed(() => {
      return store.getters.brandsFilter ?? [];
    });
    const categoriesFilter = computed(() => {
      return store.getters.categoriesFilter ?? [];
    });
    const userFilters = computed(() => {
      return store.getters.userFilters;
    });
    const baseAppliedFilters = computed(() => {
      return store.state.overViewStore?.appliedFilters;
    });
    const statusFilter = computed(() => {
      if (!feedBackFilterValue.value) return null;

      return feedBackFilterValue.value.split(",");
    });

    // === States ===
    const txData = ref({});
    const txLoading = ref(false);
    const selectedPage = ref(1);
    const firstRow = ref(0);
    const feedBackFilterValue = ref("");
    const sortObject = ref({
      field: "amount",
      order: -1,
    });

    // === Methods ===
    const parseTxData = (res) => {
      const reponse = { ...res.data };

      return {
        transactions: reponse?.tenant_customers_transactions ?? [],
        total_records: reponse?.tenant_customers_transactions_count ?? 0,
      };
    };
    const updateTable = () => {
      fetch(false);
    };
    const getTransactions = (reloadTable = true, userId = null) => {
      txLoading.value = reloadTable;
      if (reloadTable) txData.value = {};
      let payloadFilters = { ...filters.value };

      //[1] Filter out the un-needed filters when a userID is specified
      if (userId) {
        payloadFilters = {
          ...userFilters.value,
        };
      }
      //[2] Fetch the transactions
      OverviewAPI.getAllUsersTransactions({
        ...payloadFilters,
        tenant_transaction_currency_codes: userId
          ? payloadFilters?.tenant_transaction_currency_codes
          : multiCurrencyFilters.value?.tenant_transaction_currency_codes ?? [],

        ...(searchQry.value && { search_by: searchQry.value }),
        ...(userId && { tenant_customer_id: userId }),
        ...(statusFilter.value && { status: statusFilter.value }),
        page: selectedPage.value,
        per_page: 10,
        sort_by: sortObject.value?.field, // amount , transaction_date
        order: sortObject.value?.order == -1 ? "desc" : "asc",
        brand_ids: brandsFilter.value ?? [],
        category_ids: categoriesFilter.value ?? [],
        tenant_transaction_type_ids:
          transactionTypesFilters.value?.transaction_types ?? [],
      })
        .then((res) => {
          txData.value = parseTxData(res);
          txLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            txData.value = {};
            txLoading.value = false;
            console.error("error: ", error);
          }
        });
    };

    const scrollTop = () => {
      if (mainTxViewTable.value !== null) mainTxViewTable.value.scrollTop = 0;
    };
    const fetch = (reload = true) => {
      //[1] Abort all previous pending requests
      store.dispatch("CANCEL_PENDING_REQUESTS");

      //[2] scroll to top of the table
      scrollTop();
      return getTransactions(reload, props.userId);
    };
    const setSearchQry = (qry) => {
      //[1] Update Table paginator + page query param
      firstRow.value = 0;
      selectedPage.value = 1;

      //[2] set qry
      searchQry.value = qry;

      // //[3] Abort all previous pending requests
      // store.dispatch("CANCEL_PENDING_REQUESTS");

      //[4] fetch
      fetch();
    };
    const onPageChange = (obj) => {
      //[1] sace new page state
      const newSelectedPage = obj?.page + 1 ?? 1;
      selectedPage.value = newSelectedPage;

      //[2] Fetch new tx's
      fetch();
    };

    const onSortChange = (obj) => {
      //[1] Reset Pagination page state
      firstRow.value = 0;
      selectedPage.value = 1;

      //[2] Set new sort option
      sortObject.value = { ...obj };

      //[3] Fetch new tx's
      fetch();
    };
    const routeFiltersExsits = () => {
      let params;

      if (route.params?.filters)
        params = JSON.parse(route.params?.filters ?? {});

      if (Object.keys(params ?? []).length !== 0) {
        const newFilters = {
          ...baseAppliedFilters.value,
          ...params,
        };
        store.commit("updateAppliedFilters", {
          ...newFilters,
        });

        return true;
      }

      return false;
    };
    const timeFiltersExsits = () => {
      let params;

      if (route.params?.timeFilter)
        params = JSON.parse(route.params?.timeFilter ?? {});

      if (Object.keys(params ?? []).length !== 0) {
        const newFilters = {
          // ...baseAppliedFilters.value,
          ...params,
        };
        store.commit("updateTimeFilters", {
          ...newFilters,
        });

        return true;
      }

      return false;
    };

    const resetBrandsAndCategoriesFilters = () => {
      store.commit("updateSelectedBrandsFilter", []);
      store.commit("updateSelectedCategoriesFilter", []);
    };

    //watchers
    watch(
      [filters, statusFilter],
      // eslint-disable-next-line no-unused-vars
      ([newFilters, newStatusFilter], [oldnewFilters, oldStatusFilter]) => {
        //[1] Update Table paginator + page query param
        firstRow.value = 0;
        selectedPage.value = 1;

        //[2] Abort + Filter
        if (oldnewFilters?.tenant_transaction_from_date) {
          // //[1] Abort all previous pending requests
          // store.dispatch("CANCEL_PENDING_REQUESTS");

          //[2] Fetch new requests
          fetch();
        }
      }
    );

    watch(brandsFilter, () => {
      if (store.state.overViewStore.clearSelectedBrands) {
        store.commit("updateClearSelectedBrands", false);
        return;
      }
      //[1] Update Table paginator + page query param
      firstRow.value = 0;
      selectedPage.value = 1;

      //[2] Fetch new requests
      fetch();
      // }
    });

    watch(categoriesFilter, () => {
      if (store.state.overViewStore.clearSelectedCategories) {
        store.commit("updateClearSelectedCategories", false);
        return;
      }
      //[1] Update Table paginator + page query param
      firstRow.value = 0;
      selectedPage.value = 1;

      //[2] Fetch new requests
      fetch();
      // }
    });

    //hooks
    onMounted(() => {
      //[0] fetch transaction types options
      store.dispatch("getFilterTransactionTypes");

      //[1] fetch user accounts to be used in "Account Types Filter"
      if (route.params?.userId) {
        store.dispatch("getUserAccounts", route.params?.userId);
      }

      //[2] fetch transactions
      if (routeFiltersExsits() || timeFiltersExsits()) {
        //[2.1] set passed filters if exists and the watch will fetch it
        return;
      } else {
        //[2.2] Fetch the new data with default filters
        fetch();
      }
    });
    onUnmounted(() => {
      //[1] Abort all previous pending requests
      store.dispatch("CANCEL_PENDING_REQUESTS");

      //[2] Reset Brands and Categories filters
      resetBrandsAndCategoriesFilters();
    });

    return {
      mainTxViewTable,
      searchQry,
      setSearchQry,
      txData,
      txLoading,
      onPageChange,
      onSortChange,
      firstRow,
      updateTable,
      feedBackFilterValue,
    };
  },
};
</script>

<style lang="scss" scoped></style>
