<template>
  <div class="h-100 pb-4 px-4 hide-scrollbar">
    <div id="OverviewBaseViewInfoText" class="py-4">
      <BaseViewInfoText v-model="dateRangeLoading" />
    </div>
    <div>
      <OverviewSummaryCards
        id="OverviewSummaryCards"
        class="py-4"
        :summary-data="summaryData"
        :loading="summaryDataLoading"
      />

      <div
        class="pt-4 mb-4 w-100 h-100 d-flex flex-wrap justify-content-between"
      >
        <!-- User Spend Chart -->
        <BaseCardView
          id="CustomersSpendChart"
          class="col-12 p-4 px-0"
          :title="`Customers ${metricFilters?.value ?? 'Spend'} `"
          :loading="spendChartLoading"
        >
          <template #header>
            <div
              v-if="!spendChartLoading"
              class="
                flex-grow-1
                d-flex
                align-items-center
                justify-content-end
                flex-nowrap
                gap-3
              "
            >
              <BaseAmountTitle
                :show-currency="true"
                color="#99ABAA"
                :amount="
                  metricFilters.key === 'average'
                    ? chartSumData?.relAvgSum
                    : chartSumData?.relTotalSum
                "
              />
              <img
                src="@/assets/images/svg/components/v-line-sm.svg"
                alt="line svg"
              />
              <BaseAmountTitle
                :show-currency="true"
                :show-trend-sign="true"
                :amount="
                  metricFilters.key === 'average'
                    ? chartSumData?.avgSum
                    : chartSumData?.totalSum
                "
                :is-trending-positively="
                  metricFilters.key === 'average'
                    ? chartSumData?.avgDiffPercentage?.diffSign
                    : chartSumData?.totalDiffPercentage?.diffSign
                "
                :is-clickable="true"
                title="View All Transactions"
                @click="routeToTxs"
              />
            </div>
          </template>

          <template #content>
            <CustomersSpendChart
              v-model:chartSumData="chartSumData"
              :data="spendChartData"
            />
          </template>
        </BaseCardView>
        <!-- ---- ---- ---- -- -->

        <!-- Top Brands Table -->
        <BaseCardView
          id="TopBrandsTableCard"
          class="col-6 p-4 px-0 pe-4"
          title="Brands"
          :loading="brandsDataLoading"
        >
          <template #content>
            <TopBrandsTableCard :data="brandsData" />
          </template>
        </BaseCardView>
        <!-- ---- ---- ---- -- -->

        <!-- Top Cats Table -->
        <BaseCardView
          id="TopCategoriesTableCard"
          class="col-6 p-4 px-0 ps-4"
          title="Categories"
          :loading="categoriesDataLoading"
        >
          <template #content>
            <TopCategoriesTableCard :data="categoriesData" />
          </template>
        </BaseCardView>
        <!-- ---- ---- ---- -- -->

        <!-- Ages Bar Chart -->
        <BaseCardView
          id="AgeBarChart"
          class="col-6 p-4 px-0 pe-4"
          title="Age"
          :loading="ageChartLoading"
        >
          <template #content>
            <AgeBarChart :data="ageChartData" />
          </template>
        </BaseCardView>
        <!-- ---- ---- ---- -- -->

        <!-- Cashflow Chart -->
        <BaseCardView
          id="OverviewCashFlow"
          class="col-6 p-4 px-0 ps-4"
          title="Cashflow"
          :loading="cashFlowLoading"
        >
          <template #content>
            <OverviewCashFlow :data="cashFlowData" />
          </template>
        </BaseCardView>
        <!-- ---- ---- ---- -- -->

        <!-- Spend Per Currency Chart -->
        <BaseCardView
          id="SpendPerItemCardCurrencies"
          class="col-6 p-4 px-0 pe-4"
          title="Spend Per Currency"
          :loading="spendPerCurrencyLoading"
        >
          <template #content>
            <SpendPerItemCard
              :data="spendPerCurrencyData"
              view-all-label="All Currencies"
              component-name="currencies"
              @on-viewall-click="$router.push({ name: 'AllCurrencies' })"
            />
          </template>
        </BaseCardView>
        <!-- ---- ---- ---- -- -->

        <!-- Spend Per tx Type Chart -->
        <BaseCardView
          id="SpendPerItemCardTypes"
          class="col-6 p-4 px-0 ps-4"
          title="Spend Per Transaction Type"
          :loading="spendPerTxTypeLoading"
        >
          <template #content>
            <SpendPerItemCard
              :data="spendPerTxTypeData"
              view-all-label="All Types"
              component-name="types"
              @on-viewall-click="$router.push({ name: 'AllTxType' })"
          /></template>
        </BaseCardView>
        <!-- ---- ---- ---- -- -->
      </div>
    </div>
  </div>
</template>
<script>
import SpendPerItemCard from "../components/SpendPerItemCard.vue";
import OverviewCashFlow from "@/components/OverviewCashFlow.vue";
import BaseAmountTitle from "../components/helpers/BaseAmountTitle.vue";
import OverviewSummaryCards from "../components/OverviewSummaryCards.vue";
import BaseCardView from "../components/helpers/BaseCardView.vue";
import BaseViewInfoText from "../components/helpers/BaseViewInfoText.vue";
import TopBrandsTableCard from "../components/TopBrandsTableCard.vue";
import TopCategoriesTableCard from "../components/TopCategoriesTableCard.vue";
import CustomersSpendChart from "../components/CustomersSpendChart.vue";
import AgeBarChart from "../components/AgeBarChart.vue";
import { useStore } from "vuex";
import { computed, onUnmounted, onMounted, ref, watch } from "vue";
import moment from "moment";
import OverviewAPI from "../services/api/OverviewAPI";
import { useRouter } from "vue-router";

export default {
  components: {
    CustomersSpendChart,
    OverviewSummaryCards,
    BaseCardView,
    TopBrandsTableCard,
    TopCategoriesTableCard,
    BaseAmountTitle,
    BaseViewInfoText,
    AgeBarChart,
    OverviewCashFlow,
    SpendPerItemCard,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const summaryData = ref({});
    const summaryDataLoading = ref(false);
    const brandsData = ref([]);
    const brandsDataLoading = ref(false);
    const categoriesData = ref([]);
    const categoriesDataLoading = ref(false);
    const spendChartData = ref({});
    const spendChartLoading = ref(false);
    const chartSumData = ref(null);
    const ageChartLoading = ref(false);
    const ageChartData = ref(null);
    const cashFlowLoading = ref(false);
    const cashFlowData = ref(null);
    const dateRangeLoading = ref(false);
    const spendPerCurrencyLoading = ref(false);
    const spendPerCurrencyData = ref([]);
    const spendPerTxTypeLoading = ref(false);
    const spendPerTxTypeData = ref([]);

    //computed
    const currency = computed(() => store.state.currency);
    const exportPDFLoadingState = computed(() => {
      let loadedAllData =
        summaryDataLoading.value ||
        brandsDataLoading.value ||
        categoriesDataLoading.value ||
        spendChartLoading.value ||
        ageChartLoading.value ||
        cashFlowLoading.value ||
        spendPerCurrencyLoading.value ||
        spendPerTxTypeLoading.value ||
        dateRangeLoading.value;

      return loadedAllData;
    });

    const filters = computed(() => {
      return store.getters.filters;
    });
    const metricFilters = computed(
      () => store.state.overViewStore.metricFilters
    );
    const timeFilters = computed(() => store.state.overViewStore.timeFilters);

    //functions
    const formatDate = (date) => {
      return moment(date).format("D MMM, YYYY");
    };
    const setupTransfersSpendData = (dataList, type) => {
      if (dataList.length === 0) return [];

      let data = [];
      dataList.forEach((item) => {
        data.push({
          ...item,
          tx_count: item?.tenant_customers_transactions_count,
          users_count: item?.tenant_customers_count,
          label:
            type === "currency"
              ? item?.tenant_customers_currency_code
              : item?.tenant_transaction_type,
          total_amount: item?.tenant_customers_total_spend,
          avg_amount: item?.tenant_customers_avg_spend,
          rel_total_amount:
            item?.comparison_period_tenant_customers_total_spend,
          rel_avg_amount: item?.comparison_period_tenant_customers_avg_spend,
          rel_users_count: item?.comparison_period_tenant_customers_count,
        });
      });

      return data;
    };
    const getUserSummary = () => {
      summaryDataLoading.value = true;
      OverviewAPI.getUserSummary(filters.value)
        .then((res) => {
          summaryData.value = res.data;
          summaryDataLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            summaryDataLoading.value = false;
            console.error("error: ", error);
          }
        });
    };
    const getBrandsSummaryData = () => {
      brandsDataLoading.value = true;
      OverviewAPI.getBrandsSummaryData(filters.value)
        .then((res) => {
          brandsData.value = res.data?.brands ?? [];
          brandsDataLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            brandsData.value = [];
            brandsDataLoading.value = false;
            console.error("error: ", error);
          }
        });
    };
    const getCategoriesSummaryData = () => {
      categoriesDataLoading.value = true;
      OverviewAPI.getCategoriesSummaryData(filters.value)
        .then((res) => {
          categoriesData.value = res.data?.categories ?? [];
          categoriesDataLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            categoriesData.value = [];
            categoriesDataLoading.value = false;
            console.error("error: ", error);
          }
        });
    };
    const getSpendChartData = () => {
      spendChartLoading.value = true;
      spendChartData.value = [];

      OverviewAPI.getSpendChartData(
        filters.value,
        timeFilters.value?.periodFilter ?? 30
      )
        .then((res) => {
          spendChartData.value = [...(res.data?.tenant_customers_spends ?? [])];
          spendChartLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            spendChartData.value = [];
            spendChartLoading.value = false;
            console.error("error: ", error);
          }
        });
    };
    const getAgesChartData = () => {
      //Remove unsed filters
      const queryFilters = { ...filters.value };

      ageChartLoading.value = true;

      OverviewAPI.getUsersAgesData(queryFilters)
        .then((res) => {
          ageChartData.value = res.data?.demographics ?? [];
          ageChartLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            ageChartData.value = [];
            ageChartLoading.value = false;
            console.error("error: ", error);
          }
        });
    };
    const getOverviewCashFlowData = () => {
      cashFlowLoading.value = true;
      OverviewAPI.getOverviewCashFlowData(filters.value)
        .then((res) => {
          const resData = { ...res.data };

          cashFlowData.value = {
            cashflow: {
              inflow: resData?.deposits ?? 0,
              outflow: resData?.expenditure ?? 0,
              rel_inflow: resData?.comparison_period_deposits ?? 0,
              rel_outflow: resData?.comparison_period_expenditure ?? 0,
            },
          };
          cashFlowLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            cashFlowData.value = {};
            cashFlowLoading.value = false;
            console.error("error: ", error);
          }
        });
    };
    const getSpendPerCurrencyData = () => {
      spendPerCurrencyLoading.value = true;
      const queryFilters = { ...filters.value };
      delete queryFilters.tenant_transaction_currency_codes;

      OverviewAPI.getSpendPerCurrencyData(queryFilters)
        .then((res) => {
          spendPerCurrencyData.value = setupTransfersSpendData(
            res.data?.tenant_customers_spends ?? [],
            "currency"
          );
          spendPerCurrencyLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            spendPerCurrencyData.value = [];
            spendPerCurrencyLoading.value = false;
            console.error("error: ", error);
          }
        });
    };
    const getSpendPerTxTypeData = () => {
      spendPerTxTypeLoading.value = true;
      OverviewAPI.getSpendPerTxTypeData(filters.value)
        .then((res) => {
          spendPerTxTypeData.value = setupTransfersSpendData(
            res.data?.tenant_customers_spends ?? [],
            "txType"
          );
          spendPerTxTypeLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            spendPerTxTypeData.value = [];
            spendPerTxTypeLoading.value = false;
            console.error("error: ", error);
          }
        });
    };

    const fetchData = () => {
      //All Parrallel requests
      getUserSummary();
      getBrandsSummaryData();
      getCategoriesSummaryData();
      getSpendChartData();
      getAgesChartData();
      getOverviewCashFlowData();
      getSpendPerCurrencyData();
      getSpendPerTxTypeData();
    };

    const routeToTxs = () => {
      router.push({
        name: "MainTransactions",
        params: {
          filters: JSON.stringify({
            multi_currency: [
              { key: currency.value.trim(), value: currency.value.trim() },
            ],
          }),
        },
      });
    };

    onMounted(() => {
      //fetch on mount
      fetchData();
    });
    //watchers
    watch(filters, (currFilters, prevFilters) => {
      if (prevFilters?.tenant_transaction_from_date) {
        //[1] Abort all previous pending requests
        store.dispatch("CANCEL_PENDING_REQUESTS");

        //[2] Fetch new requests
        fetchData();
      }
    });

    watch(exportPDFLoadingState, (newVal) => {
      store.commit("updateLoadingDahboardData", newVal);
    });

    //hooks
    onUnmounted(() => {
      store.dispatch("CANCEL_PENDING_REQUESTS");
    });

    return {
      filters,
      formatDate,
      summaryDataLoading,
      brandsDataLoading,
      categoriesDataLoading,
      summaryData,
      brandsData,
      categoriesData,
      metricFilters,
      spendChartData,
      spendChartLoading,
      chartSumData,
      ageChartData,
      ageChartLoading,
      cashFlowData,
      cashFlowLoading,
      spendPerCurrencyLoading,
      spendPerCurrencyData,
      spendPerTxTypeLoading,
      spendPerTxTypeData,
      routeToTxs,
      dateRangeLoading,
    };
  },
};
</script>
<style>
.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
