<template>
  <div
    class="base-drop-down custom-dropdown"
    :class="{ 'large-dd-input': size === 'large' }"
  >
    <div v-if="String(label).length > 0 && showLabel">
      <label v-if="showLabel" :for="'input_$' + id" class="form-label">{{
        label
      }}</label>
    </div>
    <div class="h-100">
      <Dropdown
        v-model="inputValue"
        :loading="loading"
        v-bind="{ ...$attrs }"
        class="h-100 w-100"
        :class="{ 'p-invalid': !!inputError }"
        :input-id="'input_$' + id"
        :options="options ?? {}"
        option-label="value"
        option-value="key"
        :disabled="isDisabled"
        scroll-height="280px"
        :dropdown-icon="isDisabled ? '' : 'pi pi-chevron-down'"
        :placeholder="!isDisabled ? placeholder : '-'"
      >
      </Dropdown>
      <span v-if="!isDisabled" class="error-message">{{
        inputError ?? ""
      }}</span>
    </div>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import { useField } from "vee-validate";
import { toRef } from "vue";

export default {
  components: {
    Dropdown,
  },
  props: {
    label: {
      type: String,
      default: "Input Label",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Array, Object],
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: [String, Number],
    },
    placeholder: {
      type: String,
    },
    options: {
      type: [Array, Object],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
  },
  emits: ["selected-option"],
  setup(props) {
    const name = toRef(props, "name");
    const id = toRef(props, "id");

    const { value: inputValue, errorMessage: inputError } = useField(
      name.value ?? id.value,
      undefined,
      {
        initialValue: null,
      }
    );

    return { inputValue, inputError, name };
  },
  computed: {
    icon() {
      return require("@/assets/images/svg/sidebar/dashboard.svg");
    },
  },
  watch: {
    value(newVal) {
      this.inputValue = newVal;
    },
    inputValue(newVal) {
      this.$emit("selected-option", newVal);
    },
  },
  // data() {
  //   return {
  //     selectedOption: null, //{ key: "AE", value: "United Arab Emirates"},
  //   };
  // },
  mounted() {
    this.inputValue = this.value ?? null;
  },
};
</script>

<style>
.custom-dropdown .form-label {
  color: var(--green);
}
.custom-dropdown label {
  font-size: var(--fs-input);
  font-weight: 400;
}

.p-dropdown {
  /* width: 100%; */
  border-radius: 10px !important;
  box-shadow: none !important;
  border: 1px solid var(--dark-green-20, #ccd5d4);
  transition: all 0.4s ease;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: var(--sea) !important;
}
.p-dropdown:not(.p-disabled).p-focus {
  border-color: var(--sea) !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: var(--green);
  font-size: 0.9rem !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-focus {
  background-color: var(--teal-10) !important;
}
.p-dropdown .p-dropdown-label {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--green);
}
.p-dropdown .p-inputtext {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 10px 10px;
  background: white;
  color: var(--green);
  line-height: 173%;
  font-family: "Poppins", "Inter", Avenir, Helvetica, Arial, sans-serif !important;
  transition: all 0.4s ease;
}
.base-drop-down .p-dropdown .p-dropdown-label.p-placeholder {
  color: var(--bluey-grey-50) !important;
  font-size: 0.88rem;
  font-weight: 400;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--green);
  background: var(--bluey-grey-10) !important;
}
.p-dropdown.p-disabled {
  background: transparent !important;
  opacity: 1 !important;
  border: none !important;
}
.p-dropdown.p-disabled .p-inputtext {
  color: var(--green) !important;
  font-size: 0.9rem;
  font-weight: 600;
  padding-left: 0px;
}
.p-dropdown .p-dropdown-trigger {
  color: var(--green);
  /* opacity: 0.55; */
  transition: all 0.4s ease;
}
.p-dropdown:hover .p-dropdown-trigger {
  opacity: 1;
}
.p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon.pi {
  font-size: 1rem !important;
  -webkit-text-stroke: 0.4px white;
}

.large-dd-input {
  height: 100%;
}

.large-dd-input .p-dropdown {
  border: 1px solid white;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.large-dd-input .p-dropdown .p-inputtext {
  font-size: 1rem;
}
</style>
