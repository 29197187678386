<template>
  <vue-recaptcha
    v-show="showRecaptcha"
    ref="vueRecaptcha"
    :sitekey="siteKey"
    @verify="recaptchaVerified"
    @expire="recaptchaExpired"
    @fail="recaptchaFailed"
  >
  </vue-recaptcha>
</template>

<script>
import vueRecaptcha from "vue3-recaptcha2";

export default {
  name: "App",
  components: {
    vueRecaptcha,
  },
  data() {
    return {
      showRecaptcha: true,
    };
  },
  computed: {
    siteKey() {
      return process.env.VUE_APP_GOOGLE_CAPTCH_KEY;
    },
  },
  methods: {
    recaptchaVerified(response) {
      this.$emit("valid", response);
      //console.log(response)
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      console.error("Captch Failed 🛠️");
    },
  },
};
</script>
