<template>
  <div>
    <BarChart
      :chart-data="chartData"
      :options="chartOptions"
      :plugins="plugins"
    />
  </div>
</template>

<script>
import { computed, inject, reactive, ref, watch } from "vue";
import { BarChart } from "vue-chart-3";
import { colorMapper } from "@/composables/Helpers";

export default {
  components: { BarChart },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: null,
    },
    tooltip: {
      type: Function,
      default: null,
    },
  },
  emits: ["on-update"],
  setup(props, { emit }) {
    const compactNumber = inject("compactNumber");

    const chartData = computed(() => ({
      datasets: [
        {
          label: "Data",
          data: [...props.data.slice(0, 8)],
          borderColor: "#469B93",
          hoverBackgroundColor: "#002E2A",
          backgroundColor: colorMapper([...props.data.slice(0, 8)]),
          tension: 0.05,
          borderRadius: 5,
          borderSkipped: false,
          order: 0,
          yAxisID: "dataLabelValues",
        },
      ],
    }));

    const selectedData = ref({ ...props.data[0] });
    const defaultOptions = ref({
      onClick: (event, elements, chart) => {
        if (elements[0]) {
          const i = elements[0]?.index;
          selectedData.value = { ...chart.data.datasets[0].data[i] };
        }
      },
      categoryPercentage: 0.45,
      indexAxis: "y",
      interaction: {
        mode: "index",
        axis: "y",
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
          position: "top",
          align: "start",
          reverse: true,
          labels: {
            usePointStyle: true,
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        tooltip: {
          events: ["click"],
          enabled: false,
          external: props.tooltip,
          callbacks: {},
        },
      },
      scales: {
        dataLabelValues: {
          grid: {
            display: false,
            drawBorder: false,
          },
          min: 0,
          ticks: {
            crossAlign: "far", //left align text
            padding: 5,
            font: {
              size: 11,
            },
            color: "#002E2A",
            // eslint-disable-next-line no-unused-vars
            callback: function (value, index, values) {
              const dataList = [...props.data];

              if (dataList.length === 0) return 0;

              const xValue = dataList[index]?.x ?? 0;
              return compactNumber(xValue, 2);
            },
          },
          display: true,
          position: "right",
        },
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
          min: 0,
          ticks: {
            crossAlign: "far", //left align text
            padding: 5,
            font: {
              size: 11,
            },
            color: "#66827F",
            // eslint-disable-next-line no-unused-vars
            callback: function (value, index, values) {
              const label = this.getLabelForValue(index) ?? "--";
              if (label.length > 4) {
                return label.slice(0, 4) + "..";
              }
              return label;
            },
          },
        },
        x: {
          display: false,
        },
      },
    });
    const chartOptions = computed(() => {
      return { ...(props.options ?? defaultOptions.value) };
    });
    const barHighlight = reactive({
      id: "barHighlightCustom",
      //draw behind dataset
      beforeDraw(chart, args, pluginOptions) {
        // console.log(chart);
        const {
          data,
          ctx,
          tooltip,
          chartArea: { top, height, width },
          scales: { x, y },
        } = chart;

        //save configs
        ctx.save();

        //specific bar height
        const segmentHeight = y.height / data.labels.length;

        if (tooltip?._active[0]) {
          const yCoor =
            y.getPixelForValue(tooltip._active[0].index) - segmentHeight / 2;
          ctx.fillStyle = "rgba(237, 245, 244, 1)";
          //ctx.fillRect(x, y, w, h)
          ctx.fillRect(0, yCoor, 1500, segmentHeight);
        } else {
          ctx.fillStyle = "rgba(237, 245, 244, 1)";
          ctx.fillRect(0, 11, 1500, segmentHeight);
        }
      },
    });
    const plugins = ref([barHighlight]);

    watch(selectedData, (newVal, oldVal) => {
      if (newVal?.y !== oldVal?.y) {
        // console.log("Update Selected: ", newVal);
        emit("on-update", { ...newVal });
      }
    });

    return { chartData, chartOptions, plugins };
  },
};
</script>

<style></style>
