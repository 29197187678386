<template>
  <div>
    <BarChart
      :chart-data="chartData"
      :options="chartOptions"
      :plugins="plugins"
    />
  </div>
</template>

<script>
import { computed, inject, ref } from "vue";
import { BarChart } from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  components: { BarChart },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: null,
    },
    tooltip: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const plugins = [ChartDataLabels];
    const compactNumber = inject("compactNumber");

    const chartData = computed(() => ({
      datasets: [
        {
          label: "Age Groupes",
          data: [...props.data],
          borderColor: "#469B93",
          hoverBackgroundColor: "#002E2A",
          backgroundColor: "#469B93",
          tension: 0.05,
          borderRadius: 5,
          order: 0,
        },
      ],
    }));

    const defaultOptions = ref({
      categoryPercentage: 0.6,
      interaction: {
        mode: "index",
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
          position: "top",
          align: "start",
          reverse: true,
          labels: {
            usePointStyle: true,
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        tooltip: {
          enabled: props.tooltip === null ? true : false,
          external: props.tooltip,
        },
        datalabels: {
          color: "white",
          display: true,
          font: {
            size: 9,
            weight: 500,
          },
          formatter: (value) => {
            const dataList = [...props.data];

            //[0] if list is empty just exit -> no need for calculations
            if (dataList.length === 0) return "0%";

            //[1] calc total sum
            const totalSum = dataList.reduce(
              (prev, curr) => prev + (curr?.y ?? 0),
              0
            );

            //[2] calc the percentage for each
            let percentage = 0;
            percentage = Math.round((value?.y / totalSum) * 100);
            percentage = isFinite(percentage) ? percentage : 0;

            return percentage + "%";
          },
        },
      },
      scales: {
        y: {
          grid: {
            display: false,
            drawBorder: true,
          },
          min: 0,
          ticks: {
            padding: 5,
            font: {
              size: 10,
            },
            color: "#66827F",
            // eslint-disable-next-line no-unused-vars
            callback: function (value, index, values) {
              return compactNumber(value);
            },
          },
        },
        x: {
          grid: {
            display: false,
            drawBorder: true,
          },
          ticks: {
            padding: 5,
            font: {
              size: 10,
            },
            color: "#66827F",
          },
        },
      },
    });

    const chartOptions = computed(() => {
      return { ...(props.options ?? defaultOptions.value) };
    });

    return { chartData, chartOptions, plugins };
  },
};
</script>

<style></style>
