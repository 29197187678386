<template>
  <slot :loading="loading" :data="data"> </slot>
  <div v-if="!isCustom">
    <div v-if="!loading">
      <h6>{{ text || defaultText }}</h6>
    </div>
    <div v-else>
      <h6 class="opacity-50">Loading...</h6>
    </div>
  </div>
</template>
<script>
import OverviewAPI from "@/services/api/OverviewAPI";
import moment from "moment";
export default {
  props: {
    text: {
      type: String,
      required: false,
      default: "",
    },
    isCustom: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      data: {},
      loading: false,
    };
  },
  computed: {
    filters() {
      return this.$store.getters.filters;
    },
    defaultText() {
      if (
        this.data?.min_tenant_transaction_date &&
        this.data?.max_tenant_transaction_date
      )
        return `Showing information from ${this.formatDate(
          this.data?.min_tenant_transaction_date
        )} to ${this.formatDate(this.data?.max_tenant_transaction_date)}`;

      return "No data results for the selected time range";
    },
  },
  watch: {
    filters(current, old) {
      if (old.tenant_transaction_from_date) this.fetchMinMaxDates();
    },
    loading(current) {
      this.$emit("update:modelValue", current);
    },
  },
  mounted() {
    this.fetchMinMaxDates();
  },
  methods: {
    formatDate(date) {
      return moment(date).format("D MMM, YYYY");
    },
    fetchMinMaxDates() {
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");

      this.loading = true;
      OverviewAPI.getMinMaxdates(this.filters)
        .then((response) => {
          this.data = response?.data;
          this.loading = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            this.loading = false;
            this.data = {};
            console.error("error: ", error);
          }
        });
    },
  },
};
</script>
<style scoped>
h6 {
  color: var(--dark-green-100, #002e2a);
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
</style>
