import axios from "axios";
import Qs from "qs";
import { categories, profiles } from "../data/options.js";
import store from "@/store/index";
import { useMockFetch } from "@/composables/Helpers.js";

const DEBUG = process.env.NODE_ENV === "development";
let token;

function isAuthenticated() {
  token = localStorage.getItem("token");
  return {
    isAuthorized: ![null, undefined, ""].includes(token?.trim()),
    token,
  };
}

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_LUNE_BASE_URL,
  withCredentials: false,

  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    /** In dev, intercepts request and logs it into console for dev */
    // if (DEBUG) { console.info("✉️ ", config.headers); }
    //[1] Add abort controller
    const avoidedRequests = [
      "/tenant_customer_accounts/categories",
      "/tenant_customer_accounts/segments",
      "/tenant_transactions/types",
      "/update-user",
    ];

    if (
      !avoidedRequests.includes(config.url) &&
      config?.isAbortable !== false
    ) {
      //Abort Signal to handle request cancelation
      //[1] Init AbortController
      let abortController = new AbortController();

      //[2] Attach the controller
      config.signal = abortController.signal;

      //[3] Add to vuex to make cancellation available from anywhere
      store.commit("ADD_CANCEL_TOKEN", abortController);
    }

    //[2] check toke Auth
    const { token, isAuthorized } = isAuthenticated();
    config.headers = {
      ...config.headers,
      ...(isAuthorized && { Authorization: token }),
    };

    return config;
  },
  (error) => {
    if (DEBUG) {
      console.error("✉️ ", error);
    }
    return Promise.reject(error);
  }
);
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      //no redirection if user already on loagin screen
      if (window.location.pathname === "/auth/login")
        return Promise.reject(error.message);

      window.location = "/auth/login";
      localStorage.clear(); //clear the storage
    }

    if (error.response && error.response?.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

let savedProfiles;

//change it to fetch either dummy data or server data
let isServerEnabled = true;
const avgMonthlySpend = 15782;

//Transform Get Requests
function getWithParams(url, filters, page, options = {}) {
  return apiClient.get(url, {
    ...options,
    params: {
      ...filters,
    },
    paramsSerializer: function (params) {
      let queryString = "";
      let filters = { ...params };

      //Nested Params: Age and Income
      let nestedParams = "";
      let pageString = "";

      if (page !== null && page !== undefined) {
        pageString = `&page=${page}`;
      }

      if ("tenant_customers_ages" in filters) {
        let ageFilter = JSON.stringify(filters?.tenant_customers_ages[0]);
        delete filters.tenant_customers_ages;
        filters["tenant_customers_ages"] = [ageFilter];
      }

      if ("tenant_customers_incomes" in filters) {
        let incomeFilter = JSON.stringify(filters?.tenant_customers_incomes[0]);
        delete filters.tenant_customers_incomes;
        filters["tenant_customers_incomes"] = [incomeFilter];
      }

      if ("tenant_transaction_currency_codes" in filters) {
        let currencyFilter = filters["tenant_transaction_currency_codes"];
        delete filters.tenant_transaction_currency_codes;
        filters["tenant_transaction_currency_codes"] = currencyFilter;
      }

      //Nationality, Gender, etc...
      const mainParams = Qs.stringify(filters, { arrayFormat: "brackets" });
      queryString = mainParams + nestedParams + pageString;

      return queryString;
    },
  });
}

function generateDummyData() {
  let data = {};

  // console.log(store.thisYearData);

  for (let i = 1; i <= 18; i++) {
    data[i] = {
      avg_spend_per_cat: (Math.random() * 10000).toFixed(2),
      delta_avg_spend_per_cat: (
        Math.random() *
        10 *
        (Math.random() < 0.5 ? -1 : 1)
      ).toFixed(0),
    };
  }

  return data;
}

async function handlePagenation(_res, route) {
  // Handle Pageination
  // 1- fetch first page
  let page = 1;
  let allProducts = [...(_res?.results ?? [])];
  let hasPages = _res?.next != null;

  // 2 - Check if other pages Exisits and fetch them
  while (hasPages) {
    let response = await apiClient.get(`${route}?page=${page + 1}`);
    if (response.status < 300) {
      allProducts = [...allProducts, ...response.data?.results];
      _res.results = [...allProducts];
      hasPages = response.data?.next != null;
      page++;
    } else {
      console.log("failed to get other pages products list");
    }
  }

  //console.log('All Products : ', allProducts)
  return allProducts;
}

function generateSpendingCardData(filter) {
  let data = {};

  let profile = profiles[filter.profile ?? "all_users"];

  let period = filter?.days_filter ?? 30;
  let _monthlySpend = Math.ceil(
    ((avgMonthlySpend * profile.metrics.quota) / 30) * period
  );

  for (let i = 1; i <= Object.values(categories).length; i++) {
    let _q = categories[i].quota;

    if (_q != 0) {
      data[i] = {
        avg_spend_per_cat: _monthlySpend * _q,
        delta_avg_spend_per_cat: getSpendingCardBuffer(_q, [
          profile.id,
          period,
          "cat",
          i,
          "avg_spend",
        ]),

        avg_perc_per_cat: _q * 100,
        delta_avg_perc_per_cat: getSpendingCardBuffer(_q, [
          profile.id,
          period,
          "cat",
          i,
          "avg_perc",
        ]),
      };
    }
  }

  return data;
}

function storeCashFlowData(filter, value) {
  //create key
  let period = filter?.days_filter ?? 30;
  let profile = profiles[filter.profile ?? "all_users"].id;

  let cashFlowKey = `${profile}~${period}`;

  //getItem(Key)
  let _cashFlowValues = localStorage.getItem(cashFlowKey);

  //check if key exists in local storage
  //if yes -> return it
  //if no -> create random values and store them with the created key then return it
  if (_cashFlowValues) {
    return JSON.parse(_cashFlowValues);
  } else {
    //store new values
    localStorage.setItem(cashFlowKey, JSON.stringify(value));
    return value;
  }
}

function generateCashFlowStat(filter) {
  //let _cacheflow = getCashflow(filter);

  //if avilable then get it
  // if (_cacheflow) {
  //   console.log("I found it: ",_cacheflow)
  //   return _cacheflow;
  // }

  // //create new one and save it
  // else {
  let delta_deposits = Math.floor(Math.random() * 100) + 1;
  let delta_expenditure = 100 - delta_deposits;

  let deposits = Math.floor(Math.random() * 20) - 5;
  let expenditure = Math.floor(Math.random() * 20) - 5;

  return storeCashFlowData(filter, {
    delta_deposits,
    delta_expenditure,
    deposits,
    expenditure,
  });
  //}
}

function formatDate(date) {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  date = yyyy + "-" + mm + "-" + dd;
  return date;
}

function randInRange(max, min = 0) {
  return Math.ceil(Math.random() * (max - min) + min);
}

function randomInRange(max, min = 0) {
  return Math.random() * (max - min) + min;
}

function getChartBuffer([...args]) {
  let _storageKey = "__ch__";

  for (const arg of args) _storageKey += `__${arg}`;

  // console.log(_storageKey)

  let _initValue = localStorage.getItem(_storageKey);

  if (_initValue) {
    return eval(_initValue);
  } else {
    let value = randomInRange(0.8, 1);
    localStorage.setItem(_storageKey, value);
    return value;
  }
}

function getSpendingCardBuffer(quota, [...args]) {
  let _storageKey = "__c_ch__";

  for (const arg of args) _storageKey += `__${arg}`;

  // console.log(_storageKey)

  let _initValue = localStorage.getItem(_storageKey);

  if (_initValue) {
    return eval(_initValue);
  } else {
    let value = Math.ceil(quota * 100 + randInRange(-6, 6));
    localStorage.setItem(_storageKey, value);
    return value;
  }
}

function generateTransactionStat(filter) {
  // let period = filter?.days_filter ?? 30; // determines which map

  // let data = {
  //   past: {
  //     chart: {},
  //   },
  //   current: {
  //     chart: {},
  //   },
  //   unit: 'Avg/day',
  //   period: period,
  //   is_category: false,
  // }

  // let profile = profiles[filter.profile ?? 'all_users']; // modify value using .metric.quota

  // let buffer;
  // let unit;

  // switch (period) {
  //   case 7:
  //   case 30:
  //     buffer = 1;
  //     unit = 'Avg/day';
  //     break;
  //   case 90:
  //   case 365:
  //     buffer = 30;
  //     unit = 'Avg/month';
  //     break;
  // }

  // let spendQuota = avgMonthlySpend * profile.metrics.quota;
  // let pastSpendQuota = (spendQuota * 100) / (100 + profile.metrics.delta);

  // let avgSpend = Math.ceil(spendQuota / 30 * buffer);
  // let pastAvgSpend = Math.ceil(pastSpendQuota / 30 * buffer);

  // let startDate = moment().subtract(period, 'days');
  // let today = moment();

  // let pastStartDate = moment(startDate).subtract(period, 'days');

  // while (today.diff(startDate, 'days') >= 0) {
  //   let key = startDate.format('YYYY-MM-DD');
  //   let pastKey = pastStartDate.format('YYYY-MM-DD');

  //   let _rand = getChartBuffer([profile.id, period, key]);
  //   let _pastRand = getChartBuffer([profile.id, period, pastKey]);

  //   // console.log(avgSpend);

  //   data.current.chart[key] = { "avg_val": avgSpend * _rand, "unit": "M", }
  //   data.past.chart[key] = { "avg_val": pastAvgSpend * _pastRand, "unit": "M", }

  //   startDate.add(buffer, 'days');
  //   pastStartDate.add(buffer, 'days');
  // }

  // data.past.value = pastAvgSpend;
  // data.current.value = avgSpend;
  // data.unit = unit;

  return {
    "2023-03-13": {
      tx_date: "2023-03-13",
      rel_date: "2022-09-12",
      total_weekly_spend: 0,
      avg_val: 0,
      no_users: 0,
      rel_total_weekly_spend: -708810381.1600015,
      rel_avg_val: -14999.37322583379,
      rel_no_users: 47256,
    },
    "2023-03-06": {
      tx_date: "2023-03-06",
      rel_date: "2022-09-05",
      total_weekly_spend: 0,
      avg_val: 0,
      no_users: 0,
      rel_total_weekly_spend: -364197985.9100002,
      rel_avg_val: -8748.660450887608,
      rel_no_users: 41629,
    },
    "2023-02-27": {
      tx_date: "2023-02-27",
      rel_date: "2022-08-29",
      total_weekly_spend: 0,
      avg_val: 0,
      no_users: 0,
      rel_total_weekly_spend: -341840327.6300008,
      rel_avg_val: -7724.332338266881,
      rel_no_users: 44255,
    },
    "2023-02-20": {
      tx_date: "2023-02-20",
      rel_date: "2022-08-22",
      total_weekly_spend: 0,
      avg_val: 0,
      no_users: 0,
      rel_total_weekly_spend: -262755817.99000078,
      rel_avg_val: -7370.222938767518,
      rel_no_users: 35651,
    },
    "2023-02-13": {
      tx_date: "2023-02-13",
      rel_date: "2022-08-15",
      total_weekly_spend: 0,
      avg_val: 0,
      no_users: 0,
      rel_total_weekly_spend: -152742272.78000012,
      rel_avg_val: -4322.56828107313,
      rel_no_users: 35336,
    },
    "2023-02-06": {
      tx_date: "2023-02-06",
      rel_date: "2022-08-08",
      total_weekly_spend: 0,
      avg_val: 0,
      no_users: 0,
      rel_total_weekly_spend: -729423582.4300011,
      rel_avg_val: -13376.801012855565,
      rel_no_users: 54529,
    },
    "2023-01-30": {
      tx_date: "2023-01-30",
      rel_date: "2022-08-01",
      total_weekly_spend: 0,
      avg_val: 0,
      no_users: 0,
      rel_total_weekly_spend: -743883764.7500019,
      rel_avg_val: -13121.957395484245,
      rel_no_users: 56690,
    },
    "2023-01-23": {
      tx_date: "2023-01-23",
      rel_date: "2022-07-25",
      total_weekly_spend: 0,
      avg_val: 0,
      no_users: 0,
      rel_total_weekly_spend: -724905455.660002,
      rel_avg_val: -13506.96781493976,
      rel_no_users: 53669,
    },
    "2023-01-16": {
      tx_date: "2023-01-16",
      rel_date: "2022-07-18",
      total_weekly_spend: 0,
      avg_val: 0,
      no_users: 0,
      rel_total_weekly_spend: -746177349.2700002,
      rel_avg_val: -13822.194525600182,
      rel_no_users: 53984,
    },
    "2023-01-09": {
      tx_date: "2023-01-09",
      rel_date: "2022-07-11",
      total_weekly_spend: 0,
      avg_val: 0,
      no_users: 0,
      rel_total_weekly_spend: -574473443.6199999,
      rel_avg_val: -10762.363588369739,
      rel_no_users: 53378,
    },
    "2023-01-02": {
      tx_date: "2023-01-02",
      rel_date: "2022-07-04",
      total_weekly_spend: 0,
      avg_val: 0,
      no_users: 0,
      rel_total_weekly_spend: -60908825.47999994,
      rel_avg_val: -7111.363161704605,
      rel_no_users: 8565,
    },
    "2022-12-26": {
      tx_date: "2022-12-26",
      rel_date: "2022-06-27",
      total_weekly_spend: 0,
      avg_val: 0,
      no_users: 0,
      rel_total_weekly_spend: -70719793.16999999,
      rel_avg_val: -10655.385440711163,
      rel_no_users: 6637,
    },
    "2022-12-19": {
      tx_date: "2022-12-19",
      rel_date: "2022-06-20",
      total_weekly_spend: -321005982.6800002,
      avg_val: -12653.972827183861,
      no_users: 25368,
      rel_total_weekly_spend: -10743189.019999998,
      rel_avg_val: -20699.78616570327,
      rel_no_users: 519,
    },
    "2022-12-12": {
      tx_date: "2022-12-12",
      rel_date: "2022-06-13",
      total_weekly_spend: -816778317.8299999,
      avg_val: -17163.17463762634,
      no_users: 47589,
      rel_total_weekly_spend: -195824934.98999977,
      rel_avg_val: -12188.77972052781,
      rel_no_users: 16066,
    },
    "2022-12-05": {
      tx_date: "2022-12-05",
      rel_date: "2022-06-06",
      total_weekly_spend: -853224813.3299997,
      avg_val: -14858.848757096577,
      no_users: 57422,
      rel_total_weekly_spend: -1177411185.2599983,
      rel_avg_val: -23387.318950818335,
      rel_no_users: 50344,
    },
    "2022-11-28": {
      tx_date: "2022-11-28",
      rel_date: "2022-05-30",
      total_weekly_spend: -962192115.1200011,
      avg_val: -19576.645272024438,
      no_users: 49150,
      rel_total_weekly_spend: -20759496.070000008,
      rel_avg_val: -4125.496039348173,
      rel_no_users: 5032,
    },
    "2022-11-21": {
      tx_date: "2022-11-21",
      rel_date: "2022-05-23",
      total_weekly_spend: -874357684.0699983,
      avg_val: -15389.557054827039,
      no_users: 56815,
      rel_total_weekly_spend: -15593419.949999994,
      rel_avg_val: -29477.164366729667,
      rel_no_users: 529,
    },
    "2022-11-14": {
      tx_date: "2022-11-14",
      rel_date: "2022-05-16",
      total_weekly_spend: -1002055785.2899951,
      avg_val: -17568.21391510914,
      no_users: 57038,
      rel_total_weekly_spend: -7328938.030000001,
      rel_avg_val: -15829.239805615553,
      rel_no_users: 463,
    },
    "2022-11-07": {
      tx_date: "2022-11-07",
      rel_date: "2022-05-09",
      total_weekly_spend: -611202440.6400001,
      avg_val: -10237.896828140705,
      no_users: 59700,
      rel_total_weekly_spend: -470769097.43999934,
      rel_avg_val: -14303.00472261042,
      rel_no_users: 32914,
    },
    "2022-10-31": {
      tx_date: "2022-10-31",
      rel_date: "2022-05-02",
      total_weekly_spend: -499852793.65999913,
      avg_val: -8808.910081418284,
      no_users: 56744,
      rel_total_weekly_spend: -6093713.849999999,
      rel_avg_val: -10940.24030520646,
      rel_no_users: 557,
    },
    "2022-10-24": {
      tx_date: "2022-10-24",
      rel_date: "2022-04-25",
      total_weekly_spend: -1333830615.8099966,
      avg_val: -21643.256568605124,
      no_users: 61628,
      rel_total_weekly_spend: -381379203.6700002,
      rel_avg_val: -13234.062171906455,
      rel_no_users: 28818,
    },
    "2022-10-17": {
      tx_date: "2022-10-17",
      rel_date: "2022-04-18",
      total_weekly_spend: -817459496.9800007,
      avg_val: -14861.278714685684,
      no_users: 55006,
      rel_total_weekly_spend: -535595862.55,
      rel_avg_val: -11026.616897247442,
      rel_no_users: 48573,
    },
    "2022-10-10": {
      tx_date: "2022-10-10",
      rel_date: "2022-04-11",
      total_weekly_spend: -629197652.9900005,
      avg_val: -13691.603807855521,
      no_users: 45955,
      rel_total_weekly_spend: -92295253.5499999,
      rel_avg_val: -6979.902711185048,
      rel_no_users: 13223,
    },
    "2022-10-03": {
      tx_date: "2022-10-03",
      rel_date: "2022-04-04",
      total_weekly_spend: -606700057.0999995,
      avg_val: -12852.453280372833,
      no_users: 47205,
      rel_total_weekly_spend: -636973443.0000012,
      rel_avg_val: -21643.677981651417,
      rel_no_users: 29430,
    },
    "2022-09-26": {
      tx_date: "2022-09-26",
      rel_date: "2022-03-28",
      total_weekly_spend: -959997995.8199992,
      avg_val: -17224.63838626331,
      no_users: 55734,
      rel_total_weekly_spend: -937189269.0699974,
      rel_avg_val: -19213.7537993316,
      rel_no_users: 48777,
    },
    "2022-09-19": {
      tx_date: "2022-09-19",
      rel_date: "2022-03-21",
      total_weekly_spend: -415335683.40000045,
      avg_val: -13007.28706899253,
      no_users: 31931,
      rel_total_weekly_spend: -802315912.1599989,
      rel_avg_val: -15950.297452535713,
      rel_no_users: 50301,
    },
    "2022-09-12": {
      tx_date: "2022-09-12",
      rel_date: "2022-03-14",
      total_weekly_spend: -708810381.1600015,
      avg_val: -14999.37322583379,
      no_users: 47256,
      rel_total_weekly_spend: -690482500.1100005,
      rel_avg_val: -13695.973422790847,
      rel_no_users: 50415,
    },
  };
}

function generateCategoryTransactionStat(categoryId, filter) {
  // let period = filter?.days_filter ?? 30;

  // let data = {
  //   past: {
  //     chart: {},
  //   },
  //   current: {
  //     chart: {},
  //   },
  //   unit: 'Avg/day',
  //   period: period,
  //   is_category: false,
  // }

  // let profile = profiles[filter.profile ?? 'all_users'];

  // let buffer;
  // let unit;

  // switch (period) {
  //   case 7:
  //   case 30:
  //     buffer = 1;
  //     unit = 'Avg/day';
  //     break;
  //   case 90:
  //   case 365:
  //     buffer = 30;
  //     unit = 'Avg/month';
  //     break;
  // }

  // let pastAvgMonthlySpend = (avgMonthlySpend * 100) / (100 + profile.metrics.delta);

  // let _q = categories[categoryId].quota;
  // let spendQuota = (avgMonthlySpend * profile.metrics.quota * _q);

  // let _buffer = getSpendingCardBuffer(_q, [profile.id, period, 'cat', categoryId, 'avg_spend']);

  // let pastSpendQuota = (spendQuota * 100) / (100 + _buffer);

  // // let pastSpendQuota = (pastAvgMonthlySpend * profile.metrics.quota * categories[categoryId].quota);

  // let avgSpend = Math.ceil(spendQuota / 30 * buffer);
  // // let pastAvgSpend = Math.ceil(pastSpendQuota / 30 * buffer);
  // let pastAvgSpend = avgSpend * (100 - _buffer) / 100;

  // let startDate = moment().subtract(period, 'days');
  // let today = moment();

  // let pastStartDate = moment(startDate).subtract(period, 'days');

  // while (today.diff(startDate, 'days') >= 0) {
  //   let key = startDate.format('YYYY-MM-DD');
  //   let pastKey = pastStartDate.format('YYYY-MM-DD');

  //   let _rand = getChartBuffer([profile.id, period, categoryId, key]);
  //   let _pastRand = getChartBuffer([profile.id, period, categoryId, pastKey]);

  //   // console.log(_rand);

  //   data.current.chart[key] = { "avg_val": avgSpend * _rand, "unit": "M", }
  //   data.past.chart[key] = { "avg_val": pastAvgSpend * _pastRand, "unit": "M", }

  //   startDate.add(buffer, 'days');
  //   pastStartDate.add(buffer, 'days');
  // }

  // data.past.value = pastAvgSpend;
  // data.current.value = avgSpend;
  // data.unit = unit;

  // return data;
  return {
    "2023-03-13": {
      tx_date: "2023-03-13",
      rel_date: "2022-12-12",
      category_id: "18",
      total_spend: 0,
      avg_val: 0,
      rel_total_spend: -622293785.9399998,
      rel_avg_val: -42163.68222372788,
    },
    "2023-03-06": {
      tx_date: "2023-03-06",
      rel_date: "2022-12-05",
      category_id: "18",
      total_spend: 0,
      avg_val: 0,
      rel_total_spend: -601170656.5400014,
      rel_avg_val: -30357.5547412009,
    },
    "2023-02-27": {
      tx_date: "2023-02-27",
      rel_date: "2022-11-28",
      category_id: "18",
      total_spend: 0,
      avg_val: 0,
      rel_total_spend: -712386351.7600017,
      rel_avg_val: -54190.35081089317,
    },
    "2023-02-20": {
      tx_date: "2023-02-20",
      rel_date: "2022-11-21",
      category_id: "18",
      total_spend: 0,
      avg_val: 0,
      rel_total_spend: -590062994.4900002,
      rel_avg_val: -38044.03575048358,
    },
    "2023-02-13": {
      tx_date: "2023-02-13",
      rel_date: "2022-11-14",
      category_id: "18",
      total_spend: 0,
      avg_val: 0,
      rel_total_spend: -774713790.1699992,
      rel_avg_val: -50175.76361204658,
    },
    "2023-02-06": {
      tx_date: "2023-02-06",
      rel_date: "2022-11-07",
      category_id: "18",
      total_spend: 0,
      avg_val: 0,
      rel_total_spend: -396639863.81000054,
      rel_avg_val: -24760.588289531217,
    },
    "2023-01-30": {
      tx_date: "2023-01-30",
      rel_date: "2022-10-31",
      category_id: "18",
      total_spend: 0,
      avg_val: 0,
      rel_total_spend: -277337389.91999996,
      rel_avg_val: -18077.00364489636,
    },
    "2023-01-23": {
      tx_date: "2023-01-23",
      rel_date: "2022-10-24",
      category_id: "18",
      total_spend: 0,
      avg_val: 0,
      rel_total_spend: -1028437868.8400006,
      rel_avg_val: -58384.210550099386,
    },
    "2023-01-16": {
      tx_date: "2023-01-16",
      rel_date: "2022-10-17",
      category_id: "18",
      total_spend: 0,
      avg_val: 0,
      rel_total_spend: -585373092.1900005,
      rel_avg_val: -39299.972621013796,
    },
    "2023-01-09": {
      tx_date: "2023-01-09",
      rel_date: "2022-10-10",
      category_id: "18",
      total_spend: 0,
      avg_val: -122334560,
      rel_total_spend: -486900323.83999956,
      rel_avg_val: -42963.05689932053,
    },
    "2023-01-02": {
      tx_date: "2023-01-02",
      rel_date: "2022-10-03",
      category_id: "18",
      total_spend: 0,
      avg_val: 0,
      rel_total_spend: -387818599.01000005,
      rel_avg_val: -27499.01432390272,
    },
    "2022-12-26": {
      tx_date: "2022-12-26",
      rel_date: "2022-09-26",
      category_id: "18",
      total_spend: 0,
      avg_val: 0,
      rel_total_spend: -650208522.3900007,
      rel_avg_val: -40383.114240730436,
    },
    "2022-12-19": {
      tx_date: "2022-12-19",
      rel_date: "2022-09-19",
      category_id: "18",
      total_spend: -221821507.98999995,
      avg_val: -28770.62360440985,
      rel_total_spend: -304773615.07000005,
      rel_avg_val: -33105.97600152075,
    },
    "2022-12-12": {
      tx_date: "2022-12-12",
      rel_date: "2022-09-12",
      category_id: "18",
      total_spend: -622293785.9399998,
      avg_val: -42163.68222372788,
      rel_total_spend: -541364154.0699999,
      rel_avg_val: -48271.435940258576,
    },
  };
}

function generateTotalUsers(filter) {
  let profile = profiles[filter.profile ?? "all_users"];

  return {
    total_users: profile.metrics.users,
    delta_total_users: profile.metrics.delta,
  };
}

function generateAvgMonthSpend(filter) {
  let profile = profiles[filter.profile ?? "all_users"];
  let period = filter?.days_filter ?? 30;

  let _f;

  switch (period) {
    case 7:
      _f = "weekly";
      break;
    case 90:
      _f = "3-month";
      break;
    case 365:
      _f = "yearly";
      break;
    case 30:
    default:
      _f = "monthly";
      break;
  }

  return {
    avg_exp: Math.ceil(
      ((avgMonthlySpend * profile.metrics.quota) / 30) * period
    ),
    delta_avg_exp: profile.metrics.avg_spend_delta,
    frequency: _f,
  };
}

function generateConversionStat() {
  let _applied = (Math.random() * 10000).toFixed(0);

  return {
    applied: _applied,
    accepted: (Math.random() * _applied).toFixed(2),
    target: (Math.random() * 100).toFixed(2),
  };
}

function respond(value) {
  return Promise.resolve({
    data: value,
  });
}

//Requests
export default {
  apiClient,
  getWithParams,
  //OnBoarding Flow
  // async googleLogin(payload) {
  //   let tokenRes, res, user, verified;

  //   if (payload) {
  //     tokenRes = payload.credential;
  //   } else {

  //     await googleTokenLogin().then((tokenResponse) => {
  //       console.log("Handle the response", tokenResponse)
  //       tokenRes = tokenResponse;
  //     })
  //   }

  //   try {

  //     if (tokenRes) {
  //       const _res = await apiClient.post("/verify-google-token", {
  //         token: tokenRes,
  //       });

  //       res = _res.data;
  //       user = this.saveUser(res);
  //       verified = this.checkVerificationStatus(res);

  //       return { success: true, user: user, verified: verified };
  //     } else {
  //       throw new Error('Could not get Access Token');
  //     }

  //   } catch (err) {
  //     console.log(err);
  //     return ({ success: false, err: err });
  //   }
  // },
  async azureSignIn(azureUser) {
    let res;

    try {
      if (Object.keys(azureUser).length !== 0) {
        const _res = await apiClient.post("/azure/sign_in", azureUser);
        res = {
          data: { ..._res.data },
          headers: _res.headers,
        };

        return this.completeSignIn(res);

        // return { success: true, user: user, verified: verified };
      } else {
        throw new Error("Could not get Access Token");
      }
    } catch (error) {
      return { success: false, err: "Failed to login with Azure AD" };
    }
  },
  async login(credentials) {
    let res;

    try {
      const _res = await apiClient.post("/auth/sign_in", credentials);
      res = {
        ..._res.data,
        headers: _res.headers,
      };
      return this.completeSignIn(res);
    } catch (err) {
      // if (err.is_verified == false) {
      //   console.log("Caught 406!!");
      //   return this.completeSignIn(err);
      // } else {
      //   return { success: false, err: err };
      // }
      return { success: false, err: err };
    }
  },
  completeSignIn(res) {
    let user; //, verified;

    //[1] save user data + token in localstorage
    user = this.saveUser(res);
    // verified = this.checkVerificationStatus(res);

    return { success: true, user: user }; //verified: verified
  },
  saveUser(res) {
    let _user;

    const token = res.headers?.authorization;
    if (token) {
      //[1] store the token in localstorage
      localStorage.setItem("token", token);

      //[2] store user object in localstorage
      const userData = res?.data;
      if (userData) {
        _user = userData;
        localStorage.setItem("user", JSON.stringify(_user));
        store.commit("updateUser", _user);
      }
    }

    return _user;
  },
  checkVerificationStatus(res) {
    let _verified;

    if (res["key"] != null) {
      _verified = res["is_verified"] ?? true;
      localStorage.setItem("verified", _verified);
    }

    return _verified;
  },
  async signUp(credentials) {
    let res, user;

    try {
      const _res = await apiClient.post("/sign-up", credentials);

      res = _res.data;
      user = this.saveUser(res);

      return { success: true, user: user, verified: false };
    } catch (err) {
      console.log(err);
      return { success: false, err: err };
    }
  },
  async updateCompanyDetails(payload) {
    let success;

    try {
      const _res = await apiClient.post("/update-user-info", {
        ...payload,
        is_info_form_seen: true,
      });

      success = _res.status < 300;

      return { success: success, err: _res.statusText };
    } catch (err) {
      console.log(err);
      return { success: false, err: err };
    }
  },
  async resendVerificationEmail() {
    let success;

    try {
      const _res = await apiClient.post("/resend-verif-email");

      // res = _res.data;
      success = _res.status < 300;

      return { success: success, err: _res.statusText };
    } catch (err) {
      console.log(err);
      return { success: false, err: err };
    }
  },
  async verifyEmail(payload) {
    let success;

    try {
      const _res = await apiClient.post("/verif-email", payload);

      // res = _res.data;
      success = _res.status < 300;

      localStorage.setItem("verified", success);

      return { success: success, err: _res.statusText };
    } catch (err) {
      console.log(err);
      return { success: false, err: err };
    }
  },
  async requestPasswordReset(payload) {
    try {
      const _res = await apiClient.post("/forget_password", payload);

      return { success: true, err: _res.statusText };
    } catch (error) {
      console.error("Failed to request reset pass: ", error);
      return { success: false, err: error };
    }
  },
  async logout() {
    try {
      const res = await apiClient.post("/sign-out");
      //const res = Promise.resolve("Logged user out")

      token = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("verified");

      return res;
    } catch (err) {
      return Promise.reject("Error on logout", err);
    }
  },
  inviteUser(user) {
    return apiClient.post("/invite_team_member", user);
  },
  sendPasswordResetEmail(email) {
    return apiClient.post("/forget_password", {
      email,
    });
  },
  async setPassword(payload, isNewUser) {
    let res, user;

    try {
      const _res = await apiClient.put(
        isNewUser ? "/set_password_with_invite" : "/update_password",
        payload
      );

      res = _res.data;
      user = this.saveUser(res);

      return { success: true, user: user };
    } catch (err) {
      console.log(err);
      return { success: false, err: err };
    }
  },
  async changePassword(payload) {
    return apiClient.put("/auth/password", payload);
  },

  //Account & Settings
  async updateAccount(user) {
    let res;
    let payload = {
      name: user.name,
      team: user.team,
    };

    const _res = await apiClient.put("/auth", payload);

    if (_res.status < 300) {
      // let _user = (_res.data['members_list'] ?? []).find(member => member.id == user.id);

      // if (![null, undefined, ''].includes(_user)) {
      //   localStorage.setItem('user', JSON.stringify(_user));
      //   res = _user;
      // }
      res = _res?.data?.data;
      //console.log("from rest: ", res)
      localStorage.setItem("user", JSON.stringify(res));
      store.commit("updateUser", res);
    }
    return res;
  },
  updateMembers(memberId, payload) {
    return apiClient.patch(`/update_team_member/${memberId}`, payload);
  },
  getMembers() {
    return apiClient.get("/team_members");
    // return useMockFetch({
    //   data: [
    //     {
    //       id: 2,
    //       name: null,
    //       email: "demo@test.com",
    //       role: null,
    //       team: null,
    //       date_added: null,
    //       date_joined: null,
    //     },
    //     {
    //       id: 3,
    //       name: "test",
    //       email: "team1@yopmail.com",
    //       role: "owner",
    //       team: "product",
    //     },
    //     {
    //       id: 5,
    //       name: "test",
    //       email: "team11@yopmail.com",
    //       role: "owner",
    //       team: "product",
    //     },
    //     {
    //       id: 6,
    //       name: "test",
    //       email: "team111@yopmail.com",
    //       role: "owner",
    //       team: "product",
    //     },
    //     {
    //       id: 7,
    //       name: "test",
    //       email: "team2@yopmail.com",
    //       role: "owner",
    //       team: "product",
    //     },
    //     {
    //       id: 9,
    //       name: "test",
    //       email: "team01@yopmail.com",
    //       role: "owner",
    //       team: "product",
    //     },
    //     {
    //       id: 10,
    //       name: "test",
    //       email: "team02@yopmail.com",
    //       role: "owner",
    //       team: "product",
    //     },
    //     {
    //       id: 11,
    //       name: "test",
    //       email: "team03@yopmail.com",
    //       role: "owner",
    //       team: "product",
    //     },
    //     {
    //       id: 13,
    //       name: "test",
    //       email: "team05@yopmail.com",
    //       role: "owner",
    //       team: "product",
    //     },
    //     {
    //       id: 1,
    //       name: "abc",
    //       email: "abc12@yopmail.com",
    //       role: null,
    //       team: "sales",
    //     },
    //   ],
    // });
  },
  getCurrency() {
    //return 'SAR'
    return apiClient.patch("/update-user", {});
  },
  deleteUser(id) {
    return apiClient.delete(`delete_team_member/${id}`);
  },

  //Filters Flow
  getFilterSegements() {
    if (isServerEnabled)
      return apiClient.get("/tenant_customer_accounts/segments");
    else
      return respond({
        message: "",
        data: [
          "Retail - Ajyal",
          "Retail - Basic",
          "Retail - Personal Banking",
          "Retail - Preferred",
          "Retail - Priority",
          "Retail - Small Business",
          "Retail - SME Lite",
        ],
      });
  },
  getFilterCategories() {
    if (isServerEnabled)
      return apiClient.get("/tenant_customer_accounts/categories");
    else
      return respond({
        message: "",
        data: ["Conventional", "Islamic", "Others"],
      });
  },

  getFilterTransactionTypes() {
    if (isServerEnabled) return apiClient.get("/tenant_transactions/types");
    else
      return useMockFetch({
        data: [
          [1, "Accnt Transfer"],
          [2, "Acc Trf Sal"],
          [3, "Acct Trnsfr HR"],
          [4, "ATM Cash Dep"],
        ],
      });
  },

  //Dashbaord Overview Flow
  // getSummary(filter) {
  //   if (isServerEnabled) return getWithParams("/users-summary", filter);
  //   else
  //     return respond({
  //       total_users_count: 0,
  //       total_users_spend: 0,
  //       rel_total_users_spend: 0,
  //       total_users_spend_daily_avg: 0,
  //       rel_total_users_spend_daily_avg: 0,
  //       total_users_spend_monthly_avg: 0,
  //       rel_total_users_spend_monthly_avg: 0,
  //       rel_precent_total_users_spend: 0,
  //     });
  // },
  getCashFlowData(filter) {
    if (isServerEnabled) return getWithParams("/cashflow", filter);
    else return respond(generateCashFlowStat(filter));
  },
  getSpendPerCurrency(filter) {
    if (isServerEnabled) return getWithParams("/v1/spend-per-currency", filter);
    else
      return respond([
        {
          label: "AED",
          total_amount: -14506479.589999985,
          avg_amount: -777777,
          rel_perc: 463.992261819325,
        },
        {
          label: "USD",
          total_amount: -14506479.589999985,
          avg_amount: -77782423.17948863628,
          rel_perc: 463.992261819325,
        },
        {
          label: "SAR",
          total_amount: -14506479.589999985,
          avg_amount: -7782423.17948863628,
          rel_perc: 463.992261819325,
        },
        {
          label: "OMN",
          total_amount: -14506479.589999985,
          avg_amount: -777423.17948863628,
          rel_perc: 463.992261819325,
        },
        {
          label: "EUR",
          total_amount: -14506479.589999985,
          avg_amount: -82423.17948863628,
          rel_perc: 463.992261819325,
        },
        {
          label: "DSL",
          total_amount: -14506479.589999985,
          avg_amount: -82423.17948863628,
          rel_perc: 463.992261819325,
        },
        {
          label: "KWD",
          total_amount: -14506479.589999985,
          avg_amount: -82423.17948863628,
          rel_perc: 463.992261819325,
        },
      ]);
  },
  getSpendPerTxType(filter) {
    if (isServerEnabled)
      return getWithParams("/v1/spend-per-trans-type", filter);
    else
      return respond([
        {
          label: "Online",
          total_amount: -14506479.589999985,
          avg_amount: -777777,
          rel_perc: -463.992261819325,
        },
        {
          label: "Transfers",
          total_amount: -14506479.589999985,
          avg_amount: -77782423.17948863628,
          rel_perc: 0,
        },
        {
          label: "Cash",
          total_amount: -14506479.589999985,
          avg_amount: -7782423.17948863628,
          rel_perc: 463.992261819325,
        },
        {
          label: "Debit Card",
          total_amount: -14506479.589999985,
          avg_amount: -777423.17948863628,
          rel_perc: 463.992261819325,
        },
        {
          label: "Wire Transfers",
          total_amount: -14506479.589999985,
          avg_amount: -82423.17948863628,
          rel_perc: 463.992261819325,
        },
        {
          label: "DSL",
          total_amount: -14506479.589999985,
          avg_amount: -82423.17948863628,
          rel_perc: 463.992261819325,
        },
        {
          label: "KWD",
          total_amount: -14506479.589999985,
          avg_amount: -82423.17948863628,
          rel_perc: 463.992261819325,
        },
      ]);
  },
  // getTransactionValue(filter, daysPeriod) {
  //   let endpoint = "";

  //   switch (true) {
  //     case daysPeriod <= 31: //|| filter.periodLabel == 'Current Month'):
  //       endpoint = "/daily-spend-per-user";
  //       break;

  //     case daysPeriod > 31 && daysPeriod < 365:
  //       endpoint = "/weekly-spend-per-user";
  //       break;

  //     case daysPeriod >= 365:
  //       endpoint = "/monthly-spend-per-user";
  //       break;
  //   }

  //   if (isServerEnabled) return getWithParams(endpoint, filter);
  //   else return respond(generateTransactionStat(filter));
  // },

  //Details View All
  //Categories ViewAll
  async getTopBrandsPerCat(filter, catId) {
    let topBrandsList = [];
    let _res = await getWithParams(
      `/tenant_transactions/top_10_data/${catId}/brands_per_cat`,
      // `/top-10-brands-per-cat?categ_id=${catId}`,
      filter
    );

    if (_res.status < 300) {
      topBrandsList = [..._res.data.top_10_brands];
    } else {
      console.log("Faild to get Top Brands list");
    }

    //console.log("Top Brands: ", topBrandsList)
    return topBrandsList;
  },
  getTopTxPerCat(filter, id, page) {
    const payload = { id, ...filter };
    return getWithParams("/top-10-trans-per-cat", payload, page);
  },
  getCategoryTransactionValue(categoryId, filter, periodDays) {
    let endpoint;
    //console.log("periodDays: ", periodDays)
    //filter['days_filter'] == 365 ? '/monthly-spend-per-cat2' : '/daily-spend-per-cat2';
    switch (true) {
      case periodDays <= 31:
        endpoint = "/daily-spend-per-cat2";
        break;

      case periodDays > 31 && periodDays < 365:
        endpoint = "/v1/category/weekly-spend"; //needs to change
        break;

      case periodDays >= 365:
        endpoint = "/monthly-spend-per-cat2";
        break;
    }

    //console.log('Time Period: ', filter['days_filter'], ' | Endpoint :',endpoint, ' Id: ', categoryId)
    let payload = { category_id: categoryId, ...filter };
    if (isServerEnabled) return getWithParams(endpoint, payload, null);
    //apiClient.post(endpoint, payload);
    else return respond(generateCategoryTransactionStat(categoryId, filter));
  },
  //Brands View All
  getTopTxPerBrand(filter, id, page) {
    const payload = { id, ...filter };
    return getWithParams("/top-10-trans-per-brand", payload, page);
  },
  getBrandTransactionValue(brandId, filter, periodDays) {
    let endpoint;

    switch (true) {
      case periodDays <= 31:
        endpoint = "/v1/brand/daily-spend";
        break;

      case periodDays > 31 && periodDays < 365:
        endpoint = "/v1/brand/weekly-spend";
        break;

      case periodDays >= 365:
        endpoint = "/v1/brand/monthly-spend";
        break;
    }

    let payload = { brand_id: brandId, ...filter };

    if (isServerEnabled) return getWithParams(endpoint, payload, null);
    else return respond({});
  },

  //Demograhics
  getAgeDistribution(filter) {
    if (isServerEnabled)
      return getWithParams("v1/demograhic/users-by-age", filter);
    //apiClient.post("v1/demograhic/users-by-age", filter);
    else
      return respond([
        {
          label: "below 21",
          value: 2232,
        },
        {
          label: "21-25",
          value: 22443,
        },
        {
          label: "26-30",
          value: 233543,
        },
        {
          label: "31-35",
          value: 54333,
        },
        {
          label: "36-40",
          value: 34553,
        },
        {
          label: "41-45",
          value: 11234,
        },
        {
          label: "46-50",
          value: 24532,
        },
        {
          label: "51-55",
          value: 43453,
        },
        {
          label: "56-60",
          value: 32345,
        },
        {
          label: "over 60",
          value: 112345,
        },
      ]);
  },
  // getCountryDistribution(filter) {
  //   if (isServerEnabled)
  //     return getWithParams("v1/demograhic/users-by-country", filter);
  //   //apiClient.post("v1/demograhic/users-by-country", filter);
  //   else
  //     return respond([
  //       {
  //         label: "IN",
  //         value: 1621232,
  //       },
  //       {
  //         label: "AE",
  //         value: 1023434,
  //       },
  //       {
  //         label: "BE",
  //         value: 4455,
  //       },
  //       {
  //         label: "LB",
  //         value: 3543,
  //       },
  //       {
  //         label: "PK",
  //         value: 2324,
  //       },
  //       {
  //         label: "SY",
  //         value: 2553,
  //       },
  //       {
  //         label: "DM",
  //         value: 1322,
  //       },
  //       {
  //         label: "EG",
  //         value: 1234,
  //       },
  //       {
  //         label: "GB",
  //         value: 5523,
  //       },
  //     ]);
  // },
  // getUsersByGenders(filter) {
  //   return getWithParams("/v1/demograhic/users-by-gender", filter);
  //   //apiClient.post("/v1/demograhic/users-by-gender", filter);
  // },

  //Trends Flow
  getExpensePattern(period, filter) {
    if (isServerEnabled)
      return getWithParams(`v1/user-pattern/brand/${period}`, filter);
    //apiClient.post(`v1/user-pattern/brand/${period}`, filter);
    else return respond([]);
  },

  //Transfers Flow
  getInBoundCountries(filter, metric) {
    if (metric === "average")
      return getWithParams("/v1/transfer/avg-inbound-per-country", filter);
    //apiClient.post("/v1/transfer/avg-inbound-per-country", filter);
    else return getWithParams("/v1/transfer/total-inbound-per-country", filter);
    //apiClient.post("/v1/transfer/total-inbound-per-country", filter);
  },
  getOutBoundCountries(filter, metric) {
    if (metric === "average")
      return getWithParams("/v1/transfer/avg-outbound-per-country", filter);
    //apiClient.post("/v1/transfer/avg-outbound-per-country", filter);
    else
      return getWithParams("/v1/transfer/total-outbound-per-country", filter);
    //apiClient.post("/v1/transfer/total-outbound-per-country", filter);
  },
  getInBoundBanks(filter, metric) {
    if (metric === "average")
      return getWithParams("/v1/transfer/avg-inbound-per-bank", filter);
    //apiClient.post("/v1/transfer/avg-inbound-per-bank", filter);
    else return getWithParams("/v1/transfer/total-inbound-per-bank", filter);
    //apiClient.post("/v1/transfer/total-inbound-per-bank", filter);
  },
  getOutBoundBanks(filter, metric) {
    if (isServerEnabled) {
      if (metric === "average")
        return getWithParams("/v1/transfer/avg-outbound-per-bank", filter);
      //apiClient.post("/v1/transfer/avg-outbound-per-bank", filter);
      else return getWithParams("/v1/transfer/total-outbound-per-bank", filter);
      //apiClient.post("/v1/transfer/total-outbound-per-bank", filter);
    } else {
      if (metric === "average")
        return respond([
          {
            value: 1111,
            label: "US Bank",
          },
          {
            value: 1111,
            label: "NUJ Bank",
          },
          {
            value: 1111,
            label: "AD Bank",
          },
          {
            value: 1111,
            label: "OMN Bank",
          },
          {
            value: 1111,
            label: "UK Bank",
          },
          {
            value: 1111,
            label: "UK Bank",
          },
          {
            value: 1111,
            label: "UK Bank",
          },
        ]);
      else
        return respond([
          {
            value: 2222,
            label: "US Bank",
          },
          {
            value: 2222,
            label: "NUJ Bank",
          },
          {
            value: 2222,
            label: "AD Bank",
          },
          {
            value: 2222,
            label: "OMN Bank",
          },
          {
            value: 2222,
            label: "UK Bank",
          },
          {
            value: 2222,
            label: "UK Bank",
          },
          {
            value: 2222,
            label: "UK Bank",
          },
        ]);
    }
  },
  getInBoundCurrencies(filter) {
    return getWithParams("/v1/transfer/inbound-per-currency", filter);
    //apiClient.post("/v1/transfer/inbound-per-currency", filter);
  },
  getOutBoundCurrencies(filter) {
    return getWithParams("/v1/transfer/outbound-per-currency", filter);
    //apiClient.post("/v1/transfer/outbound-per-currency", filter);
  },

  //Transactions Flow
  async getTransactions(page) {
    let response;

    try {
      let res;

      if (page) {
        res = await apiClient.get(`/transaction?page=${page}`);
        //console.log('Fetched Page: ', page)
      } else {
        res = await apiClient.get("/transaction");
        //console.log('Fetched Page: 1')
      }

      if (res.status < 300) {
        response = res;
      }
    } catch (error) {
      console.log(error);
    }

    return response;
  },
  updateTransactions(payload) {
    return apiClient.post("/v1/transaction/bulk-update/", payload);
  },
  markTransactions(payload) {
    console.log("updated market tx", payload);
    return "updated";
    //return apiClient.post("/v1/transaction/bulk-update/", payload);
  },
  async getCategories() {
    let res = {};
    let _res = await apiClient.get("/trans-categ");

    if (_res.status < 300) {
      if (_res.data) {
        _res.data.forEach((cat) => {
          //Add Id property
          let _id = cat.id;
          res[_id] = { ...cat, id: _id, label: cat.name };
        });
      }
    }
    //console.log(res);
    return res;
  },
  async getBrands(query) {
    let res = {};
    let _res = await apiClient.get("/brand");

    if (_res.status < 300) {
      if (_res.data.result) {
        _res.data.result.forEach((brand) => {
          //Add Id property
          let _id = brand.id;
          res[_id] = { ...brand, id: _id, label: brand.name };
        });
      }
    }
    return res;
  },
  async getSpecificBrands(query) {
    let res = {};

    if (query) {
      let _res = await apiClient.get(`/brand?key_word=${query}`);

      if (_res.status < 300) {
        if (_res.data.result) {
          _res.data.result.forEach((brand) => {
            //Add Id property
            let _id = brand.id;
            res[_id] = { ...brand, id: _id, label: brand.name };
          });
        }
      }
    }
    //console.log('Brands: ', res);
    return res;
  },
  //API Key Flow
  generateAPIKey() {
    return "1232XS8VE8VVENCE8VEVEVESCDVNICDSV9FDVR";
    //return apiClient.get("/api-key");
  },

  //Not used Currentily
  getUsersTotalNumber(filter) {
    // console.log(filter);
    if (isServerEnabled) return apiClient.post("/total-users", filter);
    else return respond(generateTotalUsers(filter));
  },
  getUsersSpend(filter) {
    if (isServerEnabled) return apiClient.post("/avg-month-exp", filter);
    else return respond(generateAvgMonthSpend(filter));
  },
  //Products Flow
  async getProductTypes() {
    let res = {};
    let _res = await apiClient.get("/product-classes");

    if (_res.status < 300) {
      //console.log("Products types res: ", _res.data)
      _res.data.forEach((productType) => {
        let children = {};

        //Add Id propertie
        res[productType.class] = { ...productType, id: productType.class };

        //Rename the Key to 'children'
        delete Object.assign(res[productType.class], {
          ["children"]: res[productType.class]["sub_classes"],
        })["sub_classes"];

        //Modify children properties
        res[productType.class].children.forEach((product) => {
          children[product.toLowerCase()] = {
            id: product.toLowerCase().trim(),
            label: product,
          };
        });

        res[productType.class].children = { ...children };
      });
    }

    return res;
  },
  async getProducts() {
    //Get products list
    let productsList = {};
    let route = "/product/";
    let _res = await apiClient.get(route);

    if (_res.status < 300) {
      productsList = { ...(_res.data ?? {}) };
      productsList.results = [...(await handlePagenation(productsList, route))];
    } else {
      console.log("Faild to get Products list");
    }

    //console.log("final products fetched", productsList)
    return productsList;
  },
  addProduct(product) {
    //console.log("added :", product);
    return apiClient.post("/product/", product);
  },
  deleteProduct(product) {
    //console.log("deleted from REST :", product.id);
    return apiClient.delete(`/product/${product.id}/`);
  },
  editProduct(productId, product) {
    console.log("edited :", productId, product);
    return apiClient.put(`/product/${productId}/`, product);
  },
  // Campaign Flow
  async getCampaignStatuses() {
    let res = {};
    let _res = await apiClient.get("/campaign-statuses");

    if (_res.status < 300) {
      _res.data.forEach((campaignType) => {
        //Add Id property
        let _id = campaignType.status ?? campaignType.doc;

        res[_id] = { ...campaignType, id: _id };
      });
    }

    return res;
  },
  async getCampaigns() {
    let route = "/campaign/";
    let _res = await apiClient.get(route);

    if (_res.status < 300) {
      if (_res.data.results) {
        _res.data.results = [...(await handlePagenation(_res.data, route))];
      }
    }
    return _res;
  },
  async createCampaign(payload) {
    let id;

    try {
      //console.log("Final Payload: ", payload)
      let res = await apiClient.post("/campaign/", payload);

      if (res.status < 300) {
        id = res.data.id;
      }
    } catch (error) {
      console.log(error);
    }

    return id;
  },
  deleteCampaign(campaign) {
    return apiClient.delete(`/campaign/${campaign.id}/`);
  },
  editCampaign(campaignId, campaign) {
    return apiClient.put(`/campaign/${campaignId}/`, campaign);
  },
  getCampaignWithId(campaignId) {
    return apiClient.get(`/campaign/${campaignId}/`);
  },
  async getCampaignFrequencies() {
    let frequency_map = {};

    let res = await apiClient.get("/campaign-notif-freqs");

    if (res.status < 300) {
      if ((res.data.length ?? 0) > 0) {
        for (const frequency of res.data) {
          frequency_map[frequency.freq] = { ...frequency, id: frequency.freq };
        }
      }
    }
    // console.log(frequency_map);
    return frequency_map;
  },
  async createNudge(payload) {
    let id;

    try {
      let res = await apiClient.post("/engagement/", payload);

      if (res.status < 300) {
        id = res.data.id;
      }
    } catch (error) {
      console.log(error);
    }

    return id;
  },

  //Profiles Flow
  async getAllProfiles() {
    let profile_map = {};

    let res = await apiClient.get("/v1/profile");

    if (res.status < 300) {
      if ((res.data.length ?? 0) > 0) {
        for (const profile of res.data) {
          profile_map[profile.id] = profile;
          profile_map[profile.id] = {
            ...profile_map[profile.id],
            label: profile.def_data.profile_name,
          };
        }
      }
    }
    return profile_map;
  },
  async createProfile(payload) {
    let id;

    let res = await apiClient.post("/v1/profile", payload);

    if (res.status < 300) {
      id = res.data.profile_id;
    }

    return id;
  },
  async evaluateProfile(filter) {
    let data;

    let res = await apiClient.post("/v1/profile/evaluate", filter);

    if (res.status < 300) {
      data = res.data;
    }

    return data;
  },
  async deleteProfile(profile) {
    //console.log("deleted from REST :", product.id);
    let res = await apiClient.delete(`/v1/profile/delete/${profile.id}`);
    return res.status < 300;
  },
  getProfiles() {
    let _cachedProfile = localStorage.getItem("__profiles");

    if (_cachedProfile) {
      // console.log("Cache Valid!!!");
      savedProfiles = JSON.parse(_cachedProfile);
    } else {
      // console.log("Cache Invalid!!!");
      savedProfiles = profiles;
    }

    // console.log(savedProfiles);
    return Promise.resolve(savedProfiles);
  },
  reportTransaction(id, payload) {
    return apiClient.put(`v1/transaction/${id}`, payload);
  },
};
