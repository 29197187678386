<template>
  <div class="d-flex flex-row flex-wrap gap-3">
    <BaseMultiSelect
      v-show="!($route.meta?.hiddenFilters ?? []).includes('nationality')"
      v-model="selectedNationalities"
      label="Nationality"
      plural="Nationalities"
      :options="countriesOptions"
      place-holder="Search by country"
      :filter="true"
    />
    <BaseMultiSelect
      v-show="!($route.meta?.hiddenFilters ?? []).includes('gender')"
      v-model="selectedGenders"
      label="Gender"
      plural="Genders"
      :options="gendersOptions"
      :filter="false"
    />
    <BaseMultiSelect
      v-show="!($route.meta?.hiddenFilters ?? []).includes('segment')"
      v-model="selectedSegments"
      label="Segment"
      plural="Segments"
      :options="segmentsOptions"
      :filter="false"
      :loading-async="loadingSegments"
    />
    <BaseMultiSelect
      v-show="!($route.meta?.hiddenFilters ?? []).includes('account_category')"
      v-model="selectedCategories"
      label="Account Category"
      plural="Account Categories"
      :options="categoryOptions"
      :filter="false"
      :loading-async="loadingCategories"
    />
    <BaseMultiSelect
      v-show="($route.meta?.shownFilters ?? []).includes('transaction_types')"
      v-model="selectedTransactionTypes"
      label="Transaction Type"
      plural="Transaction Types"
      :options="transactionTypeOptions"
      :filter="true"
      :loading-async="loadingTransactionTypes"
    />
    <BaseMultiSelect
      v-show="!($route.meta?.hiddenFilters ?? []).includes('currency')"
      v-model="selectedCurrency"
      label="Currency"
      plural="Currencies"
      :options="currencyOptions"
      :filter="false"
      :selection-limit="1"
    />

    <!--Multi currency-->
    <BaseMultiSelect
      v-show="($route.meta?.shownFilters ?? []).includes('multi_currency')"
      v-model="selectedMultiCurrencies"
      label="Currencies"
      plural="Currencies"
      :options="currencyOptions"
      :filter="true"
    />
    <!--Multi currency-->

    <BaseMultiSelect
      v-show="!($route.meta?.hiddenFilters ?? []).includes('age')"
      label="Age"
      plural="Ages"
      :options="[]"
      :range-inputs="true"
      :loading-async="false"
      :filter="false"
      :range-values="selectedAges"
      @range-change="setAgeRange"
    />
    <BaseMultiSelect
      v-show="!($route.meta?.hiddenFilters ?? []).includes('income')"
      label="Income"
      plural="Income"
      :options="[]"
      :range-inputs="true"
      :loading-async="false"
      :filter="false"
      :range-values="selectedIncome"
      @range-change="setIncomeRange"
    />

    <!-- === === User Filters === ===-->
    <BaseMultiSelect
      v-show="($route.meta?.shownFilters ?? []).includes('account_id')"
      v-model="selectedUserAccounts"
      label="Account Type"
      plural="Accounts"
      :options="userAccounts"
      :filter="false"
      :loading-async="loadingUserAccounts"
    />

    <BaseMultiSelect
      v-show="($route.meta?.shownFilters ?? []).includes('user_currency')"
      v-model="selectedUserCurrencies"
      label="Currency"
      plural="Currencies"
      :options="userCurrencies"
      :filter="false"
      :loading-async="loadingUserAccounts"
    />
    <!-- ==== ==== ==== ===-->

    <!-- ==== Brands ===-->
    <div v-show="($route.meta?.shownFilters ?? []).includes('brands')">
      <BrandsPopUpFilter />
    </div>
    <!-- ==== ==== ==== ===-->

    <!-- ==== Categories ===-->
    <div v-show="($route.meta?.shownFilters ?? []).includes('categories')">
      <CategoriesPopUpFilter />
    </div>
    <!-- ==== ==== ==== ===-->

    <ActionButton
      type="button"
      label="Apply"
      class="btn-primary filter-btn px-4"
      @click="applyFilters"
    />
    <ActionButton
      type="button"
      label="Clear"
      class="btn-transparent my-1 px-2"
      @click="clearFilters"
    />
  </div>
  <!-- {{ selectedTransactionTypes }}
  {{ selectedUserAccounts }} -->
  <!-- {{ selectedMetric }}
  {{ selectedNationalities }}
  {{ selectedGenders }}
  {{ selectedSegments }}
  {{ selectedCategories }}
  
  {{ selectedCurrency }} -->
  <!-- {{ selectedAges }}
  {{ selectedIncome }} -->
  <!-- <div>Applied: {{ appliedFilters }}</div>
  <div class="pt-4">finalFilters: {{ finalFilters }}</div> -->
  <!-- <div>Applied: {{ appliedFilters }}</div> -->
</template>

<script>
import CategoriesPopUpFilter from "./CategoriesPopUpFilter.vue";
import BrandsPopUpFilter from "./BrandsPopUpFilter.vue";
import BaseMultiSelect from "@/components/helpers/BaseMultiSelect.vue";
import ActionButton from "./helpers/ActionButton.vue";
import { countries, genders, currencies } from "@/data/options";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    BaseMultiSelect,
    ActionButton,
    BrandsPopUpFilter,
    CategoriesPopUpFilter,
  },
  emits: ["applied-filters", "cleared-filters"],
  setup(props, { emit }) {
    const store = useStore();
    const finalFilters = computed(() => {
      return store.getters.filters;
    });

    //ref states
    const appliedFilters = computed(
      () => store.state.overViewStore.appliedFilters
    );
    // const timeFilters = computed(() => store.state.overViewStore.timeFilters);
    // const metricFilters = computed(
    //   () => store.state.overViewStore.metricFilters
    // );

    // const selectedMetric = ref({ key: "average", value: "Average spend" });
    // const selectedTimePeriod = ref();
    const selectedNationalities = ref([]);
    const selectedGenders = ref([]);
    const selectedSegments = ref([]);
    const selectedCategories = ref([]);
    const selectedTransactionTypes = ref([]);
    const selectedCurrency = ref([]);
    const selectedAges = ref([]);
    const selectedIncome = ref([]);
    const selectedUserAccounts = ref([]);
    const selectedUserCurrencies = ref([]);
    const selectedMultiCurrencies = ref([]);

    //watchers
    // watch(
    //   () => selectedMetric.value,
    //   (newVal, oldVal) => {
    //     if (newVal?.key !== oldVal?.key) {
    //       store.commit("updateSelectedMetrics", { ...newVal });
    //       emit("metric-change", newVal);
    //     }
    //   }
    // );
    //===  On Time change re-fetch ====
    // watch(
    //   () => selectedTimePeriod.value,
    //   (newVal) => {
    //     store.commit("updateTimeFilters", {
    //       ...(newVal ?? timeFilters.value),
    //     });

    //     store.commit("updateAppliedFilters", {
    //       ...appliedFilters.value,
    //       from_date:
    //         newVal?.rangePeriodFilter?.from ?? appliedFilters.value?.from_date,
    //       to_date:
    //         newVal?.rangePeriodFilter?.to ?? appliedFilters.value?.to_date,
    //     });

    //     emit("time-change", newVal);
    //   }
    // );

    //functions
    function applyFilters() {
      //Set global Currency
      const originCurrency =
        JSON.parse(localStorage.getItem("user"))?.preferred_currency ?? "AED";
      store.commit(
        "updateCurrency",
        selectedCurrency.value[0]?.key || originCurrency
      );

      // store.commit("updateTimeFilters", {
      //   ...(selectedTimePeriod.value ?? timeFilters.value),
      // });

      store.commit("updateAppliedFilters", {
        from_date:
          //selectedTimePeriod.value?.rangePeriodFilter?.from ??
          appliedFilters.value?.from_date,
        to_date:
          //selectedTimePeriod.value?.rangePeriodFilter?.to ??
          appliedFilters.value?.to_date,
        nationality: [...selectedNationalities.value],
        gender: [...selectedGenders.value],
        segment: [...selectedSegments.value],
        account_category: [...selectedCategories.value],
        transaction_types: [...selectedTransactionTypes.value],
        account_id: [...selectedUserAccounts.value],
        user_currency: [...selectedUserCurrencies.value],
        multi_currency: [...selectedMultiCurrencies.value],
        currency: [...selectedCurrency.value],
        age: [...selectedAges.value],
        income: [...selectedIncome.value],
      });

      emit("applied-filters", { ...appliedFilters.value });
    }
    function clearFilters() {
      //Rest Brands/Categories Popup filters
      store.commit("updateClearSelectedBrands", true);
      store.commit("updateSelectedBrandsFilter", []);

      store.commit("updateClearSelectedCategories", true);
      store.commit("updateSelectedCategoriesFilter", []);

      //Reset currency
      const originCurrency =
        JSON.parse(localStorage.getItem("user"))?.preferred_currency ?? "AED";
      store.commit("updateCurrency", originCurrency);

      store.commit("updateAppliedFilters", {
        from_date:
          //selectedTimePeriod.value?.rangePeriodFilter?.from ??
          appliedFilters.value?.from_date,
        to_date:
          //selectedTimePeriod.value?.rangePeriodFilter?.to ??
          appliedFilters.value?.to_date,
      });
      selectedNationalities.value = [];
      selectedGenders.value = [];
      selectedSegments.value = [];
      selectedCategories.value = [];
      selectedTransactionTypes.value = [];
      selectedUserAccounts.value = [];
      selectedUserCurrencies.value = [];
      selectedMultiCurrencies.value = [];
      selectedCurrency.value = [];
      selectedAges.value = [];
      selectedIncome.value = [];
      emit("cleared-filters");
    }
    function setAgeRange(range) {
      selectedAges.value = range;
    }
    function setIncomeRange(range) {
      selectedIncome.value = range;
    }

    //Options
    // const metricsOptions = computed(() => {
    //   let metricsList = [];
    //   for (let key in metrics) {
    //     metricsList.push({
    //       key,
    //       value: metrics[key]?.label,
    //     });
    //   }
    //   return metricsList;
    // });

    const countriesOptions = computed(() => {
      let countriesList = [];
      for (let key in countries) {
        countriesList.push({
          key,
          value: countries[key],
        });
      }
      return countriesList;
    });

    const gendersOptions = computed(() => {
      let gendersList = [];
      for (let key in genders) {
        gendersList.push({
          key,
          value: genders[key],
        });
      }
      return gendersList;
    });

    const segmentsOptions = computed(() => store.state.overViewStore.segments);
    const loadingSegments = computed(
      () => store.state.overViewStore.loadingSegments
    );

    const categoryOptions = computed(
      () => store.state.overViewStore.categories
    );
    const loadingCategories = computed(
      () => store.state.overViewStore.loadingCategories
    );

    const transactionTypeOptions = computed(
      () => store.state.overViewStore.transactionTypes
    );
    const loadingTransactionTypes = computed(
      () => store.state.overViewStore.loadingTransactionTypes
    );

    const userAccounts = computed(() => store.state.overViewStore.userAccounts);
    const loadingUserAccounts = computed(
      () => store.state.overViewStore.loadingUserAccounts
    );

    const userCurrencies = computed(() => {
      //[1] if empty
      const userAccounts = store.state.overViewStore.userAccounts;
      if (userAccounts.length === 0) return [];

      //[2] return filtered currencies by user account currencies
      let data = userAccounts.map((item) => ({
        value: item?.currency,
        key: item?.currency,
      }));

      // Remove dublicate currencies
      data = data.filter(
        (value, index, self) =>
          index === self.findIndex((currency) => currency.key === value.key)
      );

      return data;
    });

    const currencyOptions = computed(() => {
      let currenciesList = [];
      for (let key in currencies) {
        currenciesList.push({
          key,
          value: currencies[key],
        });
      }
      return currenciesList;
    });

    // function handlePeriodChange(selectedTime) {
    //   selectedTimePeriod.value = { ...selectedTime };
    // }

    //hooks
    onMounted(() => {
      //[1] Reterive cached filters
      selectedNationalities.value = [
        ...(appliedFilters.value?.nationality ?? []),
      ];
      selectedGenders.value = [...(appliedFilters.value?.gender ?? [])];
      selectedSegments.value = [...(appliedFilters.value?.segment ?? [])];
      selectedCategories.value = [
        ...(appliedFilters.value?.account_category ?? []),
      ];
      selectedTransactionTypes.value = [
        ...(appliedFilters.value?.transaction_types ?? []),
      ];
      selectedUserAccounts.value = [
        ...(appliedFilters.value?.account_id ?? []),
      ];
      selectedUserCurrencies.value = [
        ...(appliedFilters.value?.user_currency ?? []),
      ];
      selectedMultiCurrencies.value = [
        ...(appliedFilters.value?.multi_currency ?? []),
      ];
      selectedCurrency.value = [...(appliedFilters.value?.currency ?? [])];
      selectedAges.value = [...(appliedFilters.value?.age ?? [])];
      selectedIncome.value = [...(appliedFilters.value?.income ?? [])];

      //[2] Reterive cahced metrics
      // selectedMetric.value = { ...metricFilters.value };

      //[3] fetch segments & entity categories
      store.dispatch("getFilterSegements");
      store.dispatch("getFilterCategories");
    });

    //watch - [ Add filter state to the components whenever the appliedFilters changes ]
    watch(appliedFilters, (newFilters) => {
      // console.log("Changed: ", newFilters);
      selectedNationalities.value = [...(newFilters?.nationality ?? [])];
      selectedGenders.value = [...(newFilters?.gender ?? [])];
      selectedSegments.value = [...(newFilters?.segment ?? [])];
      selectedCategories.value = [...(newFilters?.account_category ?? [])];
      selectedTransactionTypes.value = [
        ...(newFilters?.transaction_types ?? []),
      ];
      selectedUserAccounts.value = [...(newFilters?.account_id ?? [])];
      selectedUserCurrencies.value = [...(newFilters?.user_currency ?? [])];
      selectedMultiCurrencies.value = [...(newFilters?.multi_currency ?? [])];
      selectedCurrency.value = [...(newFilters?.currency ?? [])];
      selectedAges.value = [...(newFilters?.age ?? [])];
      selectedIncome.value = [...(newFilters?.income ?? [])];
    });

    return {
      appliedFilters,
      countriesOptions,
      gendersOptions,
      selectedNationalities,
      selectedGenders,
      selectedSegments,
      loadingSegments,
      segmentsOptions,
      selectedCategories,
      selectedTransactionTypes,
      selectedUserAccounts,
      selectedUserCurrencies,
      selectedMultiCurrencies,
      categoryOptions,
      transactionTypeOptions,
      loadingCategories,
      loadingTransactionTypes,
      currencyOptions,
      selectedCurrency,
      selectedAges,
      selectedIncome,
      finalFilters,
      userAccounts,
      userCurrencies,
      loadingUserAccounts,
      setAgeRange,
      setIncomeRange,
      applyFilters,
      clearFilters,
    };
  },
};
</script>
<style scoped>
.filter-btn {
  font-size: var(--fs-input);
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  min-height: 40px;
}
</style>
