<template>
  <div>
    <dropdown
      v-model="selectedOption"
      :options="options"
      option-label="value"
      placeholder="Select a City"
      class="h-100 filters-drop-drop"
      scroll-height="300px"
      @update:modelValue="$emit('update:modelValue', selectedOption)"
    >
      <template #value="{ value }">
        <div class="d-flex justify-content-between align-items-center">
          <span>{{
            value?.key === "custom"
              ? rangeLabel
                ? rangeLabel
                : value?.value
              : value?.value
          }}</span>
          <img
            class="ms-4"
            src="@/assets/images/svg/dp-down-arrow.svg"
            alt="dp down arrow"
            width="20"
          />
        </div>
      </template>
      <template #option="{ option }">
        <slot name="option" :value="option"></slot>
      </template>
    </dropdown>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import Dropdown from "primevue/dropdown";

export default {
  components: { Dropdown },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    rangeLabel: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  setup(props) {
    const selectedOption = ref();

    watch(
      () => props.modelValue,
      (val) => {
        selectedOption.value = { ...val };
        if (
          (selectedOption.value?.value).includes("-") ||
          selectedOption.value?.value.match(/\d\s[A-Za-z,]{4}\s\d{4}/g)
        ) {
          selectedOption.value["value"] = "Custom";
        }
      }
    );

    onMounted(() => {
      selectedOption.value = { ...props.modelValue };
    });

    return { selectedOption };
  },
};
</script>

<style>
.filters-drop-drop {
  border-radius: 10px !important;
  border: 1px solid var(--white) !important;
  transition: all 0.4s ease;
  box-shadow: 0px 4px 40px rgba(204, 213, 212, 0.3) !important;
}
/* .filters-drop-drop:hover {
  border: 1px solid var(--sea) !important;
} */
.filters-drop-drop .p-dropdown-trigger {
  display: none;
}
.p-dropdown-panel {
  background: var(--white);
  box-shadow: 0px 4px 40px rgba(155, 157, 157, 0.2) !important;
  border-radius: 10px !important;
  margin-top: 15px;
  /* min-width: 250px !important; */
  padding: 0.4em 0;
}
.p-dropdown-item {
  padding: 0.75rem 1rem !important;
  opacity: 1 !important;
  color: var(--green) !important;
  font-size: var(--fs-input) !important;
}
.p-dropdown-item:hover {
  background-color: var(--teal-10) !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--green) !important;
  background: var(--teal-10) !important;
}
.filters-drop-drop.p-dropdown .p-inputtext {
  display: flex;
  align-items: center;
  font-size: var(--fs-input);
  padding: 0.85rem 0.8rem;
  font-weight: 400;
  opacity: 1;
  color: var(--green);
  font-family: "Poppins", "Inter", Avenir, Helvetica, Arial, sans-serif !important;
  transition: all 0.4s ease;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: var(--green) !important;
}
</style>
