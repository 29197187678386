<template>
  <div class="d-flex flex-row flex-wrap gap-3">
    <ExportPDFButton
      v-if="['Overview', 'Categories', 'Brands'].includes($route.name)"
      :loading="exportLoading"
      :metric="metricFilters?.key"
      :filters="filters"
      :time-period="timeFilters?.dropDownLabel ?? 'Last 30 days'"
    />

    <BaseFilterDropDown
      v-show="
        !'hiddenFilters' in $route.meta ||
        !$route.meta?.hiddenFilters?.includes('metric')
      "
      v-model="selectedMetric"
      :options="metricsOptions"
    />
    <TimePeriodDropdownFilter
      v-show="
        !'hiddenFilters' in $route.meta ||
        !$route.meta?.hiddenFilters?.includes('from_date')
      "
      @period-change="handlePeriodChange"
    />
  </div>
</template>
<script>
import ExportPDFButton from "../ExportPDFButton.vue";
import { computed, onMounted, ref, watch } from "vue";
import BaseFilterDropDown from "../helpers/BaseFilterDropDown.vue";
import TimePeriodDropdownFilter from "../helpers/TimePeriodDropdownFilter.vue";
import { metrics } from "@/data/options";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  components: { BaseFilterDropDown, TimePeriodDropdownFilter, ExportPDFButton },
  emits: ["metric-change", "time-change"],
  setup(props, { emit }) {
    const selectedMetric = ref({ key: "average", value: "Average spend" });
    const selectedTimePeriod = ref();
    const store = useStore();
    const route = useRoute();

    //computed
    const metricFilters = computed(
      () => store.state.overViewStore.metricFilters
    );
    const appliedFilters = computed(
      () => store.state.overViewStore.appliedFilters
    );
    const filters = computed(() => store.getters.filters);
    const timeFilters = computed(() => store.state.overViewStore.timeFilters);

    const exportLoading = computed(() => {
      let currentRoute = route.name;

      switch (currentRoute) {
        case "Dashboard":
        case "Overview":
          return store.state.loadingDahboardData;

        case "Categories":
          return store.state.loadingCategoriesData;

        case "Brands":
          return store.state.loadingBrandsData;

        default:
          return true;
      }
    });

    //options
    const metricsOptions = computed(() => {
      let metricsList = [];
      for (let key in metrics) {
        metricsList.push({
          key,
          value: metrics[key]?.label,
        });
      }
      return metricsList;
    });

    function handlePeriodChange(selectedTime) {
      selectedTimePeriod.value = { ...selectedTime };
    }

    //watchers
    // === Metric ===
    watch(
      () => selectedMetric.value,
      (newVal, oldVal) => {
        if (newVal?.key !== oldVal?.key) {
          store.commit("updateSelectedMetrics", { ...newVal });
          emit("metric-change", newVal);
        }
      }
    );
    // === Time Period ===
    watch(
      () => selectedTimePeriod.value,
      (newVal) => {
        store.commit("updateTimeFilters", {
          ...(newVal ?? timeFilters.value),
        });

        store.commit("updateAppliedFilters", {
          ...appliedFilters.value,
          from_date:
            newVal?.rangePeriodFilter?.from ?? appliedFilters.value?.from_date,
          to_date:
            newVal?.rangePeriodFilter?.to ?? appliedFilters.value?.to_date,
        });

        emit("time-change", newVal);
      }
    );

    //hooks
    onMounted(() => {
      //[1] Reterive cahced metrics
      selectedMetric.value = { ...metricFilters.value };
    });

    return {
      selectedMetric,
      metricsOptions,
      handlePeriodChange,
      exportLoading,
      metricFilters,
      filters,
      selectedTimePeriod,
      timeFilters,
    };
  },
};
</script>
<style></style>
