<template>
  <div class="d-flex flex-column justify-content-start align-items-start">
    <div class="w-100 mb-2 px-4">
      <NavTopBar />
    </div>
    <div
      class="
        px-4
        w-100
        d-flex
        flex-column
        justify-content-between
        align-items-start
        mt-1
      "
    >
      <div class="d-flex flex-column justify-content-start w-100">
        <!-- BaseBreadCrumb -->
        <BaseBreadCrumb />
        <!-- ============== -->

        <div class="d-flex justify-content-between align-items-center w-100">
          <BasePageTitle
            :icon="$route.meta?.routeIcon"
            :title="$route.meta?.title"
            class="py-2"
          />
          <BasePageFilters class="py-2" />
        </div>
      </div>
      <div
        v-if="
          $route?.name !== 'UserDetails' &&
          $route?.name !== 'UserCategoryDetails' &&
          $route?.name !== 'Account' &&
          $route?.name !== 'Team' &&
          $route?.name !== 'TransactionsHistoryCategories' &&
          $route?.name !== 'TransactionsHistoryBrands' &&
          $route?.name !== 'Documentation'
        "
        class="pt-1 pb-3"
      >
        <TopBarButtons />
      </div>
    </div>

    <div v-show="showFilters" class="w-100 filters-top-bar px-4">
      <FiltersTopBar class="py-3" />
    </div>

    <div class="w-100 flex-grow-1 overflow-hidden">
      <router-view
        class="overflow-auto"
        :metric="metricFilters?.key"
        :fetch-key="fetchKey"
        :filters="filters"
        :period-filter="timeFilters?.periodFilter"
        :time-period="timeFilters?.dropDownLabel ?? 'Last 30 days'"
      ></router-view>
    </div>
  </div>
</template>

<script>
import BaseBreadCrumb from "../components/helpers/BaseBreadCrumb.vue";
import FiltersTopBar from "@/components/FiltersTopBar.vue";
import BasePageTitle from "@/components/helpers/BasePageTitle.vue";
import NavTopBar from "@/components/helpers/NavTopBar.vue";
import BasePageFilters from "@/components/helpers/BasePageFilters.vue";
import TopBarButtons from "@/components/TopBarButtons.vue";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  components: {
    FiltersTopBar,
    BasePageTitle,
    TopBarButtons,
    NavTopBar,
    BasePageFilters,
    BaseBreadCrumb,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const showFiltersBar = computed(
      () => store.state.overViewStore.showFiltersBar
    );

    const showFilters = computed(() => {
      let show = showFiltersBar.value;

      switch (route.name) {
        case "UserDetails":
        case "UserCategoryDetails":
        case "TransactionsHistoryCategories":
        case "TransactionsHistoryBrands":
        case "Account":
        case "Team":
        case "Documentation":
          show = false;
          break;
      }

      return show;
    });

    const fetchKey = ref(0);
    //Metrics & Filters
    const filters = computed(() => {
      return store.getters.filters;
    });
    const metricFilters = computed(
      () => store.state.overViewStore.metricFilters
    );
    const timeFilters = computed(() => store.state.overViewStore.timeFilters);

    watch(filters, () => {
      fetchKey.value += 1;
    });

    return {
      showFiltersBar,
      filters,
      metricFilters,
      timeFilters,
      fetchKey,
      showFilters,
    };
  },
};
</script>

<style scoped></style>
