<template>
  <div
    id="authView"
    class="
      auth-outer-container
      d-flex
      flex-column
      justify-content-center
      align-items-center
      gap-5
      py-4
    "
  >
    <div class="mt-2 cp" @click="toLoginForm">
      <img src="@/assets/images/svg/newLogo.svg" width="190" alt="lune-logo" />
    </div>

    <div
      class="form-container d-flex align-items-center justify-content-center"
    >
      <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.animation || 'fade'" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log(process.env.VUE_APP_NAME);
    this.$store.dispatch("logout");
  },
  methods: {
    toLoginForm() {
      this.$router.push({ name: "LoginForm" });
    },
  },
};
</script>

<style scoped>
.form-container {
  max-width: 500px;
  background: white;
  border-radius: 10px;
  padding: 35px 30px;
}
.form-heading {
  color: #151515;
  font-size: 1.2rem !important;
}
.auth-outer-container {
  background-color: var(--gray-bg);
  min-height: 100vh;
  overflow: auto;
}
#authView {
  color: #151515 !important;
}
</style>

<style>
.login-foot span {
  color: var(--sea);
  font-size: 0.885rem;
}
.login-foot a:hover {
  color: var(--sea) !important;
  text-decoration: underline !important;
}
</style>
