<template>
  <div class="h-100 pb-4 mt-3 px-4 hide-scrollbar">
    <div class="d-flex flex-column widget-area h-100">
      <div class="d-flex flex-column">
        <div class="d-flex align-items-center justify-content-start gap-3">
          <IconTextInput
            id="search-input-raw-description"
            class="w-30"
            placeholder="Search by Raw description"
            icon-class="pi-search"
            :value="searchQry"
            @value-change="setSearchQuery"
          />

          <ActionButton
            label="Show results"
            class="btn-primary h-100 px-4"
            :loading="loadingTransactions"
            @click="
              rendar++;
              currentPage = 1;
              fetchTransactions(itemId, currentPage);
            "
          />
        </div>

        <div class="my-3">
          <h6 v-if="!loadingTransactions" class="tx-total-label">
            Showing
            <strong>{{ numberWithCommas(totalTxCount) }}</strong> Transactions
          </h6>
        </div>
      </div>

      <div class="flex-grow-1 overflow-auto h-100">
        <TransactionsTable
          class="h-100"
          :rendar="rendar"
          :transactions="transactions"
          :total-records="totalTxCount"
          :loading="loadingTransactions"
          @pageChange="pageUpdated"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from "../components/helpers/ActionButton.vue";
import IconTextInput from "../components/helpers/IconTextInput.vue";
import TransactionsTable from "../components/TransactionsTable.vue";
import RestService from "../services/RestService";
import OverviewAPI from "@/services/api/OverviewAPI";

export default {
  components: {
    ActionButton,
    IconTextInput,
    TransactionsTable,
  },
  inject: ["numberWithCommas"],
  props: {
    timePeriod: {
      type: String,
      default: "Last 30 days",
    },
    periodFilter: {
      type: Number,
      default: 30,
    },
    filters: {
      default: {},
    },
  },
  data() {
    return {
      searchQry: "",
      selectedDates: {},
      transactions: [],
      totalTxCount: 0,
      rendar: 0,
      loadingTransactions: false,
      itemId: this.$route.params.id,
      currentPage: 1,
    };
  },
  watch: {
    periodFilter() {
      this.searchQry = "";
      this.rendar++;
      this.currentPage = 1;
      this.fetchTransactions(this.itemId, this.currentPage);
    },
  },
  mounted() {
    this.fetchTransactions(this.itemId, this.currentPage);
  },
  unmounted() {
    this.$store.dispatch("CANCEL_PENDING_REQUESTS");
  },
  methods: {
    pageUpdated(selectedPage) {
      if (selectedPage > 0 && selectedPage) {
        this.currentPage = selectedPage;
        this.fetchTransactions(this.itemId, this.currentPage);
      }
    },
    routeBack() {
      this.$router.go(-1);
    },
    setSearchQuery(val) {
      this.searchQry = val;
    },
    setDates(dates) {
      this.selectedDates = { ...dates };
    },
    fetchTransactions(id, page) {
      const { tenant_transaction_from_date, tenant_transaction_to_date } =
        this.filters;
      const payload = {
        tenant_transaction_from_date,
        tenant_transaction_to_date,
        ...(this.searchQry && { search_by: this.searchQry }),
      };
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");

      if (this.$route.meta?.mainRoute === "Categories") {
        //console.log("Fetch categories: ", payload, id, page);
        this.getCategoryTransactions(payload, id, page);
      } else if (this.$route.meta?.mainRoute === "Brands") {
        this.getBrandTransactions(payload, id, page);
      }
    },
    getCategoryTransactions(filters, id, page) {
      this.loadingTransactions = true;

      OverviewAPI.getAllUsersTransactions({
        ...filters,
        category_ids: [id],
        page,
        per_page: 20,
      })
        .then((res) => {
          this.loadingTransactions = false;
          this.transactions = res.data?.tenant_customers_transactions ?? [];
          this.totalTxCount = res.data?.tenant_customers_transactions_count;
        })
        .catch((error) => {
          if (error !== "canceled") {
            this.loadingTransactions = false;
          }
          this.transactions = [];
          this.totalTxCount = 0;
        });
    },

    getBrandTransactions(filters, id, page) {
      this.loadingTransactions = true;

      OverviewAPI.getAllUsersTransactions({
        ...filters,
        brand_ids: [id],
        page,
        per_page: 20,
      })
        .then((res) => {
          this.loadingTransactions = false;
          this.transactions = res.data?.tenant_customers_transactions ?? [];
          this.totalTxCount = res.data?.tenant_customers_transactions_count;
        })
        .catch((error) => {
          if (error !== "canceled") {
            this.loadingTransactions = false;
          }
          this.transactions = [];
          this.totalTxCount = 0;
        });
    },
  },
};
</script>

<style scoped>
.tx-total-label {
  font-weight: 400;
}
</style>
