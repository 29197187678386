import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";
import DashboardSkeleton from "../views/DashboardSkeleton.vue";
import Settings from "../views/Settings.vue";
import NewOverview from "../views/NewOverview.vue";
import UsersView from "../views/UsersView.vue";
import UsersMainView from "../views/UsersMainView.vue";
import UserDetailsView from "../views/UserDetailsView.vue";
import DetailedSpendTypesView from "../views/DetailedSpendTypesView.vue";
import UserCategoryDetailsView from "../views/UserCategoryDetailsView.vue";
import TransactionsView from "../views/TransactionsView.vue";
import Documentation from "../views/Documentation.vue";
import ForgetPasswordConfirm from "../views/ForgetPasswordConfirm.vue";
import TransactionsHistory from "../views/TransactionsHistory.vue";
import DashboardMain from "../views/DashboardMain.vue";
import Demography from "../views/Demography.vue";
import UserExpenses from "../views/UserExpenses.vue";
import Transfers from "../views/Transfers.vue";
import ViewAll from "../views/ViewAll.vue";
import LoginView from "../views/LoginView.vue";
import LoginForm from "../components/LoginFlow/LoginForm.vue";
import ForgetPasswordForm from "../components/LoginFlow/ForgetPasswordForm.vue";
import SignUpForm from "../components/LoginFlow/SignUpForm.vue";
import VerifySignUp from "../components/LoginFlow/VerifySignUp.vue";
import ChangeForgettedPasswordForm from "../components/LoginFlow/ChangeForgettedPasswordForm.vue";
import CompanyDetailsForm from "../components/LoginFlow/CompanyDetailsForm.vue";
import SetNewPasswordForm from "../components/LoginFlow/SetNewPasswordForm.vue";
import VerifyOTPForm from "../components/LoginFlow/VerifyOTPForm.vue";
import Account from "../views/Account.vue";
import Team from "../views/Team.vue";

// === Not Used ===
// import CampaignView from "../views/CampaignView.vue";
// import CampaignListView from "../views/CampaignListView.vue";
// import ActiveCampaigns from "../views/ActiveCampaigns.vue";
// import ScheduledCampaigns from "../views/ScheduledCampaigns.vue";
// import CampaignDrafts from "../views/CampaignDrafts.vue";
// import ArchivedCampaigns from "../views/ArchivedCampaigns.vue";
// import CreateCampaign from "../views/CreateCampaign.vue";
// import ViewCampaignDetails from "../views/ViewCampaignDetails.vue";

// === Cleaned ===
// import DashboardView from "../views/DashboardView.vue";
// import Dashboard from "../views/Dashboard.vue";
// import Overview from "../views/Overview.vue";

const routes = [
  {
    path: "/",
    name: "DashboardSkeleton",
    component: DashboardSkeleton,
    beforeEnter: (to, from, next) => {
      if (to.meta.unguarded ?? false) {
        return next();
      } else {
        let token = localStorage.getItem("token");
        let isAuthenticated = ![null, undefined, ""].includes(token?.trim());

        if (isAuthenticated) {
          const azureUser =
            JSON.parse(localStorage.getItem("azureUserAccount")) ?? {};
          store.commit("updateAzureUserAccount", { ...azureUser });

          return next();
        } else {
          return next("/auth/login");
        }
      }
    },
    children: [
      {
        path: "",
        name: "DashboardMain",
        component: DashboardMain,
        meta: {
          title: "DashboardMain",
          rolesHasAccess: [1, 2, 3],
        },
        redirect: {
          name: "Overview",
        },
        children: [
          {
            path: "overview",
            name: "Overview",
            component: NewOverview,
            meta: {
              title: "Overview",
            },
          },
          {
            path: "categories",
            name: "Categories",
            component: ViewAll,
            meta: {
              title: "Categories",
              rolesHasAccess: [1, 2, 3],
              breadCrumbList: [
                { label: "Overview", name: "Overview" },
                { label: "All Categories", name: "Categories" },
              ],
            },
          },
          {
            path: "brands",
            name: "Brands",
            component: ViewAll,
            meta: {
              title: "Brands",
              rolesHasAccess: [1, 2, 3],
              breadCrumbList: [
                { label: "Overview", name: "Overview" },
                { label: "All Brands", name: "Brands" },
              ],
            },
          },
          {
            path: "categories/transactions/:id",
            name: "TransactionsHistoryCategories",
            component: TransactionsHistory,
            meta: {
              title: "Transactions history",
              mainRoute: "Categories",
              rolesHasAccess: [1, 2, 3],
              breadCrumbList: [
                { label: "Overview", name: "Overview" },
                { label: "All Categories", name: "Categories" },
                {
                  label: "Specific Category",
                  name: "TransactionsHistoryCategories",
                  queryParam: "category_label",
                },
              ],
            },
            props: true,
          },
          {
            path: "brands/transactions/:id",
            name: "TransactionsHistoryBrands",
            component: TransactionsHistory,
            meta: {
              title: "Transactions history",
              mainRoute: "Brands",
              rolesHasAccess: [1, 2, 3],
              breadCrumbList: [
                { label: "Overview", name: "Overview" },
                { label: "All Brands", name: "Brands" },
                {
                  label: "Specific Brand",
                  name: "TransactionsHistoryBrands",
                  queryParam: "brand_label",
                },
              ],
            },
            props: true,
          },
          {
            path: "transactions",
            name: "MainTransactions",
            component: TransactionsView,
            meta: {
              title: "Transactions",
              hiddenFilters: ["metric", "currency"],
              shownFilters: [
                "multi_currency",
                "brands",
                "categories",
                "transaction_types",
              ],
              breadCrumbList: [
                { label: "Overview", name: "Overview" },
                { label: "Transactions", name: "MainTransactions" },
              ],
            },
          },
          {
            path: "transactions/user/:userId/:cloudId",
            name: "MainUserTransactions",
            component: TransactionsView,
            meta: {
              title: "Transactions",
              shownFilters: [
                "account_id",
                "user_currency",
                "transaction_types",
                "brands",
                "categories",
              ],
              hiddenFilters: [
                "metric",
                "income",
                "age",
                "nationality",
                "gender",
                "segment",
                "account_category",
                "currency",
              ],
              breadCrumbList: [
                { label: "Overview", name: "Overview" },
                { label: "Users", name: "UsersView" },
                {
                  label: "user_name",
                  name: "UserDetails",
                  queryParam: "user_name",
                },
                {
                  label: "Transactions",
                  name: "MainUserTransactions",
                },
              ],
            },
            props: true,
          },
          {
            path: "all-currencies",
            name: "AllCurrencies",
            component: DetailedSpendTypesView,
            meta: {
              title: "Spend Per Currency",
              label: "Currency",
              for: "currencies",
              breadCrumbList: [
                { label: "Overview", name: "Overview" },
                { label: "All Currencies", name: "AllCurrencies" },
              ],
            },
          },
          {
            path: "all-transaction-types",
            name: "AllTxType",
            component: DetailedSpendTypesView,
            meta: {
              title: "Spend Per Transaction Type",
              label: "Transaction Type",
              for: "types",
              breadCrumbList: [
                { label: "Overview", name: "Overview" },
                { label: "All Transaction Types", name: "AllTxType" },
              ],
            },
          },
          {
            path: "users",
            name: "UsersView",
            redirect: { name: "UsersMain" },
            component: UsersView,
            meta: {
              title: "Users",
              breadCrumbList: [
                { label: "Overview", name: "Overview" },
                { label: "Users", name: "UsersView" },
              ],
            },
            children: [
              {
                path: "",
                name: "UsersMain",
                component: UsersMainView,
                meta: {
                  hiddenFilters: ["metric", "currency"],
                  shownFilters: ["multi_currency"],
                  title: "Users",
                },
              },
              {
                path: "brands/:brandId/:brandLabel",
                name: "UsersMainByBrand",
                component: UsersMainView,
                meta: {
                  hiddenFilters: ["metric", "currency"],
                  shownFilters: ["multi_currency"],
                  title: "Users",
                  breadCrumbList: [
                    { label: "Overview", name: "Overview" },
                    { label: "All Brands", name: "Brands" },
                    {
                      label: "brandLabel",
                      name: "Brands",
                      param: "brandLabel",
                    },
                    { label: "Users", name: "UsersView" },
                  ],
                },
                props: true,
              },
              {
                path: "categories/:categoryId/:categoryLabel",
                name: "UsersMainByCategory",
                component: UsersMainView,
                meta: {
                  hiddenFilters: ["metric", "currency"],
                  shownFilters: ["multi_currency"],
                  title: "Users",
                  breadCrumbList: [
                    { label: "Overview", name: "Overview" },
                    { label: "All Categories", name: "Categories" },
                    {
                      label: "categoryLabel",
                      name: "Categories",
                      param: "categoryLabel",
                    },
                    { label: "Users", name: "UsersView" },
                  ],
                },
                props: true,
                beforeEnter: (to, from, next) => {
                  const paramValue = to.params.categoryId;

                  if (paramValue >= 1 && paramValue <= 19) {
                    next(); // Continue to the route
                  } else {
                    next({ name: "UsersMain" }); // Redirect to another route if the condition is not met
                  }
                },
              },
              {
                path: "details/:userId/:cloudId",
                name: "UserDetails",
                component: UserDetailsView,
                meta: {
                  title: "User Details",
                  breadCrumbList: [
                    { label: "Overview", name: "Overview" },
                    { label: "Users", name: "UsersView" },
                    {
                      label: "user_name",
                      name: "UserDetails",
                      queryParam: "user_name",
                    },
                  ],
                },
              },
              {
                path: "details/:userId/:accountId/:cloudId/categories/:catId",
                name: "UserCategoryDetails",
                component: UserCategoryDetailsView,
                meta: {
                  title: "Category Label",
                  routeIcon: require("@/assets/images/svg/new_categories/Education.svg"),
                  breadCrumbList: [
                    { label: "Overview", name: "Overview" },
                    { label: "Users", name: "UsersView" },
                    {
                      label: "user_name",
                      name: "UserDetails",
                      queryParam: "user_name",
                    },
                    {
                      label: "Category Label",
                      name: "UserCategoryDetails",
                    },
                  ],
                },
                props: true,
              },
            ],
          },
          {
            path: "transactions/users/:userId/:cloudId",
            name: "UserDetailsFromTransactions",
            component: UserDetailsView,
            meta: {
              title: "User Details",
              breadCrumbList: [
                { label: "Overview", name: "Overview" },
                { label: "Transactions", name: "MainTransactions" },
                {
                  label: "user_name",
                  name: "UserDetails",
                  queryParam: "user_name",
                },
              ],
            },
          },
          {
            path: "demography",
            name: "Demographics",
            component: Demography,
            meta: { title: "Demographics", rolesHasAccess: [1, 2, 3] },
          },
          {
            path: "trends",
            name: "Trends",
            component: UserExpenses,
            meta: { title: "Trends", rolesHasAccess: [1, 2, 3] },
          },
          {
            path: "transfers",
            name: "TransfersInsights",
            component: Transfers,
            meta: { title: "Transfers Insights", rolesHasAccess: [1, 2, 3] },
          },
          {
            path: "documentation",
            name: "Documentation",
            component: Documentation,
            meta: {
              title: "Documentation",
              rolesHasAccess: [1, 2, 3],
              requiresPermission: false,
              hiddenFilters: ["from_date", "metric"],
            },
          },

          {
            path: "settings",
            name: "Settings",
            component: Settings,
            redirect: { name: "Account" },
            meta: {
              requiresPermission: false,
              title: "Settings",
              rolesHasAccess: [1, 2, 3],
            },
            children: [
              {
                path: "account",
                name: "Account",
                component: Account,
                meta: {
                  title: "Account Settings",
                  hiddenFilters: ["from_date", "to_date", "metric"],
                  breadCrumbList: [
                    { label: "Settings", name: "Settings" },
                    { label: "Account", name: "Account" },
                  ],
                },
              },
              {
                path: "team",
                name: "Team",
                component: Team,
                meta: {
                  title: "Team Settings",
                  hiddenFilters: ["from_date", "to_date", "metric"],
                  breadCrumbList: [
                    { label: "Settings", name: "Settings" },
                    { label: "Team", name: "Team" },
                  ],
                },
              },
              {
                path: "*",
                redirect: "account",
              },
            ],
          },
          {
            path: "/:pathMatch(.*)*",
            redirect: { name: "Overview" },
          },
        ],
      },
      // {
      //   path: "old",
      //   name: "OldOverview",
      //   component: DashboardView,
      //   children: [
      //     {
      //       path: "",
      //       name: "OldDashboard",
      //       component: Dashboard,
      //       meta: {
      //         title: "Old Dashboard",
      //         rolesHasAccess: [1, 2, 3],
      //       },
      //       props: true,
      //     },
      //   ],
      // },
      // {
      //   path: "documentation",
      //   name: "Documentation",
      //   component: Documentation,
      //   meta: {
      //     requiresPermission: false,
      //     title: "Documentation",
      //     rolesHasAccess: [1, 2, 3],
      //   },
      // },
      // {
      //   path: "campaigns",
      //   name: "CampaignView",
      //   component: CampaignView,
      //   children: [
      //     {
      //       path: "account",
      //       name: "Account",
      //       component: Account,
      //       meta: { title: "Account Settings", rolesHasAccess: [1, 2, 3] },
      //     },
      //     {
      //       path: "team",
      //       name: "Team",
      //       component: Team,
      //       meta: { title: "Team Settings", rolesHasAccess: [1, 2] },
      //     },
      //     {
      //       path: "support",
      //       name: "Support",
      //       component: Support,
      //       meta: { title: "Support" },
      //     },
      //     {
      //       path: "about",
      //       name: "About",
      //       component: About,
      //       meta: { title: "About" },
      //     },
      //     {
      //       path: "new",
      //       name: "BuildCampaign",
      //       component: CreateCampaign,
      //     },
      //     {
      //       path: "view/:id",
      //       name: "ViewCampaign",
      //       component: ViewCampaignDetails,
      //     },
      //     {
      //       path: "edit/:id",
      //       name: "EditCampaign",
      //       component: ViewCampaignDetails,
      //     },
      //   ],
      // },
    ],
  },
  {
    path: "/reset-pass", //reset forgoton password
    name: "ResetPasswordDestination",
    component: LoginView,
    meta: {
      unguarded: true,
      title: "Reset Password",
    },
    beforeEnter: (to, from, next) => {
      window.zE("webWidget", "logout");
      localStorage.removeItem("token");

      return next({
        name: "ChangeForgettedPasswordForm",
        query: {
          form_key: "reset_token",
          token: to.query.reset_token,
        },
      });
    },
  },
  {
    path: "/invite-set-pass", //set pass for invited members
    name: "InviteDestination",
    component: LoginView,
    meta: {
      unguarded: true,
      title: "Set Password",
    },
    beforeEnter: (to, from, next) => {
      window.zE("webWidget", "logout");
      localStorage.removeItem("token");

      return next({
        name: "SetNewPasswordForm",
        query: {
          form_key: "invite_token",
          token: to.query.invite_token,
        },
      });
    },
  },
  {
    path: "/verify-email",
    name: "VerifyEmailDestination",
    component: LoginView,
    meta: {
      unguarded: true,
      title: "Verify Email",
    },
    beforeEnter: (to, from, next) => {
      window.zE("webWidget", "logout");
      return next({
        name: "VerifySignUp",
        params: {
          form_key: "verification_token",
          token: to.query.verification_token,
        },
      });
    },
  },
  {
    path: "/azure/auth/redirect/",
    name: "AzureLogin",
    redirect: { name: "Overview" },
    meta: { requiresPermission: false, title: "Azure Login" },
  },
  {
    path: "/auth",
    name: "Login",
    redirect: { name: "LoginForm" },
    component: LoginView,
    meta: {
      animation: "slide-left",
      requiresPermission: false,
      title: "Login",
    },
    beforeEnter: (to, from, next) => {
      window.zE("webWidget", "logout");
      return next();
    },
    children: [
      {
        path: "login",
        name: "LoginForm",
        component: LoginForm,
        meta: { title: "Login Form", animation: "slide-left" },
      },
      {
        path: "forget-password", //rest pass using email form
        name: "ForgetPasswordForm",
        component: ForgetPasswordForm,
        meta: { title: "Forget Password Form", animation: "slide-right" },
      },
      {
        path: "forget-password-confirm/:email", //rest pass using email form confirmation
        name: "ForgetPasswordConfirm",
        component: ForgetPasswordConfirm,
        meta: {
          label: "Forget Password Confirm View",
          animation: "slide-left",
        },
      },
      {
        path: "signup",
        name: "SignUpForm",
        redirect: "LoginForm",
        component: SignUpForm,
        meta: { title: "SignUp Form" },
      },
      {
        path: "verify-email",
        name: "VerifySignUp",
        component: VerifySignUp,
        meta: { title: "Verify Email" },
        props: true,
      },
      {
        path: "change-password", //change old password
        name: "ChangeForgettedPasswordForm",
        component: ChangeForgettedPasswordForm,
        meta: { title: "Change Password", animation: "slide-right" },
        props: true,
      },
      {
        path: "set-password", // set a new password
        name: "SetNewPasswordForm",
        component: SetNewPasswordForm,
        meta: { title: "Set Password", animation: "slide-right" },
        props: true,
      },
      {
        path: "company-details",
        name: "CompanyDetailsForm",
        component: CompanyDetailsForm,
        meta: { title: "Company Details", animation: "slide-right" },
      },
      {
        path: "verify-otp",
        name: "VerifyOTPForm",
        component: VerifyOTPForm,
        meta: { title: "Verify OTP" },
      },
      {
        path: "/auth/:catchAll(.*)",
        name: "NotFound",
        redirect: { name: "LoginForm", animation: "slide-right" },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//---- Route Guard ----
router.beforeEach((to, from, next) => {
  const cachedUser = JSON.parse(localStorage.getItem("user") ?? "{}"); //parse it to object first
  const userRole = cachedUser?.role ?? 3; //default to viewer

  if (to.meta.unguarded ?? false) {
    //auth routes
    return next();
  } else {
    //role user [1, 2, 3] trying to access role route
    // const routeRoles = to.meta.rolesHasAccess ?? [];

    //Has acess to the destination route?
    // if (routeRoles.includes(userRole) || !("rolesHasAccess" in to.meta))
    return next();
    // else {
    //   console.error("Can't Access this Route");
    //   return next({ name: from.name });
    // }
  }
});

export default router;
