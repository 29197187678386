<template>
  <div class="row p-3 position-relative widget-area overflow-auto pb-0 h-100">
    <div
      ref="entriesTable"
      class="
        col-lg-5 col-md-12
        view-card-scroll-widget
        position-relative
        overflow-auto
        d-flex
        flex-column
        justify-content-between
      "
    >
      <div>
        <SpendingCardViewAll
          :key="updateKey"
          :metric="metric"
          :title="title"
          :with-searchbar="title === 'Brands'"
          :data="data"
          :initial-index="currentIndex"
          :loading="loading"
          @dataChange="updateSelectedItem"
          @search-brand="fetchBrandsWithQry"
        />
      </div>

      <div>
        <Paginator
          v-show="title === 'Brands' && totalRecords > 20 && !loading"
          class="my-2 py-3 rounded bg-transparent"
          :total-records="totalRecords"
          :rows="20"
          @page="changePage"
        />
      </div>
    </div>

    <div
      ref="entriesDetails"
      class="p-2 pb-4 col-lg-7 col-md-12 view-card-scroll-widget overflow-auto"
    >
      <div :id="title + '-user-spend-chart'" class="card p-3 mx-2">
        <TransactionValueChart
          :metric="metric"
          :selected-item="selectedItem"
          :for-category="title === 'Brands' ? false : true"
          :period="timeFilters?.periodFilter"
          :time-period="timePeriod"
          :data="transactionValue"
          :loading="loadingChartData"
          :title="title"
        />
      </div>

      <div class="d-flex justify-content-between align-items-center my-4">
        <CountCard
          :id="title + '-user-count-card'"
          title="Users"
          description="Number of users"
          :count="currentIndex ? countCardValues?.totalUsers : 0"
          :count-percent="
            currentIndex ? countCardValues.percentUsers?.diffValue : 0
          "
          theme="green"
          class="w-50 mx-2 me-4 cp"
          :view="title"
          @click="redirectToFiltredUsers(selectedItem)"
        />

        <CountCard
          :id="title + '-tx-count-card'"
          title="Transactions"
          description="Number of transactions"
          :count="currentIndex ? countCardValues.totalTx : 0"
          :count-percent="
            currentIndex ? countCardValues.percentTx?.diffValue : 0
          "
          theme="white"
          class="w-50 me-2"
          :view="title"
        />
      </div>

      <div v-if="title === 'Categories'">
        <TopBrands
          :id="title + '-top-brands'"
          :brands-list="topBrandsList"
          :loading="loadingTopBrands"
        />
      </div>

      <div :id="title + '-top-transactions'">
        <TopTransactions
          :id="currentIndex"
          :tx-list="topTxList"
          :title="getCategoryLabel(data[currentIndex]?.id) ?? ''"
          :loading="loadingTopTransactions"
          :view="title"
          :data="data[currentIndex]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SpendingCardViewAll from "../components/SpendingCardViewAll.vue";
import Paginator from "../components/Paginator.vue";
import TransactionValueChart from "../components/TransactionValueChart.vue";
import TopBrands from "../components/TopBrands.vue";
import TopTransactions from "../components/TopTransactions.vue";
import CountCard from "../components/CountCard.vue";
import { categories } from "../data/options.js";
import { debounce as _debounce } from "lodash";
import OverviewAPI from "@/services/api/OverviewAPI";

export default {
  components: {
    Paginator,
    SpendingCardViewAll,
    TransactionValueChart,
    TopBrands,
    TopTransactions,
    CountCard,
  },
  inject: ["getCategoryLabel", "useDiffCalculator"],
  props: {
    metric: {
      type: String,
      default: "average",
    },
    fetchKey: {
      default: 1,
    },
    filters: {
      default: {},
    },
    periodFilter: {
      default: 30,
    },
    timePeriod: {
      type: String,
    },
  },
  data() {
    return {
      categories: categories,
      title: this.$route.name,
      searchQry: "",
      data: {},
      page: 1,
      currentIndex: null,
      entries: [],
      updateKey: 0,
      transactionValue: [],
      countValues: {},
      topBrandsList: [],
      topTxList: [],
      loading: false,
      loadingTopBrands: false,
      loadingTopTransactions: false,
      loadingChartData: false,
      brandsData: 0,
      totalRecords: 0,
    };
  },
  computed: {
    currency() {
      return this.$store.state.currency.trim();
    },
    timeFilters() {
      return this.$store.state.overViewStore.timeFilters;
    },
    selectedItem() {
      const selectedItem = this.data[this.currentIndex ?? 0] ?? {};
      return {
        name:
          this.title === "Categories"
            ? this.getCategoryLabel(selectedItem?.id)
            : selectedItem?.name ?? "--",
        logo:
          this.title === "Categories"
            ? this.categories[selectedItem?.id ?? "1"].icon ?? ""
            : `${process.env.VUE_APP_IMG_ORIGINAL_BASE_URL}${selectedItem?.logo}`,
        ...selectedItem,
      };
    },
    exportPDFLoadingState() {
      let loaddedAllDate =
        this.loadingChartData ||
        this.loading ||
        this.loadingTopBrands ||
        this.loadingTopTransactions;
      return loaddedAllDate;
    },
    countCardValues() {
      if (this.title === "Categories") {
        return {
          title: "Categorie",
          totalTx: this.countValues.tenant_customers_transactions_count ?? 0,
          totalUsers: this.countValues.tenant_customers_count ?? 0,
          percentTx: this.useDiffCalculator(
            this.countValues?.tenant_customers_transactions_count ?? 0,
            this.countValues
              ?.comparison_period_tenant_customers_transactions_count ?? 0
          ),
          percentUsers: this.useDiffCalculator(
            this.countValues?.tenant_customers_count ?? 0,
            this.countValues?.comparison_period_tenant_customers_count ?? 0
          ),
        };
      } else {
        return {
          title: "Brand",
          totalTx: this.countValues?.tenant_customers_transactions_count ?? 0,
          totalUsers: this.countValues?.tenant_customers_count ?? 0,

          percentTx: this.useDiffCalculator(
            this.countValues?.tenant_customers_transactions_count ?? 0,
            this.countValues
              ?.comparison_period_tenant_customers_transactions_count ?? 0
          ),
          percentUsers: this.useDiffCalculator(
            this.countValues?.tenant_customers_count ?? 0,
            this.countValues?.comparison_period_tenant_customers_count ?? 0
          ),
        };
      }
    },
  },
  watch: {
    exportPDFLoadingState(newVal) {
      if (this.$route.name === "Categories")
        this.$store.commit("updateLoadingCategories", newVal);

      if (this.$route.name === "Brands")
        this.$store.commit("updateLoadingBrandsData", newVal);
    },
    countValues(newVal) {
      if (this.title === "Categories")
        this.$store.commit("updatePdfData", {
          selectedCategory: { ...newVal },
        });

      if (this.title === "Brands")
        this.$store.commit("updatePdfData", { selectedBrand: { ...newVal } });
    },
    fetchKey() {
      this.searchQry = "";
      this.updateKey++;
      this.fetchData();
    },
  },
  mounted() {
    //fetch onMount
    if (
      this.filters?.tenant_transaction_from_date ||
      this.filters?.tenant_transaction_to_date
    ) {
      this.fetchData();
    }
  },
  unmounted() {
    this.$store.dispatch("CANCEL_PENDING_REQUESTS");
  },
  methods: {
    redirectToFiltredUsers(item) {
      if (this.$route.name === "Brands") {
        this.$router.push({
          name: "UsersMainByBrand",
          params: {
            brandId: item?.id,
            brandLabel: item?.name,
            filters: JSON.stringify({
              multi_currency: [{ key: this.currency, value: this.currency }],
            }),
          },
        });
      } else {
        this.$router.push({
          name: "UsersMainByCategory",
          params: {
            categoryId: item?.id,
            categoryLabel: item?.name,
            filters: JSON.stringify({
              multi_currency: [{ key: this.currency, value: this.currency }],
            }),
          },
        });
      }
    },

    parseData(list) {
      if (list.length === 0) return {};
      let data = {};
      const currAvgKey = "tenant_customers_avg_spend";
      const oldAvgtKey = "comparison_period_tenant_customers_avg_spend";

      const currTotalKey = "tenant_customers_total_spend";
      const oldTotalKey = "comparison_period_tenant_customers_total_spend";

      list.forEach((item) => {
        data[item.id] = {
          ...item,
          rel_percent_avg_spend: this.useDiffCalculator(
            item[currAvgKey] ?? 0,
            item[oldAvgtKey] ?? 0
          ).diffValue,

          rel_percent_total_spend: this.useDiffCalculator(
            item[currTotalKey] ?? 0,
            item[oldTotalKey] ?? 0
          ).diffValue,
        };
      });

      return data;
    },
    resetPagination() {
      const firstPageBtn = document.querySelector(
        ".view-card-scroll-widget .p-paginator-first"
      );

      if (!firstPageBtn.disabled) {
        firstPageBtn.click();
      }
    },
    fetchBrandsWithQry: _debounce(function (newQry) {
      this.searchQry = newQry;
      //reset
      this.resetPagination();

      this.fetchData();
    }, 850),

    fetchData() {
      const filters = {
        ...this.filters,
        ...(this.searchQry && { keyword: this.searchQry }),
      };
      //Reset all previous states and cancel previous requests
      this.resetStates();
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");

      this.loading = true;
      this.data = {};

      if (this.title == "Categories") {
        OverviewAPI.getCategoriesData(filters)
          .then((response) => {
            //sort entries
            const categoriesData = this.parseData(
              response.data?.categories ?? []
            );

            const amountKey =
              this.metric === "average"
                ? "tenant_customers_avg_spend"
                : "tenant_customers_total_spend";
            let entries = Object.entries(categoriesData ?? {}).sort((a, b) => {
              let res =
                Math.abs(b[1]?.[amountKey] ?? 0) -
                Math.abs(a[1]?.[amountKey] ?? 0);

              return res;
            });

            this.data = categoriesData;

            //Get first Index
            if ((entries.length ?? 0) > 0) {
              this.currentIndex = entries[0][0];
              this.countValues = this.data[this.currentIndex ?? 1] ?? {};
            } else {
              this.currentIndex = null;
            }

            if (this.currentIndex) {
              //Parallel Requests
              this.getTransactionsSpend(filters);
              this.getTopTxPerCat(this.currentIndex);
              this.getTopBrandsPerCat(this.currentIndex);
            }

            this.loading = false;
          })
          .catch((error) => {
            if (error !== "canceled") {
              this.loading = false;
              this.data = {};
              console.error("error: ", error);
            }
          });
      } else {
        //reset
        this.totalRecords = 0;

        OverviewAPI.getBrandsData({ ...filters, per_page: 20 }, this.page)
          .then((response) => {
            this.totalRecords = response.data.brands_count;
            const brandsData = this.parseData(response.data?.brands ?? []);

            const amountKey =
              this.metric === "average"
                ? "tenant_customers_avg_spend"
                : "tenant_customers_total_spend";
            let entries = Object.entries(brandsData ?? {}).sort((a, b) => {
              let res =
                Math.abs(b[1]?.[amountKey] ?? 0) -
                Math.abs(a[1]?.[amountKey] ?? 0);

              return res;
            });

            this.data = brandsData;

            if ((entries.length ?? 0) > 0) {
              this.currentIndex = entries[0][0];
              this.countValues = this.data[this.currentIndex ?? 1] ?? {};
            } else {
              this.currentIndex = null;
            }

            if (this.currentIndex) {
              //Parallel Requests
              this.getTopTxPerBrand(this.currentIndex);
              this.getTransactionsSpend(filters);
            }

            this.loading = false;
          })
          .catch((error) => {
            if (error !== "canceled") {
              this.loading = false;
              this.data = {};
              this.totalRecords = 0;
              console.error("error: ", error);
            }
          });
      }
    },
    resetStates() {
      //console.log("Reset is called");
      this.loadingChartData = false;
      this.loadingTopTransactions = false;
      this.loadingTopBrands = false;

      this.currentIndex = null;
      this.transactionValue = [];
      this.topTxList = [];
      this.topBrandsList = [];
    },
    changePage(event) {
      //reset states
      //this.resetStates();

      //Scroll to top of div's
      const entriesTable = this.$refs.entriesTable;
      entriesTable.scroll({ top: 0, behavior: "smooth" });

      const entriesDetails = this.$refs.entriesDetails;
      entriesDetails.scroll({ top: 0, behavior: "smooth" });

      //Fetch new page entries
      this.page = event.page + 1;
      this.fetchData();
    },
    updateSelectedItem(id) {
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");

      //update components
      this.currentIndex = id ?? this.currentIndex;
      this.countValues = this.data[this.currentIndex ?? 1] ?? {};

      let filters = { ...this.filters };
      this.getTransactionsSpend(filters);

      if (this.title == "Categories") {
        this.getTopTxPerCat(id);
        this.getTopBrandsPerCat(id);
      } else {
        this.getTopTxPerBrand(id);
      }
    },
    getTransactionsSpend(filter) {
      this.loadingChartData = true;
      this.transactionValue = []; //reset

      if (this.title == "Categories") {
        OverviewAPI.getCategorySpending(
          this.currentIndex,
          filter,
          this.timeFilters?.periodFilter
        )
          .then((response) => {
            this.transactionValue = response.data?.category ?? [];
            this.loadingChartData = false;
          })
          .catch((err) => {
            if (err !== "canceled") {
              this.transactionValue = [];
              this.loadingChartData = false;
              console.error("error: ", err);
            }
          });
      } else {
        OverviewAPI.getBrandSpending(
          this.currentIndex,
          filter,
          this.timeFilters?.periodFilter
        )
          .then((response) => {
            this.transactionValue = response.data?.brands ?? [];
            this.loadingChartData = false;
          })
          .catch((err) => {
            if (err !== "canceled") {
              this.transactionValue = [];
              this.loadingChartData = false;
              console.error("error", err);
            }
          });
      }
    },
    getTopBrandsPerCat(catId) {
      let filters = { ...this.filters };
      this.loadingTopBrands = true;

      OverviewAPI.getTopBrandsPerCategory(filters, catId)
        .then((res) => {
          this.topBrandsList = res?.data?.brands ?? [];
          this.loadingTopBrands = false;
        })
        .catch((err) => {
          if (err !== "canceled") {
            this.topBrandsList = [];
            this.loadingTopBrands = false;
            console.error("Faild to fetch: ", err);
          }
        });
    },
    getTopTxPerCat(catId) {
      let filters = { ...this.filters };
      this.loadingTopTransactions = true;

      OverviewAPI.getAllUsersTransactions({
        ...filters,
        category_ids: [catId],
        page: 1,
        per_page: 10,
        sort_by: "amount",
        order: "desc",
      })
        .then((res) => {
          this.topTxList = res.data?.tenant_customers_transactions ?? [];
          this.loadingTopTransactions = false;
        })
        .catch((err) => {
          if (err !== "canceled") {
            this.topTxList = [];
            this.loadingTopTransactions = false;
            console.error("error: ", err);
          }
        });
    },
    getTopTxPerBrand(brandId) {
      let filters = { ...this.filters };
      this.loadingTopTransactions = true;
      this.topTxList = []; //reset

      OverviewAPI.getAllUsersTransactions({
        ...filters,
        brand_ids: [brandId],
        page: 1,
        per_page: 10,
        sort_by: "amount",
        order: "desc",
      })
        .then((res) => {
          this.topTxList = res.data?.tenant_customers_transactions ?? [];
          this.loadingTopTransactions = false;
        })
        .catch((err) => {
          if (err !== "canceled") {
            this.topTxList = [];
            this.loadingTopTransactions = false;
            console.error("error", err);
          }
        });
    },
  },
};
</script>

<style scoped>
.view-card-scroll-widget {
  min-height: 100% !important;
  max-height: 100% !important;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.view-card-scroll-widget::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.paginator-container {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
}
</style>
