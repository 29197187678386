<template>
  <div :key="rendar">
    <div v-show="!loading" class="h-100">
      <div
        v-if="sortedTransactions.length > 0"
        ref="transactionTableContainer"
        class="table-responsive transactions-table"
      >
        <table v-if="!loading" class="table align-middle">
          <thead>
            <tr>
              <th scope="col" class="col-3 text-nowrap">
                <FilterMenu
                  label="Transactions Description"
                  class="th-sort-button"
                  :sort-is-active="sortOption == sortOptions.description"
                  :reverse-sorting="sortOption == 'reverse-0'"
                  @click="sortData(sortOptions.description)"
                />
              </th>
              <th scope="col" class="col-2 text-nowrap">
                <FilterMenu
                  class="th-sort-button"
                  label="Amount"
                  :sort-is-active="sortOption == sortOptions.amount"
                  :reverse-sorting="sortOption == 'reverse-1'"
                  @click="sortData(sortOptions.amount)"
                />
              </th>
              <th scope="col" class="col-3 text-nowrap">
                <FilterMenu
                  class="th-sort-button"
                  label="Date & Time"
                  :sort-is-active="sortOption == sortOptions.date"
                  :reverse-sorting="sortOption == 'reverse-2'"
                  @click="sortData(sortOptions.date)"
                />
              </th>
              <th scope="col" class="col-2 text-nowrap">
                <span>Brand</span>
              </th>
              <th scope="col" class="col-2 text-nowrap">
                <span>Category</span>
              </th>
              <th scope="col" class="col text-nowrap"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(tx, index) in sortedTransactions" :key="index">
              <td class="text-wrap">
                {{ tx.raw_description ?? "--" }}
              </td>
              <td class="text-wrap amount-column">
                {{ compactNumber(Math.abs(tx.amount)) ?? 0 }}
                <small>{{ tx.currency_code }}</small>
              </td>
              <td class="text-wrap">
                {{ formatDate(tx.timestamp) ?? "--" }}
              </td>
              <td class="text-wrap">
                {{ tx.brand_name ?? "--" }}
              </td>
              <td class="text-wrap">
                {{ categories[tx.category_id]?.label ?? "--" }}
              </td>
              <td class="text-wrap">
                <TransactionsTableOptions @report-tx="reportTx(tx)" />
              </td>
            </tr>
          </tbody>
        </table>

        <Paginator
          v-if="totalRecords > 20"
          class="mt-2 py-3 rounded"
          :total-records="totalRecords"
          :rows="20"
          @page="changePage"
        />
      </div>

      <div v-else>
        <CardsEmptyState class="mt-5" />
      </div>

      <ReportTransactionsForm
        id="report-transactions-modal"
        :show-modal="showReportForm"
        :data="modalData"
        @closeModal="closeModal"
      />
    </div>
    <div v-show="loading" class="w-100 h-100 position-relative">
      <div class="loader px-auto py-auto">
        <span class="position-absolute top-50 start-50 translate-middle">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CardsEmptyState from "./CardsEmptyState.vue";
import TransactionsTableOptions from "./helpers/TransactionsTableOptions.vue";
import Paginator from "./Paginator.vue";
import moment from "moment";
import { categories } from "../data/options";
import FilterMenu from "./FilterMenu.vue";
import ReportTransactionsForm from "./ReportTransactionsForm.vue";

export default {
  components: {
    Paginator,
    TransactionsTableOptions,
    CardsEmptyState,
    FilterMenu,
    ReportTransactionsForm,
  },
  inject: ["compactNumber"],
  props: {
    transactions: {
      type: Array,
      default: [],
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rendar: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      modalData: { name: "Test" },
      showReportForm: false,
      categories: categories,
      sortOption: "0",
      sortOptions: {
        description: "0",
        amount: "1",
        date: "2",
      },
    };
  },
  computed: {
    sortedTransactions() {
      //exit if empty
      if (this.transactions.length === 0) return [];

      if (this.sortOption.includes("reverse")) {
        return this.transactions.reverse();
      } else {
        this.transactions.sort((a, b) => {
          let res = 0;
          switch (this.sortOption) {
            case this.sortOptions.description:
              if (
                (a.raw_description?.toLowerCase() ?? "") >
                (b.raw_description?.toLowerCase() ?? "")
              )
                res = 1;
              if (
                (a.raw_description?.toLowerCase() ?? "") <
                (b.raw_description?.toLowerCase() ?? "")
              )
                res = -1;
              break;

            case this.sortOptions.amount:
              res = Math.abs(a?.amount) - Math.abs(b?.amount);
              break;

            case this.sortOptions.date:
              res = new Date(b?.timestamp) - new Date(a?.timestamp);
              break;
          }

          return res;
        });
        return this.transactions;
      }
    },
  },
  methods: {
    closeModal() {
      this.showReportForm = false;
    },
    reportTx(tx) {
      this.modalData = tx ?? {};
      if (this.showReportForm) {
        this.showReportForm = false;
        return;
      }
      this.showReportForm = true;
    },
    changePage(pageObj) {
      //reset table scroll
      const tableScroll = this.$refs.transactionTableContainer;
      tableScroll.scrollTop = 0;

      //emit new page event
      const selectedPage = pageObj?.page + 1;
      this.$emit("pageChange", selectedPage ?? 0);
    },
    sortData(option) {
      this.sortOption =
        this.sortOption == option ? `reverse-${option}` : option;
    },
    formatDate(date) {
      return moment(date).format("D MMM, YYYY  -   h:mm A");
    },
  },
};
</script>

<style>
.transactions-table .table tbody tr td {
  line-height: 2rem;
}
.transactions-table {
  overflow: auto;
  height: 100%;
}
.transactions-table .table thead {
  background-color: var(--gray-bg);
  position: sticky;
  top: 0;
  z-index: 1;
}

.transactions-table .table thead th {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

/* Borders (if you need them) */
.transactions-table thead {
  box-shadow: inset 0px 0px #002e2a65, 0 1px #002e2a65;
}
.transactions-table {
  min-width: 50rem;
}
.th-sort-button.sort-filter span,
.th-sort-button.sort-filter i {
  color: var(--green);
  opacity: 0.55;
}
.th-sort-button.sort-filter .active {
  color: var(--green);
  opacity: 1;
}
.amount-column small {
  font-size: 0.8rem;
}
</style>
