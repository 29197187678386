<template>
  <div
    class="rounded"
    :class="{
      'bi-category': isCategoryIcon,
      'view-all-cat-icon-box': isCategoryViewAllIcon,
    }"
  >
    <img
      v-if="!loadImage"
      v-bind="{ ...$attrs }"
      :src="IconSource"
      :class="{ 'category-icon': isCategoryIcon, 'brand-icon': isBrandIcon }"
      width="15"
      loading="lazy"
      crossorigin="anonymous"
    />
    <img
      v-else
      src="@/assets/images/svg/components/circle_rolling_animation.svg"
      alt="Brand logo loading icon"
      class="loading-brand-image"
      loading="lazy"
    />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      require: true,
      default: "",
    },
    brandIconSize: {
      type: String,
      require: true,
      default: "37px",
    },
    categoryIconSize: {
      type: String,
      require: true,
      default: "40px",
    },
    isBrandIcon: {
      type: Boolean,
      require: false,
    },
    isCategoryIcon: {
      type: Boolean,
      require: false,
    },
    isCategoryViewAllIcon: {
      type: Boolean,
      require: false,
    },
  },
  data() {
    return {
      loadImage: true,
      IconSource: require("@/assets/images/svg/components/no_brand_logo.svg"),
    };
  },
  watch: {
    src: {
      handler(newURL) {
        //[1] check if cat icon then exit
        if (this.isCategoryIcon) {
          this.IconSource = newURL;
        }
        //[2] if brand icon
        this.loadImage = true;
        const imageURL = newURL + "?v_custom_cache=1";

        this.checkIfImageExists(imageURL, (exists) => {
          if (exists) {
            this.IconSource = imageURL;
          } else {
            this.IconSource = require("@/assets/images/svg/components/no_brand_logo.svg");
          }
          this.loadImage = false;
        });
      },
      immediate: true,
    },
  },
  methods: {
    checkIfImageExists(url, callback) {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = url;

      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };

        img.onerror = () => {
          callback(false);
        };
      }
    },
  },
};
</script>

<style scoped>
.bi-category {
  background-color: var(--white) !important;
  /* color: var(--sea); */
  /* padding: 4px 7px; */
}
.category-icon {
  width: v-bind(categoryIconSize);
  width: v-bind(categoryIconSize);
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #edf5f4;
}
.brand-icon {
  width: v-bind(brandIconSize);
  border-radius: 50%;
  border: 1px solid #edf5f4;
}
.loading-brand-image {
  width: v-bind(brandIconSize);
  padding: 7px;
  border-radius: 50%;
  border: 1px solid #edf5f4;
  background: var(--bluey-grey-50);
}
.view-all-cat-icon-box {
  background-color: var(--bluey-grey-10);
  color: var(--sea);
  padding: 8px 10px;
}
.view-all-cat-icon-box img {
  width: 16px;
}

.view-all-brand-icon {
  min-width: 32px;
  max-width: 32px;
}
</style>
