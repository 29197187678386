<template>
  <div class="mt-2 d-flex justify-content-between align-items-center">
    <BaseSearchAction
      :id="`search-customers-name`"
      class="w-50"
      placeholder="Search by CIF ID"
      :loading="usersListLoading"
      @updated-search="setSearchQry"
    />
  </div>

  <div ref="mainUsersViewTable" class="mt-4 pt-2">
    <UsersListTable
      v-model:firstRow="firstRow"
      :loading="usersListLoading"
      :table-rows="usersListData?.tenant_customers ?? []"
      :total-records="usersListData?.tenant_customers_accounts_count ?? 0"
      :total-users="usersListData?.tenant_customers_count ?? 0"
      @page-change="onPageChange"
      @sort-change="onSortChange"
    />
  </div>
</template>
<script>
import BaseSearchAction from "./helpers/BaseSearchAction.vue";
import ActionButton from "./helpers/ActionButton.vue";
import { computed, ref, watch, onUnmounted, onMounted } from "vue";
import UsersListTable from "../components/UsersListTable.vue";
import UsersviewAPI from "../services/api/UsersviewAPI";
import { useStore } from "vuex";
import NBFService from "@/services/NBFService";
import { useRoute } from "vue-router";

export default {
  components: { UsersListTable, ActionButton, BaseSearchAction },
  props: {
    searchQry: {
      type: String,
      default: "",
    },
  },
  emits: ["data-updated"],
  setup(props, { emit }) {
    const store = useStore();
    const usersListData = ref({});
    const usersListLoading = ref(false);
    const selectedPage = ref(1);
    const mainUsersViewTable = ref(null);
    const firstRow = ref(0);
    const route = useRoute();
    const sortObject = ref({
      field: "account_total_spend",
      order: -1,
    });

    const baseAppliedFilters = computed(() => {
      return store.state.overViewStore?.appliedFilters;
    });

    const filters = computed(() => {
      return store.getters.filters;
    });
    const multiCurrencyFilters = computed(() => {
      return store.getters.multiCurrencyFilters;
    });
    // const metricFilters = computed(
    //   () => store.state.overViewStore.metricFilters
    // );
    const onPageChange = (pageObj) => {
      //[1] set page state
      selectedPage.value = pageObj?.page + 1 ?? 1;

      //[2] fetch user's list
      getUsersList();
    };

    const getUsersList = (cloud_id) => {
      store.dispatch("CANCEL_PENDING_REQUESTS");
      scrollTop();
      usersListLoading.value = true;
      usersListData.value = {};
      UsersviewAPI.getUsersList({
        ...filters.value,
        per_page: 20,
        ...(props.searchQry && { query: props.searchQry }),
        ...(route.params.categoryId && {
          category_ids: [route.params.categoryId],
        }),
        ...(route.params.brandId && {
          brand_ids: [route.params.brandId],
        }),
        ...(cloud_id && {
          tenant_customer_cloud_id: cloud_id,
        }),
        page: selectedPage.value,
        sort_by: sortObject.value?.field, // account_total_spend , account_transactions_count
        order: sortObject.value?.order == -1 ? "desc" : "asc",
        tenant_transaction_currency_codes:
          multiCurrencyFilters.value?.tenant_transaction_currency_codes ?? [],
      })
        .then((res) => {
          usersListLoading.value = false;
          emit("data-updated", { ...res?.data });
          usersListData.value = { ...res?.data };
        })
        .catch((error) => {
          if (error !== "canceled") {
            usersListData.value = {};
            usersListLoading.value = false;
            console.error("error: ", error);
          }
        });
    };

    const scrollTop = () => {
      if (mainUsersViewTable.value !== null)
        mainUsersViewTable.value.scrollTop = 0;
    };

    const onSortChange = (obj) => {
      //[1] Reset Pagination page stats
      firstRow.value = 0;
      selectedPage.value = 1;

      //[2] Set new sort options
      sortObject.value = { ...obj };

      //[3] Fetch sorted user's
      getUsersList();
    };

    const setSearchQry = (cifID) => {
      //[1] if empty CIF ID -> normal fetch + exit
      if (!cifID) {
        store.dispatch("CANCEL_PENDING_REQUESTS");
        getUsersList();
        return;
      }

      //[2] take the CIF ID and fetch the cloudID + fetch customers list filtered by the CloudID
      usersListLoading.value = true;
      usersListData.value = {};
      NBFService.getCustomerByCIFID(cifID)
        .then((res) => {
          //[1] get the data response
          const resData = { ...(res?.data ? res?.data : res) };

          //[2] get the cloudID
          const cloudID = resData?.CloudID ?? null;

          //[3] fetch and filter by cloudID
          store.dispatch("CANCEL_PENDING_REQUESTS");
          getUsersList(cloudID);
        })
        .catch((error) => {
          if (error !== "canceled") {
            console.error("CIF ID Search Error: ", error);
            usersListLoading.value = false;
            usersListData.value = {};
          }
        });
    };

    const routeFiltersExsits = () => {
      let params;

      if (route.params?.filters)
        params = JSON.parse(route.params?.filters ?? {});

      if (Object.keys(params ?? []).length !== 0) {
        const newFilters = {
          ...baseAppliedFilters.value,
          ...params,
        };
        store.commit("updateAppliedFilters", {
          ...newFilters,
        });

        return true;
      }

      return false;
    };

    watch(
      [filters, () => props.searchQry],
      // eslint-disable-next-line no-unused-vars
      ([currentFilters, currentSrc], [oldFilters, oldSrc]) => {
        if (oldFilters?.tenant_transaction_from_date) {
          //[1] reset page state
          firstRow.value = 0;
          selectedPage.value = 1;

          //[2] cancel previous requests
          store.dispatch("CANCEL_PENDING_REQUESTS");

          //[3] Fetch the new data with default filters
          getUsersList();
        }
      }
    );

    //hooks
    onMounted(() => {
      if (routeFiltersExsits()) {
        //[1] set passed filters if exists and the watch will fetch it
        return;
      } else {
        //[2] Fetch the new data with default filters
        getUsersList();
      }
    });
    onUnmounted(() => {
      store.dispatch("CANCEL_PENDING_REQUESTS");
    });

    return {
      usersListLoading,
      usersListData,
      onPageChange,
      mainUsersViewTable,
      firstRow,
      setSearchQry,
      onSortChange,
    };
  },
};
</script>
<style lang=""></style>
