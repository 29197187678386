<template>
  <div class="d-flex justify-content-sm-start align-content-center gap-2">
    <img
      v-if="pageIcon"
      :src="pageIcon"
      alt="Route Icon"
      class="route-title-icon"
    />

    <!-- Loading Custom Name-->
    <div
      v-if="
        $route.name === 'UserDetails' ||
        $route.name === 'UserDetailsFromTransactions'
      "
    >
      <h2 v-if="!customersDetailsLoading">
        {{ customersDetails?.CustomerName }}
      </h2>
      <h2 v-else class="opacity-75">loading...</h2>
    </div>
    <!-- End Loading Custom Name-->

    <h2 v-else>
      {{ pageTitle }}
    </h2>
  </div>
</template>
<script>
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { categories } from "@/data/options";
import { useStore } from "vuex";

export default {
  props: {
    title: {
      type: String,
      required: true,
      default: "Title",
    },
    icon: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const route = useRoute();
    const store = useStore();

    const customersDetails = computed(
      () => store.state.usersViewStore.specificCustomersNBFDetails
    );
    const customersDetailsLoading = computed(
      () => store.state.usersViewStore.specificCustomersNBFDetailsLoading
    );

    const pageTitle = computed(() => {
      let title = props.title;
      //[1] If specific cat route
      switch (route?.name) {
        case "UserCategoryDetails":
          title = categories[route.params?.catId]?.label ?? "----";
          break;
        case "UsersMainByCategory":
          title = `${categories[route.params?.categoryId]?.label} - Users`;
          break;
        case "UsersMainByBrand":
          title = `${route.params?.brandLabel} - Users`;
          break;
      }

      return title;
    });
    const pageIcon = computed(() => {
      let icon = props.icon;
      //[1] If specific cat route
      switch (route?.name) {
        case "UserCategoryDetails":
          icon =
            categories[route.params?.catId]?.icon ??
            require("@/assets/images/svg/components/no_brand_logo.svg");
          break;
      }
      // console.log(route.name);

      return icon;
    });

    // const getCustomerDetails = (cloudId) => {
    //   // store.dispatch("getCustomerDetails", cloudId);
    //   // customersDetailsLoading.value = true;
    //   // NBFService.getCustomerDetails(cloudId)
    //   //   .then((res) => {
    //   //     customersDetails.value = { ...(res?.data ? res?.data : res) };
    //   //     customersDetailsLoading.value = false;
    //   //   })
    //   //   .catch((error) => {
    //   //     if (error !== "canceled") {
    //   //       customersDetails.value = { CustomerName: `User Id: ${cloudId}` };
    //   //       customersDetailsLoading.value = false;
    //   //       console.error("error: ", error);
    //   //     }
    //   //   });
    // };

    // watch(
    //   () => route.name,
    //   (currentRoute) => {
    //     if (
    //       currentRoute === "UserDetails" ||
    //       currentRoute === "UserDetailsFromTransactions"
    //     )
    //       getCustomerDetails(route.params?.cloudId);
    //   },
    //   {
    //     immediate: true,
    //   }
    // );

    return {
      pageTitle,
      pageIcon,
      customersDetailsLoading,
      customersDetails,
      // getCustomerDetails,
    };
  },
};
</script>
<style scoped>
.route-title-icon {
  width: 43px;
  height: 43px;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #edf5f4;
}
h2 {
  color: var(--green);
  font-size: 2.05rem;
  font-weight: 600;
}
</style>
