import RestService from "../RestService";
import { useMockFetch } from "../../composables/Helpers";
//Import useMockAPI() to return an object as an API Call with some delay

class UsersviewAPI {
  getUsersList(filters, page) {
    return RestService.getWithParams("/tenant_customers", filters, page);
    // return useMockFetch({
    //   data: {
    //     tenant_customers_count: 2,
    //     tenant_customers: [
    //       {
    //         id: 1,
    //         nationality: "IN",
    //         age: 44,
    //         account_id: "22344",
    //         account_name: "USD Test",
    //         account_currency: "USD",
    //         account_category: "Conventional",
    //         segment: "Retail - Basic",
    //         account_transactions_count: 53,
    //         account_total_spend: 137634.02000000002,
    //       },
    //       {
    //         id: 1,
    //         nationality: "IN",
    //         age: 44,
    //         account_id: "111222233",
    //         account_name: "UAE AED",
    //         account_currency: "AED",
    //         account_category: "Islamic",
    //         segment: "Retail - Business",
    //         account_transactions_count: 235,
    //         account_total_spend: 23434.54,
    //       },
    //       {
    //         id: 2,
    //         nationality: "JO",
    //         age: 33,
    //         account_id: "23222",
    //         account_name: "UAE AED",
    //         account_currency: "AED",
    //         account_category: "Islamic",
    //         segment: "Retail - Business",
    //         account_transactions_count: 235,
    //         account_total_spend: 23434.54,
    //       },
    //     ],
    //   },
    // });
  }
  getUserAccounts(filters, userId, options) {
    return RestService.getWithParams(
      `/tenant_customers/${userId}`,
      {
        tenant_transaction_from_date: filters?.tenant_transaction_from_date,
        tenant_transaction_to_date: filters?.tenant_transaction_to_date,
      },
      null,
      options
    );
    // return useMockFetch({
    //   data: {
    //     tenant_customer: {
    //       id: "9711651473",
    //       nationality: "AE",
    //       age: 32,
    //       tenant_customer_accounts: [
    //         {
    //           account_id: "36003816354",
    //           account_name: "UAE Saving",
    //           account_currency: "AED",
    //           account_total_spend: 137634.02000000002,
    //           comparison_period_account_total_spend: -11724.360000000019,
    //           account_transactions_count: 53,
    //           comparison_period_account_transactions_count: 49,
    //           account_avg_spend_per_day: 4746.000689655173,
    //           comparison_period_account_avg_spend_per_day:
    //             -4222304.2882758620696,
    //           account_category: "Conventional",
    //           account_segment: "Retail - Small Business",
    //         },
    //         {
    //           account_id: "36003816378",
    //           account_name: "USD Test",
    //           account_currency: "USD",
    //           account_total_spend: -1183580.99,
    //           comparison_period_account_total_spend: -1738646.4900000002,
    //           account_transactions_count: 10,
    //           comparison_period_account_transactions_count: 22,
    //           account_avg_spend_per_day: -40813.137586206896,
    //           comparison_period_account_avg_spend_per_day: -59953.32724137932,
    //           account_category: "Islamic",
    //           account_segment: "Retail - Dubai",
    //         },
    //       ],
    //     },
    //   },
    // });
  }
  getUserCashflow(filters, customer_id) {
    return RestService.getWithParams(
      `/tenant_customers/${customer_id}/cashflow`,
      filters
    );
  }
  getUserBrands(filters, customer_id) {
    return RestService.getWithParams(
      `/tenant_customers/${customer_id}/brands/spend/summary`,
      filters
    );
  }
  getUserCategories(filters, customer_id) {
    return RestService.getWithParams(
      `/tenant_customers/${customer_id}/categories/spend`,
      filters
    );

    // return useMockFetch({
    //   data: {
    //     categories: [
    //       {
    //         id: "1",
    //         name: "Financial Services",
    //         tenant_customer_total_spend: -2222.0,
    //         tenant_customer_total_transactions_count: 222,
    //         tenant_customer_transactions_avg_spend: -33333.0,
    //         comparison_period_tenant_customer_total_spend: -33333,
    //         comparison_period_tenant_customer_total_transactions_count: 33,
    //         comparison_period_tenant_customer_transactions_avg_spend: -343,
    //       },
    //       {
    //         id: "2",
    //         name: "Charity & Gifts",
    //         tenant_customer_total_spend: -2222.0,
    //         tenant_customer_total_transactions_count: 222,
    //         tenant_customer_transactions_avg_spend: -33333.0,
    //         comparison_period_tenant_customer_total_spend: -33333,
    //         comparison_period_tenant_customer_total_transactions_count: 33,
    //         comparison_period_tenant_customer_transactions_avg_spend: -343,
    //       },
    //       {
    //         id: "3",
    //         name: "Charity & Gifts",
    //         tenant_customer_total_spend: -2222.0,
    //         tenant_customer_total_transactions_count: 222,
    //         tenant_customer_transactions_avg_spend: -33333.0,
    //         comparison_period_tenant_customer_total_spend: -33333,
    //         comparison_period_tenant_customer_total_transactions_count: 33,
    //         comparison_period_tenant_customer_transactions_avg_spend: -343,
    //       },
    //       {
    //         id: "4",
    //         name: "2233 & Gifts",
    //         tenant_customer_total_spend: -2222.0,
    //         tenant_customer_total_transactions_count: 222,
    //         tenant_customer_transactions_avg_spend: -33333.0,
    //         comparison_period_tenant_customer_total_spend: -33333,
    //         comparison_period_tenant_customer_total_transactions_count: 33,
    //         comparison_period_tenant_customer_transactions_avg_spend: -343,
    //       },
    //       {
    //         id: "5",
    //         name: "3332 & Gifts",
    //         tenant_customer_total_spend: -2222.0,
    //         tenant_customer_total_transactions_count: 222,
    //         tenant_customer_transactions_avg_spend: -33333.0,
    //         comparison_period_tenant_customer_total_spend: -33333,
    //         comparison_period_tenant_customer_total_transactions_count: 33,
    //         comparison_period_tenant_customer_transactions_avg_spend: -343,
    //       },
    //       {
    //         id: "6",
    //         name: "443 & Gifts",
    //         tenant_customer_total_spend: -432222.0,
    //         tenant_customer_total_transactions_count: 432,
    //         tenant_customer_transactions_avg_spend: -432.0,
    //         comparison_period_tenant_customer_total_spend: -432,
    //         comparison_period_tenant_customer_total_transactions_count: 4324,
    //         comparison_period_tenant_customer_transactions_avg_spend: -4233,
    //       },
    //     ],
    //   },
    // });

    // return useMockFetch({
    //   data: {
    //     1: {
    //       category_id: "1",
    //       total_users_count_per_category: 3312,
    //       category_desc: "Financial Services",
    //       total_tx_count_per_category: 31,
    //       total_spend_per_category: 32212,
    //       average_user_spend_per_category: 4243,
    //       percent_spend_per_category: 3455,
    //       rel_total_spend_per_category: 6533,
    //       rel_total_users_count_per_category: 2452,
    //       rel_total_tx_count_per_category: 4566,
    //       rel_percent_spend_per_category: 4563,
    //       rel_percent_users_count_per_category: 2233,
    //       rel_percent_tx_count_per_category: 533,

    //       sub_categories: [
    //         {
    //           sub_category_id: "1-1",
    //           sub_category_name: "Currency Exchange",

    //           total_spend: 4345,
    //           rel_total_spend: 2345,

    //           avg_tx_spend: 108.0,
    //           rel_avg_tx_spend: 432.66,

    //           avg_spend_per_day: 43,
    //           rel_avg_spend_per_day: 23,

    //           tx_count: 40,
    //           rel_tx_count: 100,

    //           top_brand: {
    //             brand_id: "2955",
    //             brand_name: "Emirates NBD",
    //             brand_logo_url: "brand_logos/EmiratesNBD_fLML0Tp.png",

    //             total_spend: 27860.399,
    //             rel_total_spend: 23344.444,

    //             avg_tx_spend: 15200.0,
    //             rel_avg_tx_spend: 1211.66,

    //             tx_count: 43,
    //             rel_tx_count: 123,
    //           },
    //         },
    //         {
    //           sub_category_id: "1-2",
    //           sub_category_name: "Bank Fees",

    //           total_spend: 5232,
    //           rel_total_spend: 235,

    //           avg_tx_spend: 323.0,
    //           rel_avg_tx_spend: 432.66,

    //           avg_spend_per_day: 43,
    //           rel_avg_spend_per_day: 23,

    //           tx_count: 100,
    //           rel_tx_count: 100,

    //           top_brand: {
    //             brand_id: "1738",
    //             brand_name: "ADCB",
    //             brand_logo_url: "brand_logos/ADCB.png",

    //             total_spend: 1300.399,
    //             rel_total_spend: 234.444,

    //             avg_tx_spend: 432.0,
    //             rel_avg_tx_spend: 532.66,

    //             tx_count: 12,
    //             rel_tx_count: 23,
    //           },
    //         },
    //         {
    //           sub_category_id: "1-3",
    //           sub_category_name: "Financial Services",

    //           total_spend: 4224,
    //           rel_total_spend: 452,

    //           avg_tx_spend: 232.0,
    //           rel_avg_tx_spend: 432.66,

    //           avg_spend_per_day: 12,
    //           rel_avg_spend_per_day: 43,

    //           tx_count: 100,
    //           rel_tx_count: 100,

    //           top_brand: {
    //             brand_id: "1738",
    //             brand_name: "Abu Dhabi Islamic Bank",
    //             brand_logo_url: "brand_logos/ADIB_b6ni2LK.png",

    //             total_spend: 4134.399,
    //             rel_total_spend: 553.444,

    //             avg_tx_spend: 453.0,
    //             rel_avg_tx_spend: 235.66,

    //             tx_count: 12,
    //             rel_tx_count: 123,
    //           },
    //         },
    //         {
    //           sub_category_id: "1",
    //           sub_category_name: "Other",

    //           total_spend: 1400,
    //           rel_total_spend: 200,

    //           avg_tx_spend: 2.0,
    //           rel_avg_tx_spend: 432.66,

    //           avg_spend_per_day: 43,
    //           rel_avg_spend_per_day: 23,

    //           tx_count: 100,
    //           rel_tx_count: 100,

    //           top_brand: {
    //             brand_id: "1738",
    //             brand_name: "Emirates Islamic Bank",
    //             brand_logo_url: "brand_logos/Emirates_Islamic.png",

    //             total_spend: 4234.399,
    //             rel_total_spend: 111.444,

    //             avg_tx_spend: 234.0,
    //             rel_avg_tx_spend: 442.66,

    //             tx_count: 4,
    //             rel_tx_count: 3,
    //           },
    //         },
    //       ],
    //     },
    //     2: {
    //       category_id: "2",
    //       total_users_count_per_category: 3423,
    //       category_desc: "Charity & Gifts",
    //       total_tx_count_per_category: 223442,
    //       total_spend_per_category: 63435,
    //       average_user_spend_per_category: 243,
    //       percent_spend_per_category: 312,
    //       rel_total_spend_per_category: 0,
    //       rel_total_users_count_per_category: 0,
    //       rel_total_tx_count_per_category: 0,
    //       rel_percent_spend_per_category: 0,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: 0,

    //       sub_categories: [
    //         {
    //           sub_category_id: "2-1",
    //           sub_category_name: "Donations",

    //           total_spend: 43242,
    //           rel_total_spend: 200,

    //           avg_tx_spend: 523.0,
    //           rel_avg_tx_spend: 432.66,

    //           avg_spend_per_day: 43,
    //           rel_avg_spend_per_day: 23,

    //           tx_count: 100,
    //           rel_tx_count: 100,

    //           top_brand: {
    //             brand_id: "1738",
    //             brand_name: "LuLu Hypermarket",
    //             brand_logo_url: "brand_logos/LuLu_Hypermarket_I0dJmAw.png",

    //             total_spend: 27860.399,
    //             rel_total_spend: 23344.444,

    //             avg_tx_spend: 15200.0,
    //             rel_avg_tx_spend: 1211.66,

    //             tx_count: 43,
    //             rel_tx_count: 123,
    //           },
    //         },
    //       ],
    //     },
    //     3: {
    //       category_id: "3",
    //       total_users_count_per_category: 0,
    //       category_desc: "Education",
    //       total_tx_count_per_category: 0,
    //       total_spend_per_category: 0,
    //       average_user_spend_per_category: 0,
    //       percent_spend_per_category: 0,
    //       rel_total_spend_per_category: 0,
    //       rel_total_users_count_per_category: 0,
    //       rel_total_tx_count_per_category: 0,
    //       rel_percent_spend_per_category: 0,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: 0,
    //     },
    //     4: {
    //       category_id: "4",
    //       total_users_count_per_category: 1,
    //       category_desc: "Entertainment",
    //       total_tx_count_per_category: 3,
    //       total_spend_per_category: -356.97,
    //       average_user_spend_per_category: -356.97,
    //       percent_spend_per_category: 1.1389952643169698,
    //       rel_total_spend_per_category: -73.98,
    //       rel_total_users_count_per_category: 1,
    //       rel_total_tx_count_per_category: 2,
    //       rel_percent_spend_per_category: 382.522303325223,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: 50,
    //     },
    //     5: {
    //       category_id: "5",
    //       total_users_count_per_category: 1,
    //       category_desc: "Government Services",
    //       total_tx_count_per_category: 1,
    //       total_spend_per_category: -50,
    //       average_user_spend_per_category: -50,
    //       percent_spend_per_category: 0.15953655269588057,
    //       rel_total_spend_per_category: -110.78999999999999,
    //       rel_total_users_count_per_category: 1,
    //       rel_total_tx_count_per_category: 2,
    //       rel_percent_spend_per_category: -54.8695730661612,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: -50,
    //     },
    //     6: {
    //       category_id: "6",
    //       total_users_count_per_category: 1,
    //       category_desc: "Groceries",
    //       total_tx_count_per_category: 18,
    //       total_spend_per_category: -4339.990000000001,
    //       average_user_spend_per_category: -4339.990000000001,
    //       percent_spend_per_category: 13.847740866691897,
    //       rel_total_spend_per_category: -6205.65,
    //       rel_total_users_count_per_category: 1,
    //       rel_total_tx_count_per_category: 13,
    //       rel_percent_spend_per_category: -30.063893387477524,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: 38.46153846153847,
    //     },
    //     7: {
    //       category_id: "7",
    //       total_users_count_per_category: 1,
    //       category_desc: "Wellness",
    //       total_tx_count_per_category: 7,
    //       total_spend_per_category: -3842.87,
    //       average_user_spend_per_category: -3842.87,
    //       percent_spend_per_category: 12.26156464516837,
    //       rel_total_spend_per_category: -1403.3,
    //       rel_total_users_count_per_category: 1,
    //       rel_total_tx_count_per_category: 7,
    //       rel_percent_spend_per_category: 173.84522197676904,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: 0,
    //     },
    //     8: {
    //       category_id: "8",
    //       total_users_count_per_category: 0,
    //       category_desc: "Savings & Investments",
    //       total_tx_count_per_category: 0,
    //       total_spend_per_category: 0,
    //       average_user_spend_per_category: 0,
    //       percent_spend_per_category: 0,
    //       rel_total_spend_per_category: 0,
    //       rel_total_users_count_per_category: 0,
    //       rel_total_tx_count_per_category: 0,
    //       rel_percent_spend_per_category: 0,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: 0,
    //     },
    //     9: {
    //       category_id: "9",
    //       total_users_count_per_category: 1,
    //       category_desc: "Household",
    //       total_tx_count_per_category: 3,
    //       total_spend_per_category: -990.44,
    //       average_user_spend_per_category: -990.44,
    //       percent_spend_per_category: 3.160227665042159,
    //       rel_total_spend_per_category: -550,
    //       rel_total_users_count_per_category: 1,
    //       rel_total_tx_count_per_category: 1,
    //       rel_percent_spend_per_category: 80.08000000000001,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: 200,
    //     },
    //     10: {
    //       category_id: "10",
    //       total_users_count_per_category: 1,
    //       category_desc: "Dining",
    //       total_tx_count_per_category: 9,
    //       total_spend_per_category: -237.5,
    //       average_user_spend_per_category: -237.5,
    //       percent_spend_per_category: 0.7577986253054326,
    //       rel_total_spend_per_category: -292,
    //       rel_total_users_count_per_category: 1,
    //       rel_total_tx_count_per_category: 10,
    //       rel_percent_spend_per_category: -18.664383561643834,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: -10,
    //     },
    //     11: {
    //       category_id: "11",
    //       total_users_count_per_category: 1,
    //       category_desc: "Shopping",
    //       total_tx_count_per_category: 5,
    //       total_spend_per_category: -7137.83,
    //       average_user_spend_per_category: -7137.83,
    //       percent_spend_per_category: 22.774895838584744,
    //       rel_total_spend_per_category: -548.9,
    //       rel_total_users_count_per_category: 1,
    //       rel_total_tx_count_per_category: 6,
    //       rel_percent_spend_per_category: 1200.3880488249226,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: -16.666666666666664,
    //     },
    //     12: {
    //       category_id: "12",
    //       total_users_count_per_category: 0,
    //       category_desc: "Income",
    //       total_tx_count_per_category: 0,
    //       total_spend_per_category: 0,
    //       average_user_spend_per_category: 0,
    //       percent_spend_per_category: 0,
    //       rel_total_spend_per_category: 0,
    //       rel_total_users_count_per_category: 0,
    //       rel_total_tx_count_per_category: 0,
    //       rel_percent_spend_per_category: 0,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: 0,
    //     },
    //     13: {
    //       category_id: "13",
    //       total_users_count_per_category: 0,
    //       category_desc: "Transfer",
    //       total_tx_count_per_category: 0,
    //       total_spend_per_category: 0,
    //       average_user_spend_per_category: 0,
    //       percent_spend_per_category: 0,
    //       rel_total_spend_per_category: 0,
    //       rel_total_users_count_per_category: 0,
    //       rel_total_tx_count_per_category: 0,
    //       rel_percent_spend_per_category: 0,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: 0,
    //     },
    //     14: {
    //       category_id: "14",
    //       total_users_count_per_category: 1,
    //       category_desc: "Transportation",
    //       total_tx_count_per_category: 14,
    //       total_spend_per_category: -2287.88,
    //       average_user_spend_per_category: -2287.88,
    //       percent_spend_per_category: 7.300009763637025,
    //       rel_total_spend_per_category: -1464.83,
    //       rel_total_users_count_per_category: 1,
    //       rel_total_tx_count_per_category: 9,
    //       rel_percent_spend_per_category: 56.18740741246426,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: 55.55555555555556,
    //     },
    //     15: {
    //       category_id: "15",
    //       total_users_count_per_category: 1,
    //       category_desc: "Travel",
    //       total_tx_count_per_category: 1,
    //       total_spend_per_category: -15,
    //       average_user_spend_per_category: -15,
    //       percent_spend_per_category: 0.047860965808764165,
    //       rel_total_spend_per_category: 0,
    //       rel_total_users_count_per_category: 0,
    //       rel_total_tx_count_per_category: 0,
    //       rel_percent_spend_per_category: 0,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: 0,
    //     },
    //     16: {
    //       category_id: "16",
    //       total_users_count_per_category: 0,
    //       category_desc: "Loans",
    //       total_tx_count_per_category: 0,
    //       total_spend_per_category: 0,
    //       average_user_spend_per_category: 0,
    //       percent_spend_per_category: 0,
    //       rel_total_spend_per_category: 0,
    //       rel_total_users_count_per_category: 0,
    //       rel_total_tx_count_per_category: 0,
    //       rel_percent_spend_per_category: 0,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: 0,
    //     },
    //     17: {
    //       category_id: "17",
    //       total_users_count_per_category: 0,
    //       category_desc: "Insurance",
    //       total_tx_count_per_category: 0,
    //       total_spend_per_category: 0,
    //       average_user_spend_per_category: 0,
    //       percent_spend_per_category: 0,
    //       rel_total_spend_per_category: 0,
    //       rel_total_users_count_per_category: 0,
    //       rel_total_tx_count_per_category: 0,
    //       rel_percent_spend_per_category: 0,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: 0,
    //     },
    //     18: {
    //       category_id: "18",
    //       total_users_count_per_category: 0,
    //       category_desc: "Services",
    //       total_tx_count_per_category: 0,
    //       total_spend_per_category: 0,
    //       average_user_spend_per_category: 0,
    //       percent_spend_per_category: 0,
    //       rel_total_spend_per_category: 0,
    //       rel_total_users_count_per_category: 0,
    //       rel_total_tx_count_per_category: 0,
    //       rel_percent_spend_per_category: 0,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: 0,
    //     },
    //     19: {
    //       category_id: "19",
    //       total_users_count_per_category: 1,
    //       category_desc: "Miscellaneous",
    //       total_tx_count_per_category: 8,
    //       total_spend_per_category: -12082.3,
    //       average_user_spend_per_category: -12082.3,
    //       percent_spend_per_category: 38.55136981274875,
    //       rel_total_spend_per_category: -424.1,
    //       rel_total_users_count_per_category: 1,
    //       rel_total_tx_count_per_category: 5,
    //       rel_percent_spend_per_category: 2748.9271398255123,
    //       rel_percent_users_count_per_category: 0,
    //       rel_percent_tx_count_per_category: 60,
    //     },
    //   },
    // });
  }
  getUserSepcificCategory(filters, customer_id, category_id) {
    return RestService.getWithParams(
      `/tenant_customers/${customer_id}/categories/${category_id}/spend`,
      filters
    );
    // return useMockFetch({
    //   data: {
    //     id: "1",
    //     name: "Financial Services alone",
    //     tenant_customer_total_spend: -100.0,
    //     tenant_customer_total_transactions_count: 5,
    //     tenant_customer_transactions_avg_spend: -20.0,
    //     comparison_period_tenant_customer_total_spend: -500,
    //     comparison_period_tenant_customer_total_transactions_count: 15,
    //     comparison_period_tenant_customer_transactions_avg_spend: -33.5,
    //   },
    // });
  }
  getUserCategorySpend(filters, periodDays, categoryId, customer_id) {
    let timeInterval = "daily";

    switch (true) {
      case periodDays <= 31:
        timeInterval = "daily";
        break;

      case periodDays > 31 && periodDays < 365:
        timeInterval = "weekly";
        break;

      case periodDays >= 365:
        timeInterval = "monthly";
        break;
    }
    const endpoint = `/tenant_customers/${customer_id}/categories/${categoryId}/spend/${timeInterval}`;

    return RestService.getWithParams(endpoint, filters);

    // return useMockFetch({
    //   data: {
    //     category: [
    //       {
    //         tenant_customer_transaction_count_date: "2020-04-07",
    //         tenant_customer_total_spend: -1124.55,
    //         tenant_customer_total_transactions_count: 1124,
    //         comparison_period_tenant_customer_total_spend: -1124.55,
    //         comparison_period_tenant_customer_total_transactions_count: 1124,
    //       },
    //       {
    //         tenant_customer_transaction_count_date: "2020-04-08",
    //         tenant_customer_total_spend: -222335.55,
    //         tenant_customer_total_transactions_count: 432,
    //         comparison_period_tenant_customer_total_spend: -432.55,
    //         comparison_period_tenant_customer_total_transactions_count: 432,
    //       },
    //       {
    //         tenant_customer_transaction_count_date: "2020-04-09",
    //         tenant_customer_total_spend: -356.55,
    //         tenant_customer_total_transactions_count: 356,
    //         comparison_period_tenant_customer_total_spend: -24.55,
    //         comparison_period_tenant_customer_total_transactions_count: 245,
    //       },
    //       {
    //         tenant_customer_transaction_count_date: "2020-04-10",
    //         tenant_customer_total_spend: -256.55,
    //         tenant_customer_total_transactions_count: 532,
    //         comparison_period_tenant_customer_total_spend: -532.55,
    //         comparison_period_tenant_customer_total_transactions_count: 246,
    //       },
    //     ],
    //   },
    // });
  }
  getUserTransactions(filters) {
    return RestService.getWithParams("/tenant_transactions", filters);
    // return useMockFetch(
    //   {
    //     data: {
    //       transactions: [
    //         {
    //           amount: 612341,
    //           mcc: "4899",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "Netflix.com",
    //           global_tx_id: "8ab63e89-14ba-4215-8ef3-c49dd5fd00e7",
    //           transaction_id: "8ab63e89-14ba-4215-8ef3-c49dd5fd00e7",
    //           currency_code: "SAR",
    //           transaction_date: "2023-08-04",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "20205",
    //           brand_name: "Netflix",
    //           brand_logo_url: "brand_logos/Netflix.png",
    //           id: "8ab63e89-14ba-4215-8ef3-c49dd5fd00e7",
    //           timestamp: "2023-08-04",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: "pending",
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 300,
    //           mcc: "5816",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "STEAMGAMES.COM 4259522",
    //           global_tx_id: "8d00a5ee-9a39-4c36-a0bf-a33b2b1c3294",
    //           transaction_id: "8d00a5ee-9a39-4c36-a0bf-a33b2b1c3294",
    //           currency_code: "ARS",
    //           transaction_date: "2023-08-04",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "21216",
    //           brand_name: "Steam",
    //           brand_logo_url:
    //             "brand_logos/download_-_2022-06-10T160317.021_1.png",
    //           id: "8d00a5ee-9a39-4c36-a0bf-a33b2b1c3294",
    //           timestamp: "2023-08-04",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: "closed",
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 300,
    //           mcc: "5816",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "STEAMGAMES.COM 4259522",
    //           global_tx_id: "6b64f0e1-6e3c-43c1-a751-b317f42add01",
    //           transaction_id: "6b64f0e1-6e3c-43c1-a751-b317f42add01",
    //           currency_code: "ARS",
    //           transaction_date: "2023-08-04",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "21216",
    //           brand_name: "Steam",
    //           brand_logo_url:
    //             "brand_logos/download_-_2022-06-10T160317.021_1.png",
    //           id: "6b64f0e1-6e3c-43c1-a751-b317f42add01",
    //           timestamp: "2023-08-04",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: "updated",
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 195,
    //           mcc: "7991",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "Salman Jaber Salman al",
    //           global_tx_id: "a4c37c87-3322-4d62-929f-6369b12e0816",
    //           transaction_id: "a4c37c87-3322-4d62-929f-6369b12e0816",
    //           currency_code: "SAR",
    //           transaction_date: "2023-08-04",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: null,
    //           brand_name: null,
    //           brand_logo_url: null,
    //           id: "a4c37c87-3322-4d62-929f-6369b12e0816",
    //           timestamp: "2023-08-04",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 43,
    //           mcc: "4899",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "NETFLIX.COM",
    //           global_tx_id: "ca1bbdfb-c712-4dfb-9567-2bf7b4b58cd6",
    //           transaction_id: "ca1bbdfb-c712-4dfb-9567-2bf7b4b58cd6",
    //           currency_code: "SAR",
    //           transaction_date: "2023-08-04",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "20205",
    //           brand_name: "Netflix",
    //           brand_logo_url: "brand_logos/Netflix.png",
    //           id: "ca1bbdfb-c712-4dfb-9567-2bf7b4b58cd6",
    //           timestamp: "2023-08-04",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 200,
    //           mcc: "5816",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "STEAMGAMES.COM 4259522",
    //           global_tx_id: "c0450037-6bf0-4cf1-9665-5a3cb2a54382",
    //           transaction_id: "c0450037-6bf0-4cf1-9665-5a3cb2a54382",
    //           currency_code: "ARS",
    //           transaction_date: "2023-08-04",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "21216",
    //           brand_name: "Steam",
    //           brand_logo_url:
    //             "brand_logos/download_-_2022-06-10T160317.021_1.png",
    //           id: "c0450037-6bf0-4cf1-9665-5a3cb2a54382",
    //           timestamp: "2023-08-04",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 400,
    //           mcc: "5816",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "STEAMGAMES.COM 4259522",
    //           global_tx_id: "ed1c5659-2238-4824-a07f-21b7aabc9604",
    //           transaction_id: "ed1c5659-2238-4824-a07f-21b7aabc9604",
    //           currency_code: "ARS",
    //           transaction_date: "2023-08-04",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "21216",
    //           brand_name: "Steam",
    //           brand_logo_url:
    //             "brand_logos/download_-_2022-06-10T160317.021_1.png",
    //           id: "ed1c5659-2238-4824-a07f-21b7aabc9604",
    //           timestamp: "2023-08-04",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 19.99,
    //           mcc: "5815",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "Spotify AB P24C69DCA8",
    //           global_tx_id: "0b10e956-56a3-4d8c-bfc1-ac4e0389d43c",
    //           transaction_id: "0b10e956-56a3-4d8c-bfc1-ac4e0389d43c",
    //           currency_code: "SAR",
    //           transaction_date: "2023-08-03",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "20472",
    //           brand_name: "Spotify",
    //           brand_logo_url: "brand_logos/Spotify_Vm8PGiO.png",
    //           id: "0b10e956-56a3-4d8c-bfc1-ac4e0389d43c",
    //           timestamp: "2023-08-03",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 43,
    //           mcc: "4899",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "Netflix.com",
    //           global_tx_id: "1d99174e-d56b-4a21-a696-48e052a30312",
    //           transaction_id: "1d99174e-d56b-4a21-a696-48e052a30312",
    //           currency_code: "SAR",
    //           transaction_date: "2023-08-03",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "20205",
    //           brand_name: "Netflix",
    //           brand_logo_url: "brand_logos/Netflix.png",
    //           id: "1d99174e-d56b-4a21-a696-48e052a30312",
    //           timestamp: "2023-08-03",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 2.3,
    //           mcc: "5816",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "G2A.COM",
    //           global_tx_id: "c1dd0e2a-8e56-4f79-bf96-13e2ce3d597c",
    //           transaction_id: "c1dd0e2a-8e56-4f79-bf96-13e2ce3d597c",
    //           currency_code: "USD",
    //           transaction_date: "2023-08-03",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: null,
    //           brand_name: null,
    //           brand_logo_url: null,
    //           id: "c1dd0e2a-8e56-4f79-bf96-13e2ce3d597c",
    //           timestamp: "2023-08-03",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 200,
    //           mcc: "5818",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "PlaystationNetwork",
    //           global_tx_id: "f70480d1-d1ea-46ee-9602-f89cb5a0b3e6",
    //           transaction_id: "f70480d1-d1ea-46ee-9602-f89cb5a0b3e6",
    //           currency_code: "TRY",
    //           transaction_date: "2023-08-03",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "20249",
    //           brand_name: "Playstation",
    //           brand_logo_url: "brand_logos/playstation_G5ARcyt.png",
    //           id: "f70480d1-d1ea-46ee-9602-f89cb5a0b3e6",
    //           timestamp: "2023-08-03",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 0,
    //           mcc: "5816",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "PlaystationNetwork",
    //           global_tx_id: "4392dbb8-bc71-4753-9b55-43b298c68162",
    //           transaction_id: "4392dbb8-bc71-4753-9b55-43b298c68162",
    //           currency_code: "TRY",
    //           transaction_date: "2023-08-03",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "20249",
    //           brand_name: "Playstation",
    //           brand_logo_url: "brand_logos/playstation_G5ARcyt.png",
    //           id: "4392dbb8-bc71-4753-9b55-43b298c68162",
    //           timestamp: "2023-08-03",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 200,
    //           mcc: "5818",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "PlaystationNetwork",
    //           global_tx_id: "76e161b8-d26f-4518-9c76-fedf3b9182c5",
    //           transaction_id: "76e161b8-d26f-4518-9c76-fedf3b9182c5",
    //           currency_code: "TRY",
    //           transaction_date: "2023-08-03",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "20249",
    //           brand_name: "Playstation",
    //           brand_logo_url: "brand_logos/playstation_G5ARcyt.png",
    //           id: "76e161b8-d26f-4518-9c76-fedf3b9182c5",
    //           timestamp: "2023-08-03",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 60,
    //           mcc: "5816",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "PlaystationNetwork",
    //           global_tx_id: "73f9219e-cfd9-491d-92fe-f4f4b58766e1",
    //           transaction_id: "73f9219e-cfd9-491d-92fe-f4f4b58766e1",
    //           currency_code: "TRY",
    //           transaction_date: "2023-08-03",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "20249",
    //           brand_name: "Playstation",
    //           brand_logo_url: "brand_logos/playstation_G5ARcyt.png",
    //           id: "73f9219e-cfd9-491d-92fe-f4f4b58766e1",
    //           timestamp: "2023-08-03",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 145,
    //           mcc: "7832",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "MUVI Cinema",
    //           global_tx_id: "f8d6f221-9e24-4cca-9c5e-a64d124c576c",
    //           transaction_id: "f8d6f221-9e24-4cca-9c5e-a64d124c576c",
    //           currency_code: "SAR",
    //           transaction_date: "2023-08-03",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "20921",
    //           brand_name: "MUVI Cinema",
    //           brand_logo_url: "brand_logos/muvi-cinemas-logo_1.png",
    //           id: "f8d6f221-9e24-4cca-9c5e-a64d124c576c",
    //           timestamp: "2023-08-03",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 145,
    //           mcc: "7832",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "MUVI Cinema",
    //           global_tx_id: "8794e8c3-fb44-40ab-bd7a-6eeb4a15b11f",
    //           transaction_id: "8794e8c3-fb44-40ab-bd7a-6eeb4a15b11f",
    //           currency_code: "SAR",
    //           transaction_date: "2023-08-03",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "20921",
    //           brand_name: "MUVI Cinema",
    //           brand_logo_url: "brand_logos/muvi-cinemas-logo_1.png",
    //           id: "8794e8c3-fb44-40ab-bd7a-6eeb4a15b11f",
    //           timestamp: "2023-08-03",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 145,
    //           mcc: "7832",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "MUVI Cinema",
    //           global_tx_id: "d143a7bb-4754-4f8c-bd9b-97759805d158",
    //           transaction_id: "d143a7bb-4754-4f8c-bd9b-97759805d158",
    //           currency_code: "SAR",
    //           transaction_date: "2023-08-03",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "20921",
    //           brand_name: "MUVI Cinema",
    //           brand_logo_url: "brand_logos/muvi-cinemas-logo_1.png",
    //           id: "d143a7bb-4754-4f8c-bd9b-97759805d158",
    //           timestamp: "2023-08-03",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 55.9,
    //           mcc: "4899",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "NETFLIX.COM",
    //           global_tx_id: "1942f4d9-dbd1-43b0-ae3c-acf525e8b4f4",
    //           transaction_id: "1942f4d9-dbd1-43b0-ae3c-acf525e8b4f4",
    //           currency_code: "BRL",
    //           transaction_date: "2023-08-03",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "20205",
    //           brand_name: "Netflix",
    //           brand_logo_url: "brand_logos/Netflix.png",
    //           id: "1942f4d9-dbd1-43b0-ae3c-acf525e8b4f4",
    //           timestamp: "2023-08-03",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 23.99,
    //           mcc: "7829",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "Google YouTubePremium",
    //           global_tx_id: "d75a3457-8c88-49e7-b7af-a15f74b43258",
    //           transaction_id: "d75a3457-8c88-49e7-b7af-a15f74b43258",
    //           currency_code: "SAR",
    //           transaction_date: "2023-08-03",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "20403",
    //           brand_name: "Youtube",
    //           brand_logo_url: "brand_logos/1631053379295.png",
    //           id: "d75a3457-8c88-49e7-b7af-a15f74b43258",
    //           timestamp: "2023-08-03",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //         {
    //           amount: 8000,
    //           mcc: "5816",
    //           account_number: null,
    //           location: null,
    //           source: null,
    //           merchant_name: null,
    //           raw_description: "Steam Purchase",
    //           global_tx_id: "c2d21d2f-17cf-4b49-beaa-ba3bea3f93f7",
    //           transaction_id: "c2d21d2f-17cf-4b49-beaa-ba3bea3f93f7",
    //           currency_code: "ARS",
    //           transaction_date: "2023-08-02",
    //           swift_code: null,
    //           swift_country: null,
    //           swift_city: null,
    //           swift_entity: null,
    //           transaction_type: null,
    //           is_invalid_brand: false,
    //           is_invalid_category: false,
    //           lune_categ_id: "4",
    //           brand_id: "21216",
    //           brand_name: "Steam",
    //           brand_logo_url:
    //             "brand_logos/download_-_2022-06-10T160317.021_1.png",
    //           id: "c2d21d2f-17cf-4b49-beaa-ba3bea3f93f7",
    //           timestamp: "2023-08-02",
    //           is_invalid_logo: false,
    //           user_rec_logo: null,
    //           invalid_logo_reason: null,
    //           invalid_category_reason: null,
    //           invalid_brand_reason: null,
    //           review: {
    //             status: null,
    //             report_open_date: null,
    //             report_close_date: null,
    //           },
    //           invalid_brand_suggestion: null,
    //           invalid_category_suggestion: null,
    //           invalid_logo_suggestion: null,
    //         },
    //       ],
    //       page: 1,
    //       limit: 20,
    //       total_records: 11849,
    //     },
    //     message: "",
    //   },
    //   1200
    // );
  }
  getUserBalances(filters, daysPeriod) {
    let period = "";

    switch (true) {
      case daysPeriod <= 31: //|| filter.periodLabel == 'Current Month'):
        period = "daily";
        break;

      case daysPeriod > 31 && daysPeriod < 365:
        period = "weekly";
        break;

      case daysPeriod >= 365:
        period = "monthly";
        break;
    }
    // return RestService.getWithParams("/user-balance", {
    //   ...filters,
    //   period,
    // });
    return useMockFetch({
      data: {
        balances: [
          {
            date: "2023-07-03",
            value: 2442.0,

            rel_date: "2023-06-03",
            rel_value: 1234.0,
          },
          {
            date: "2023-07-04",
            value: 7442.0,

            rel_date: "2023-06-04",
            rel_value: 2233.0,
          },
          {
            date: "2023-07-05",
            value: 7652.0,

            rel_date: "2023-06-05",
            rel_value: 9999.0,
          },
          {
            date: "2023-07-06",
            value: 7652.0,

            rel_date: "2023-06-05",
            rel_value: 9999.0,
          },
          {
            date: "2023-07-07",
            value: 7652.0,

            rel_date: "2023-06-05",
            rel_value: 9999.0,
          },
        ],
      },
      message: "",
    });
  }
}

export default new UsersviewAPI();
