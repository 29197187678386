<template>
  <div :key="key" class="cp h-100 d-flex align-items-center p-2 options-button">
    <div class="d-flex justify-content-center align-items-center">
      <tippy class="text-center" :interactive="true">
        <!-- :trigger-target="['.bi-three-dots', '#ctx-menu']" -->
        <li class="pi pi-ellipsis-v"></li>
        <template #content>
          <ul id="ctx-menu" class="p-2 px-3 rounded-3 bg-light shadow-sm">
            <li
              class="
                d-flex
                flex-row
                justify-content-center
                align-items-center
                dropdown-item
                cp
                p-2
              "
              @click="reportTx"
            >
              <i class="pi pi-file-edit me-3"></i>
              <span>Report</span>
            </li>
          </ul>
        </template>
      </tippy>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["report-tx"],
  data() {
    return {
      key: 0,
    };
  },
  methods: {
    reportTx() {
      this.key++;
      this.$emit("report-tx");
    },
  },
};
</script>

<style scoped>
#ctx-menu li span {
  font-size: 0.95rem;
}
.options-button {
  opacity: 0.4;
  transition: all 0.4 ease;
}
.options-button:hover {
  opacity: 1;
}
</style>
