<template>
  <div>
    <DoughnutChart
      class="base-doughnut-chart-box"
      :chart-data="chartData"
      :options="options"
      :plugins="plugins"
    />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { DoughnutChart } from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  components: { DoughnutChart },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const plugins = [ChartDataLabels];
    const chartData = computed(() => ({
      datasets: [
        {
          data: [
            Math.abs(props.data?.cashflow?.inflow ?? 0),
            Math.abs(props.data?.cashflow?.outflow ?? 0),
          ],
          backgroundColor: ["#335855", "#FFEBEB"],
          borderWidth: 0,
        },
      ],
    }));

    const options = ref({
      borderRadius: 0,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        //Custom Datalabel configs
        datalabels: {
          align: "end",
          anchor: "center",
          color: "#002E2A",
          borderWidth: 1,
          borderColor: "#002E2A1a",
          backgroundColor: "white",
          borderRadius: 5,
          padding: {
            left: 8,
            right: 8,
            top: 6,
            bottom: 6,
          },
          font: {
            size: 15,
            weight: 500,
          },
          formatter: (value, ctx) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map((data) => {
              sum += data;
            });
            let percentage = Math.abs(((value * 100) / sum).toFixed(0)) + "%";
            return percentage;
          },
        },
      },
      cutout: "70%",
      radius: "77%",
    });

    return { chartData, options, plugins };
  },
};
</script>

<style scoped>
.base-doughnut-chart-box {
  max-height: 320px;
}
</style>
